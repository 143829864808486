import React, { useEffect, useMemo, useState } from 'react';
import CountUp from 'react-countup';
import Logo from '../assets/logo.png';
import { ReactComponent as PluseIcn } from '../assets/plus_icon.svg';
import { ReactComponent as Minus } from '../assets/minus.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as Accordion from '@radix-ui/react-accordion';
import ExpandedData from './ExpandedData';
import LockUpABI from '../ABI/DrivenLockupABI.json';

import { ethers } from 'ethers';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useSigner } from 'wagmi';
import { getContract } from '../utils/contractHelpers';
import { getAddresses } from '../utils/addressHelpers';
import { setUnstakeModalData, setUnstakeOpen } from '../store/UnstakSlice';
import DisplayTime from './layout/DisplayTime';
import UnStakingModal from './layout/UnStakingModal';
import MyStakeDisplayTime from './layout/MyStakeDisplayTime';
import CountupCustom from './Helper/CountupCustom';

const columnHelper = createColumnHelper();

export default function ExpandableTable({ data }) {
  const { data: signer } = useSigner();
  const addresses = getAddresses();
  const locupAddress = addresses[0].lockup;
  const dispatch = useDispatch();
  const open = useSelector((state) => state.unstake.open);
  // const [data, setData] = useState([...userData]);
  const [selectedIndex, setSelectedIndex] = useState();
  const refresh = useSelector((state) => state.modal.requireRefresh);
  const [expanded, setExpanded] = useState({});
  const handleRestake = async (type, index) => {
    try {
      const lockupContract = getContract(LockUpABI, locupAddress, signer);
      let tx;
      if (type === 'restake') {
        tx = await lockupContract.compoundReward(data[parseInt(index)].stackType);
      } else if (type === 'harvest') {
        tx = await lockupContract.claimReward(data[parseInt(index)].stackType);
      }
      await tx.wait();
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.term, {
        id: 'term',
        cell: (info) => (
          <>
            {info.getValue() === 'extra' ? (
              <></>
            ) : (
              <div className="flex w-[120%] flex-row flex-wrap justify-start gap-2 py-5 px-5 text-center text-xs tracking-wider sm:text-base">
                <div className="flex items-center">
                  <img src={Logo} alt="logo" className="h-5 w-5" />
                </div>
                <div className="">{info.getValue()}</div>
              </div>
            )}
          </>
        ),

        header: () => <div className="pl-5 text-left font-normal">Term</div>,
      }),
      columnHelper.accessor(
        (row) => {
          return { apy: row.apy, term: row.term };
        },
        {
          id: 'apy',
          cell: (info) => (
            <>
              {info.getValue() === 'extra' ? (
                <>
                  <div className="py-5">
                    <button
                      className="rounded-lg border border-primary-sunny-dark px-3 py-1 duration-300 ease-in-out hover:bg-primary-sunny-dark"
                      onClick={() => {
                        handleRestake('restake', info.row.id);
                      }}
                    >
                      Restake
                    </button>
                  </div>
                </>
              ) : (
                <div className="px-2 py-5 text-left text-xs text-green-400 sm:text-base">
                  {info.getValue().term === '1 year' && '2%'}
                  {info.getValue().term === '2 years' && '3.5%'}
                  {info.getValue().term === '3 years' && '5%'}
                  {info.getValue().term === '4 years' && '6.5%'}
                  {console.log('getval', info.getValue())}
                  {/* {info.getValue().toFixed(3)} % */}
                </div>
              )}
            </>
          ),
          header: () => <div className="!px-2">APY</div>,
        }
      ),
      columnHelper.accessor((row) => row.user.pendingreward, {
        id: 'pendingreward',
        cell: (info) => (
          <>
            {info.getValue() === 'extra' ? (
              <>
                <div>
                  <button
                    className="rounded-lg border border-primary-sunny-dark px-3 py-1 duration-300 ease-in-out hover:bg-primary-sunny-dark"
                    onClick={() => {
                      handleRestake('harvest', info.row.id);
                    }}
                  >
                    Harvast
                  </button>
                </div>
              </>
            ) : (
              <div className="px-2 py-5 text-left text-xs sm:text-base">
                {/* {info.getValue()} */}
                <CountUp
                  start={0}
                  end={parseFloat(info.getValue())}
                  prefix=""
                  suffix={' $BTF'}
                  decimals={3}
                  duration={1}
                  separator=","
                />
              </div>
            )}
          </>
        ),
        header: 'Reward',
      }),
      columnHelper.accessor((row) => row.user.userstack, {
        id: 'userstack',
        cell: (info) => (
          <>
            {info.getValue() === 'extra' ? (
              <>
                <div>
                  <button
                    className="rounded-lg border border-primary-sunny-dark px-3 py-1 duration-300 ease-in-out hover:bg-primary-sunny-dark disabled:cursor-not-allowed"
                    onClick={() => {
                      setSelectedIndex(parseInt(info.row.id));
                      dispatch(setUnstakeOpen(true));
                      dispatch(setUnstakeModalData(data[parseInt(info.row.id)]));
                    }}
                    disabled={data[parseInt(info.row.id)].user.available === '0'}
                  >
                    Unstake
                  </button>
                </div>
              </>
            ) : (
              <div className="px-2 py-5 text-left text-xs sm:text-base">
                <CountUp
                  start={0}
                  end={parseFloat(ethers.utils.formatUnits(info.getValue()))}
                  prefix=""
                  suffix={' $BTF'}
                  decimals={3}
                  duration={1}
                  separator=","
                />
                {/* <CountupCustom value={parseFloat(ethers.utils.formatUnits(info.getValue())).toFixed(2)} decimals={2}/> */}
              </div>
            )}
          </>
        ),
        header: 'Your Staked',
      }),

      columnHelper.accessor((row) => row.user.userstack, {
        id: 'totalValue',
        cell: (info) => (
          <div className="pr-5 text-left">
            {info.getValue() === 'extra' ? (
              <>
                <div className="px-3 ">
                  <p className="mb-1 text-sm tracking-wider">Remaining Time</p>
                  <MyStakeDisplayTime time={data[parseInt(info.row.id)]?.user?.stakeendinEpoch} />
                </div>
              </>
            ) : (
              <div className="flex justify-end gap-5 px-2 py-5 text-right text-xs sm:text-base">
                <div>$0.00</div>
                <div
                  {...{
                    onClick: () => {
                      info.row.getToggleExpandedHandler();
                    },
                    style: { cursor: 'pointer' },
                  }}
                  className="h-5 w-5"
                >
                  {!info.row.getIsExpanded() ? (
                    <PluseIcn className="h-5 w-5" />
                  ) : (
                    <Minus className="h-5 w-5" />
                  )}
                </div>
              </div>
            )}
          </div>
        ),
        header: () => <div className="pr-5 text-right">Total Value</div>,
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
    },
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <>
      <div className="">
        <table className="min-w-[-webkit-fill-available]">
          <thead>
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={`w-1/5 py-5 px-2 text-left !text-sm font-normal tracking-wider text-gray-300 sm:text-xl`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => {
              if (row.getValue('userstack') !== '0') {
                return (
                  <tr
                    key={row.id}
                    className={`table_row !text-left ${
                      row.getIsExpanded() && 'light-gradieant-blur'
                    } ${row.id.includes('.') && 'light-gradieant border-t border-gray-600'}`}
                    {...{
                      onClick: row.getToggleExpandedHandler(),
                      style: { cursor: 'pointer' },
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <UnStakingModal open={open} />
    </>
  );
}
