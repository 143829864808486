import React, { useState } from 'react';
import styled from '@emotion/styled';
import useTradeInfo from '../../../hooks/useTradeContext';
import { SkeletonComponent } from '../../SkeletonComponent';
import { PriceComponent } from '../../PriceComponent';

export default function PairPanel() {
  const filters = ['fav', 'usdt'];

  const [activeFilter, setActiveFilter] = useState(0);
  const { pairs, selectedPair, setSelectedPair } = useTradeInfo();

  return (
    <StyledPanel className="h-full w-full 2xl:w-[320px]">
      <SearchPanel className="m-1.5 flex h-[35px] overflow-hidden rounded bg-[#131313]">
        <input
          type="text"
          className="h-full flex-1 border-r-4 border-[#1b1b1b] bg-transparent px-2"
        />
        <div className="flex h-full w-10 cursor-pointer items-center justify-center rounded transition hover:bg-[#323232]">
          <i className="bi bi-search"></i>
        </div>
      </SearchPanel>
      <FilterPanel className="mx-3 flex" active={activeFilter + 1}>
        {filters.map((data, i) => {
          return (
            <div
              key={i}
              className="flex h-6 w-[20%] cursor-pointer items-center justify-center rounded text-xs font-semibold uppercase text-[#a7acb9] transition hover:text-[#CA8D31]"
              onClick={() => setActiveFilter(i)}
            >
              {data}
            </div>
          );
        })}
      </FilterPanel>
      <div>
        <div className="mt-3 flex  h-8 items-center justify-between bg-black px-2 text-xs">
          <div className="w-4">
            <i className="bi bi-star-fill"></i>
          </div>
          <div className="w-[90px]">PAIR</div>
          <div className="w-[90px]">PRICE</div>
          <div className="w-[60px]">24H CH</div>
        </div>
        <div className="h-[300px]">
          {pairs.map((data, i) => {
            return (
              <div
                className={`flex h-6 cursor-pointer items-center justify-between px-2 text-xs ${
                  selectedPair === i ? 'bg-[rgba(0,0,0,0.4)]' : ''
                }`}
                key={i}
                onClick={() => setSelectedPair(i)}
              >
                <div className="w-4">
                  <i className="bi bi-star"></i>
                </div>
                <div className="w-[90px]">
                  {data.token0.symbol}/{data.token1.symbol}
                </div>
                <div className="w-[90px]">
                  {data.price ? data.price.toFixed(2) : <SkeletonComponent />}
                </div>
                <div className="w-[60px]">
                  {data.priceChange ? (
                    <PriceComponent
                      value={data.priceChange.toFixed(2)}
                      change={data.priceChange}
                      suffix={'%'}
                      prefix={'+'}
                    />
                  ) : (
                    <SkeletonComponent />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </StyledPanel>
  );
}
const FilterPanel = styled.div`
  > div:nth-child(${({ active }) => active}) {
    background: #ca8d31;
    color: white;
  }
`;

const SearchPanel = styled.div``;

const StyledPanel = styled.div`
  background: #1b1b1b;
  border-radius: 6px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    display: block !important;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb:vertical {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #eebb19;
  }
`;
