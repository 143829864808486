import logo from '../assets/Defalut_token_logo.png';
export default {
  pairTokens: [
    {
      logoURI: logo,
      symbol: 'USDC - USDT',
      chainId: 43114,
      token0: {
        symbol: 'USDC',
        address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        decimals: 6,
        name: 'USDC Token',
        chainId: 43114,
      },
      token1: {
        symbol: 'USDT',
        address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        decimals: 6,
        name: 'USDT Token',
        chainId: 43114,
      },
      pool: 'USDC - USDT',
      pairAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      lpBal: 0,
      poolShare: 0,
    },

    {
      logoURI: logo,
      symbol: 'AVAX - USDT',
      chainId: 43114,
      token0: {
        symbol: 'AVAX',
        address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        decimals: 18,
        name: 'Avax Token',
        chainId: 43114,
      },
      token1: {
        symbol: 'USDT',
        address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        decimals: 6,
        name: 'USDT Token',
        chainId: 43114,
      },
      pool: 'AVAX - USDT',
      pairAddress: '0xe3bA3d5e3F98eefF5e9EDdD5Bd20E476202770da',
      lpBal: 0,
      poolShare: 0,
    },

    {
      logoURI: logo,
      symbol: 'AVAX - BTF',
      chainId: 43114,
      token0: {
        symbol: 'AVAX',
        address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        decimals: 18,
        name: 'Avax Token',
        chainId: 43114,
      },
      token1: {
        symbol: 'BTF',
        address: '0x405eb25c5edbFd227a72D69e7eD9dB8860637F00',
        decimals: 18,
        name: 'BTF Token',
        chainId: 43114,
      },
      pool: 'AVAX - BTF',
      pairAddress: '0x0e0100Ab771E9288e0Aa97e11557E6654C3a9665',
      lpBal: 0,
      poolShare: 0,
    },
  ],
};
