import { activeNetwork } from '../config/networkConfig';

// import drivenx from "../assets/images/drivenx.png";

const contract = {
  bsc: {
    WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    USDT: '0x55d398326f99059fF775485246999027B3197955',
    LpTokenRouter: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
    multiCall: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    DrivenFarm: '0x1f61ACdeE563af6064570347870c6009c016081A',
    addresses: [
      {
        //dvx
        DividendToken: '0x6db3972c6a5535708e7A4F7Ad52F24d178D9A93e',
        lockup: '0x53509561113e1DD131a6b0ff66283Ce5B15Ed2b2',
        TokenToUsd: [
          '0x6db3972c6a5535708e7A4F7Ad52F24d178D9A93e',
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
          '0x55d398326f99059fF775485246999027B3197955',
        ],
        UsdToToken: [
          '0x55d398326f99059fF775485246999027B3197955',
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
          '0x6db3972c6a5535708e7A4F7Ad52F24d178D9A93e',
        ],
        LpToken: '0xA8e72b739841A18751B9b83d0EBCCFbC1b489590',
      },
      {
        //MLNX
        DividendToken: '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
        lockup: '0xCc7b3585BfeE46492B8f302e82B0eA7983286Cd7',
        TokenToUsd: [
          '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
          '0x55d398326f99059fF775485246999027B3197955',
        ],
        UsdToToken: [
          '0x55d398326f99059fF775485246999027B3197955',
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
          '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
        ],
        LpToken: '0x1C9ABaf8c0Fe7691808bC9794ea37e0b3a0e4edF',
      },
      {
        //MLNX finished
        DividendToken: '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
        lockup: '0x14a5014D76E431353c0c465a9bd3AA80C8cBc4fA',
        TokenToUsd: [
          '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
          '0x55d398326f99059fF775485246999027B3197955',
        ],
        UsdToToken: [
          '0x55d398326f99059fF775485246999027B3197955',
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
          '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
        ],
        LpToken: '0x1C9ABaf8c0Fe7691808bC9794ea37e0b3a0e4edF',
      },
    ],
  },
  bscTestnet: {
    IDO: '0xCDe8F1D7B0ACd2e6AA6D4AbcB76F57f16086EAb4',
    WBNB: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    USDT: '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684',
    LpTokenRouter: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
    multiCall: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    DrivenFarm: '0x2dcb513bb226e1ae4093f60b35eb003e89615fc9',
    addresses: [
      {
        //dvx
        DividendToken: '0xAc985e681dE045da8896b7b0ef0aa7f8666C9577',
        lockup: '0x05E05E8e6F75e2cf4bCB319638c3Ce722966EEa8',
        TokenToUsd: [
          '0xAc985e681dE045da8896b7b0ef0aa7f8666C9577',
          '0xae13d989dac2f0debff460ac112a837c89baa7cd',
          '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
          '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684',
        ],
        UsdToToken: [
          '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684',
          '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
          '0xae13d989dac2f0debff460ac112a837c89baa7cd',
          '0xAc985e681dE045da8896b7b0ef0aa7f8666C9577',
        ],
        LpToken: '0x6a747248E115d6A0C90BBe6F4Fe52cf04502F2f5',
      },
    ],
  },

  fuji: {
    WBNB: '0xd00ae08403b9bbb9124bb305c09058e32c39a48c',
    IDO: '0xf9aDD17E533308005B435Acf6F9145915bF35fb8',
    LpTokenRouter: '0x688d21b0B8Dc35971AF58cFF1F7Bf65639937860',
    multiCall: '0x00AD29bbBa3162FC760Bf26061714476304914cB',
    addresses: [
      {
        factory: '0x2a496ec9e9bE22e66C61d4Eb9d316beaEE31A77b',
        DividendToken: '0xC6F627bebbeC4f34827a6Fc9d52Df21e4df3540c', // BTF token
        lockup: '0x748FA8feD580485680461A6BbB63F787B6ea55BA', // staking
      },
    ],
    LimitOrder: '0x5D176F9A450FaC499697501ca7B7530897304625',
  },

  Avalanch: {
    IDO: '0xA00ed313C79eA0736f3C608126dEEcB46cb47f4F',
    WBNB: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    USDT: '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684',
    LpTokenRouter: '0xe54ca86531e17ef3616d22ca28b0d458b6c89106', // Final Router
    multiCall: '0x29b6603D17B9D8f021EcB8845B6FD06E1Adf89DE',
    DrivenFarm: '0x2dcb513bb226e1ae4093f60b35eb003e89615fc9',
    LimitOrder: '0xcfE72375EC49664c07Eb163448D1CDa1618F470d',
    addresses: [
      {
        factory: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88', // final
        DividendToken: '0x405eb25c5edbFd227a72D69e7eD9dB8860637F00',
        lockup: '0xDDf2c401bAA314925ed55eA90bb11DC48C3005F9',
        TokenToUsd: [
          '0x453ca0bBEA5b409FDEc46A070D6485bB899c90DE',
          '0xae13d989dac2f0debff460ac112a837c89baa7cd',
          '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
          '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684',
        ],
        UsdToToken: [
          '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684',
          '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
          '0xae13d989dac2f0debff460ac112a837c89baa7cd',
          '0x453ca0bBEA5b409FDEc46A070D6485bB899c90DE',
        ],
        LpToken: '0x8159E346Ced54a7eca0f67e78C0a08D73065d7D6',
      },
    ],
  },
};

export const addressToLogoMap = new Map();
addressToLogoMap.set('BTF');
addressToLogoMap.set('MELONx', 'https://s2.coinmarketcap.com/static/img/coins/64x64/14191.png');

export default contract[activeNetwork];
