import React, { useState } from 'react';
import PairPanel from './MarketPanel/PairPanel';
import MarketPanel from './MarketPanel';
import ChartTable from './ChartTable';
import HistoryPanel from './HistoryPanel';
import OrderPanel from './ChartTable/OrderPanel';
import styled from 'styled-components';
import { BookSVG, FundingSVG, HistorySVG, TradingSVG } from '../../utils/svgs';

export default function Trades() {
  const [curField, setCurField] = useState(0);
  return (
    <StyledContainer className="swap_page_cont bg_grad flex h-[calc(100vh-84px)] flex-1 justify-between rounded-tl-3xl bg-light-body-bg p-0 ease-in dark:bg-dark-body-bg sm:p-4">
      <div className="flex hidden h-full flex-col justify-between 2xl:block">
        <div className="h-[55%]">
          <PairPanel />
        </div>
        <div className="mt-1.5" />
        <div className="h-[calc(45%-6px)]">
          <MarketPanel />
        </div>
      </div>

      <div className=" mx-1.5 h-full flex-1">
        <div className="h-[55%]">
          <ChartTable />
        </div>
        <div className="mt-1.5" />
        <div className="block h-[calc(45%-86px)] 2xl:hidden">
          {curField === 0 ? (
            <PairPanel />
          ) : curField === 1 ? (
            <MarketPanel />
          ) : curField === 2 ? (
            <OrderPanel />
          ) : (
            <HistoryPanel />
          )}
        </div>
        <div className="hidden h-[calc(45%-6px)] 2xl:block">
          <OrderPanel />
        </div>

        <div className="block w-full rounded-b-md bg-[#1b1b1b] 2xl:hidden">
          <div className="mx-auto flex h-[80px] w-fit items-center text-xs ">
            <div
              className={`mx-1 flex w-[75px] cursor-pointer flex-col items-center rounded-lg py-2 transition hover:bg-[rgba(255,255,255,0.05)] sm:w-[100px] ${
                curField === 0 ? 'bg-[rgba(255,255,255,0.05)] text-white' : 'text-[#6f6e84]'
              }`}
              onClick={() => setCurField(0)}
            >
              <div>{BookSVG}</div>
              <div className="mt-1">Pairs</div>
            </div>
            <div
              className={`mx-1 flex w-[75px] cursor-pointer flex-col items-center rounded-lg py-2 transition hover:bg-[rgba(255,255,255,0.05)] sm:w-[100px] ${
                curField === 1 ? 'bg-[rgba(255,255,255,0.05)] text-white' : 'text-[#6f6e84]'
              }`}
              onClick={() => setCurField(1)}
            >
              <div>{TradingSVG}</div>
              <div className="mt-1">Trade</div>
            </div>
            <div
              className={`mx-1 flex w-[75px] cursor-pointer flex-col items-center rounded-lg py-2 transition hover:bg-[rgba(255,255,255,0.05)] sm:w-[100px] ${
                curField === 2 ? 'bg-[rgba(255,255,255,0.05)] text-white' : 'text-[#6f6e84]'
              }`}
              onClick={() => setCurField(2)}
            >
              <div>{FundingSVG}</div>
              <div className="mt-1">Orders</div>
            </div>
            <div
              className={`mx-1 flex w-[75px] cursor-pointer flex-col items-center rounded-lg py-2 transition hover:bg-[rgba(255,255,255,0.05)] sm:w-[100px] ${
                curField === 3 ? 'bg-[rgba(255,255,255,0.05)] text-white' : 'text-[#6f6e84]'
              }`}
              onClick={() => setCurField(3)}
            >
              <div>{HistorySVG}</div>
              <div className="mt-1">History</div>
            </div>
          </div>
        </div>
      </div>
      <div className=" hidden h-full 2xl:block">
        <HistoryPanel />
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    display: block !important;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb:vertical {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #eebb19;
  }
`;
