export const breakPoints = {
  sm: '640px',
  // => @media (min-width: 640px) { ... }

  md: '768px',
  // => @media (min-width: 768px) { ... }

  lg: '1024px',
  // => @media (min-width: 1024px) { ... }

  xl: '1280px',
  // => @media (min-width: 1280px) { ... }

  '2xl': '1536px',
  // => @media (min-width: 1536px) { ... }
};

function getMonthName(month) {
  return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][
    month
  ];
}

function UnixTimeToDate(unix_timestamp) {
  var date = new Date(unix_timestamp * 1000);
  var day = date.getDate();
  var month = getMonthName(date.getMonth());
  var hours = date.getHours();
  var minutes = '0' + date.getMinutes();
  var seconds = '0' + date.getSeconds();

  var formattedTime = month + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  var formattedTime = day + ' ' + month;

  return formattedTime;
}

let chartData = [
  {
    timestamp: '1672218660000',
    price: '0.011179',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1672477860000',
    price: '0.010853',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1672737060000',
    price: '0.009806',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1672996260000',
    price: '0.009683',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1673255460000',
    price: '0.009034',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1673514660000',
    price: '0.009418',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1673773860000',
    price: '0.008816',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1674033060000',
    price: '0.008668',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1674292260000',
    price: '0.008492',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1674551460000',
    price: '0.008091',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1674810660000',
    price: '0.007692',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1675069860000',
    price: '0.007295',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1675329060000',
    price: '0.00684',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1675588260000',
    price: '0.006702',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1675847460000',
    price: '0.005866',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1676106660000',
    price: '0.005558',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1676365860000',
    price: '0.005636',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1676625060000',
    price: '0.006078',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1676884260000',
    price: '0.00598',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1677143460000',
    price: '0.005601',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1677402660000',
    price: '0.006444',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1677661860000',
    price: '0.006147',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1677921060000',
    price: '0.005647',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1678180260000',
    price: '0.004459',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1678439460000',
    price: '0.004702',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1678698660000',
    price: '0.004465',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1678957860000',
    price: '0.004174',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1679217060000',
    price: '0.004021',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1679476260000',
    price: '0.00367',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1679735460000',
    price: '0.003811',
    __typename: 'PriceAt',
  },
  {
    timestamp: '1679994660000',
    price: '0.003811',
    __typename: 'PriceAt',
  },
];

export const ChartData = chartData;

// export const ChartData = chartData.map((data) => {
//   data.timestamp = UnixTimeToDate(data.timestamp);
//   return data;
// });


export const userData = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 60000,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
]