import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { setIsProvide } from '../store/DropdownMenuSlice';
import PoolLiquidity from './PoolLiquidity';
import Trending from './Trending';
import WidthdrawLiquidity from './WidthdrawLiquidity';
import WithdrawTable from './WithdrawTable';

export default function Pool() {
  const isProvided = useSelector((state) => state.dropdown.isProvide);
  const [isProvide, setIsProvided] = useState(isProvided);
  const { address } = useAccount();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsProvided(isProvided);
  }, [isProvided]);

  return (
    <>
      <div className="bg_grad h-[calc(100vh-84px)] flex-1 overflow-y-scroll rounded-tl-3xl bg-light-body-bg dark:bg-dark-body-bg sm:p-8">
        <div className="relative m-4 rounded-[10px] dark:bg-bg-main">
          <header
            className={`mb-5 flex items-center justify-between p-5 ${
              isProvide ? 'gap-5' : 'gap-2'
            }`}
          >
            <div className="flex-auto text-ellipsis font-mulish  text-base font-semibold tracking-wider text-white">
              {isProvide ? 'Pool Liquidity' : 'Withdraw Liquidity'}
            </div>
            <div className="flex gap-3">
              <div
                className={`cursor-pointer  pb-1 font-normal tracking-wider ${
                  isProvide
                    ? 'border-b-2 border-b-primary-sunny-main text-lg text-primary-sunny-main'
                    : ' text-base'
                }`}
                onClick={() => {
                  dispatch(setIsProvide(true));
                }}
              >
                Provide
              </div>
              <div
                className={`cursor-pointer  pb-1 font-normal tracking-wider  ${
                  !isProvide
                    ? 'border-b-2 border-b-primary-sunny-main text-lg text-primary-sunny-main'
                    : 'text-base'
                }`}
                onClick={() => {
                  dispatch(setIsProvide(false));
                }}
              >
                Withdraw
              </div>
            </div>
          </header>
          {isProvide ? <PoolLiquidity /> : <WidthdrawLiquidity />}
        </div>
        {address && (
          <div className="relative m-4 rounded-[10px] dark:bg-bg-main">
            <WithdrawTable />
          </div>
        )}
        {/* <div className="relative m-4 rounded-[10px] dark:bg-bg-main">
          <Trending />
        </div> */}
      </div>
    </>
  );
}
