import styled from 'styled-components';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import useTradeInfo from '../../../hooks/useTradeContext';
import { SkeletonComponent } from '../../SkeletonComponent';
import { PriceComponent } from '../../PriceComponent';
import { numberWithCommas } from '../../../utils/functions';

const ChartTable = () => {
  const { selectedPair, setSelectedPair, pairs } = useTradeInfo();
  const pair = pairs[selectedPair];
  return (
    <StyledContainer className="flex h-full flex-col text-xs ">
      <div className="mb-4 flex w-[calc(100vw-52px)] items-center overflow-x-scroll sm:w-[calc(100vw-84px)] md:sm:w-full">
        <div className="flex items-center">
          <img src={pair.token0.logo} alt={''} className="mr-4 h-8 w-8" />
          <div>
            <div>{pair.token0.name}</div>
            <div>
              {pair.token0.symbol}/{pair.token1.symbol}
            </div>
          </div>
        </div>
        <div className="ml-6 flex flex-col items-center text-center">
          <div className="whitespace-nowrap md:whitespace-normal">Last Price</div>
          <div>
            {pair.price ? (
              <PriceComponent value={pair.price.toFixed(2)} change={pair.priceChange} />
            ) : (
              <SkeletonComponent />
            )}
          </div>
        </div>
        <div className="ml-6 flex flex-col items-center text-center">
          <div className="whitespace-nowrap md:whitespace-normal">24H Change</div>
          <div>
            {pair.priceChange ? (
              <PriceComponent
                value={pair.priceChange.toFixed(2)}
                change={pair.priceChange} 
                suffix={'%'}
                prefix={'+'}
              />
            ) : (
              <SkeletonComponent />
            )}
          </div>
        </div>
        <div className="ml-6 flex flex-col items-center text-center">
          <div className="whitespace-nowrap md:whitespace-normal">24H High</div>
          <div>{pair.h ? numberWithCommas(pair.h.toFixed(2)) : <SkeletonComponent />}</div>
        </div>
        <div className="ml-6 flex flex-col items-center text-center">
          <div className="whitespace-nowrap md:whitespace-normal">24H Low</div>
          <div>{pair.l ? numberWithCommas(pair.l.toFixed(2)) : <SkeletonComponent />}</div>
        </div>
        <div className="ml-6 flex flex-col items-center text-center">
          <div className="whitespace-nowrap md:whitespace-normal">
            24H Volume ({pair.token0.symbol})
          </div>
          <div>
            {pair.token0.volume ? (
              numberWithCommas(pair.token0.volume.toFixed(2))
            ) : (
              <SkeletonComponent />
            )}
          </div>
        </div>
        <div className="ml-6 flex flex-col items-center text-center">
          <div className="whitespace-nowrap md:whitespace-normal">
            24H Volume ({pair.token1.symbol})
          </div>
          <div>
            {pair.token1.volume ? (
              numberWithCommas(pair.token1.volume.toFixed(2))
            ) : (
              <SkeletonComponent />
            )}
          </div>
        </div>
      </div>
      <div className="flex-1">
        <TradingViewWidget symbol={pair.tradingView} theme={Themes.DARK} locale="en" autosize />
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background: #1b1b1b;
  border-radius: 6px;
  padding: 20px;
  > div::-webkit-scrollbar {
    display: none !important;
  }
`;
export default ChartTable;
