/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { pairs as defaultPairs } from '../utils/pairs';
import useRefresh from './useRefresh';
import axios from 'axios';

const defaultVal = {
  pairs: [],
  selectedPair: 0,
  setSelectedPair: () => {},
  pending: false,
  setPending: () => {},
};

export const TradeContext = React.createContext(defaultVal);

export default function useTradeInfo() {
  return React.useContext(TradeContext);
}

async function fetchPrice(address, chainId, resolution = 10) {
  const to = Math.floor(Date.now() / 1000);
  const url = `https://api.dex.guru/v1/tradingview/history?symbol=${address}-${
    chainId === 43114 ? 'avalanche' : ''
  }_USD&resolution=${resolution}&from=${to - 3600 * 24}&to=${to}`;
  let result = await axios.get(url);
  return result.data;
}

export function TradeProvider({ children }) {
  const [pairs, setPairs] = useState(defaultPairs);
  const [selectedPair, setSelectedPair] = useState(0);
  const [pending, setPending] = useState(false);
  
  const { fastRefresh } = useRefresh();


  async function fetchPublicPairInfo() {
    try {
      const priceResult = await Promise.all(
        pairs.map(async (data) => {
          const tokenPrices = await Promise.all([
            fetchPrice(data.token0.address, data.chainId),
            fetchPrice(data.token1.address, data.chainId),
          ]);
          let v1 = 0,
            v2 = 0;
          for (let i = 0; i < tokenPrices[0].v.length; i++) {
            v1 += tokenPrices[0].v[i];
            v2 += tokenPrices[1].v[i];
          }

          const token0 = {
            price: tokenPrices[0].c[tokenPrices[0].c.length - 1],
            volume: v1,
            h: tokenPrices[0].h[tokenPrices[0].h.length - 1],
            l: tokenPrices[0].l[tokenPrices[0].l.length - 1],
            prevPrice: tokenPrices[0].c[0],
          };
          const token1 = {
            price: tokenPrices[1].c[tokenPrices[1].c.length - 1],
            volume: v2,
            h: tokenPrices[1].h[tokenPrices[1].h.length - 1],
            l: tokenPrices[1].l[tokenPrices[1].l.length - 1],
            prevPrice: tokenPrices[1].c[0],
          };
          return { token0, token1 };
        })
      );
      let _pairs = pairs;
      for (let i = 0; i < _pairs.length; i++) {
        const price = priceResult[i].token0.price / priceResult[i].token1.price;
        const prevPrice = priceResult[i].token0.prevPrice / priceResult[i].token1.prevPrice;
        const h = priceResult[i].token0.h / priceResult[i].token1.h;
        const l = priceResult[i].token0.l / priceResult[i].token1.l;
        const priceChange = ((price - prevPrice) / prevPrice) * 100;
        _pairs[i] = {
          ..._pairs[i],
          token0: {
            ..._pairs[i].token0,
            ...priceResult[i].token0,
          },
          token1: {
            ..._pairs[i].token1,
            ...priceResult[i].token1,
          },
          price,
          prevPrice,
          priceChange,
          h,
          l,
        };
      }
      setPairs(_pairs);
      console.log(_pairs);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchPublicPairInfo();
  }, [fastRefresh]);

  return (
    <TradeContext.Provider
      value={{ pairs, selectedPair, setSelectedPair, pending, setPending }}
      children={children}
    />
  );
}
