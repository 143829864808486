import { createSlice } from "@reduxjs/toolkit"
let initialState = {
    open: false,
    modalData: {
        contractType: '',
        term: '',
        lockupAddress: '',
        stakingToken: '',
        stackType: null,
        duration: null,
        total: null,
      },
}

export const UnstakSlice = createSlice({
    name: "UnstakSlice",
    initialState: {
        ...initialState
    },
    
    reducers: {
        setUnstakeOpen: (data, action) => {
            data.open = action.payload;
        },

        setUnstakeModalData: (data, action) => {
            data.modalData = action.payload;
        },
    },
})

export const { setUnstakeOpen, setUnstakeModalData } = UnstakSlice.actions;
export default UnstakSlice.reducer