import { createColumnHelper } from '@tanstack/react-table';
import CountUp from 'react-countup';
import Logo from '../../assets/logo.png';
import AVX from '../../assets/avx.png';
import store from '../../store/configStore';
import { setOpen, setModalData } from '../../store/ModalSlice';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { setIsProvide, setWithDrawData } from '../../store/DropdownMenuSlice';
import { setRefresh } from '../../store/CustomTokenSlice';
import MyStakeDisplayTime from '../layout/MyStakeDisplayTime';

const columnHelper = createColumnHelper();

export const stake_columns = [
  columnHelper.accessor((row) => row.term, {
    id: 'term',
    cell: (info) => (
      <div className="flex w-[120%] flex-row flex-wrap justify-start gap-2 px-5 py-5 text-center text-xs tracking-wider sm:text-base">
        <div className="flex items-center">
          <img src={Logo} alt="logo" className="h-5 w-5" />
        </div>
        <div>{info.getValue()}</div>
      </div>
    ),
    header: () => <div className="pl-5 text-left font-normal">Term</div>,
    enableSorting: false,
  }),
  columnHelper.accessor(
    (row) => {
      return { apy: row.apy, term: row.term };
    },
    {
      id: 'apy',
      cell: (info) => (
        <div className="px-2 py-5 text-left text-xs text-green-400 sm:text-base">
          {info.getValue().term === '1 year' && '2'}
          {info.getValue().term === '2 years' && '3.5'}
          {info.getValue().term === '3 years' && '5'}
          {info.getValue().term === '4 years' && '6.5'}%
          {/* {
        info.getValue().apy.toFixed(2)
        } % */}
        </div>
      ),
      header: () => <div className="!px-2">APY</div>,
      enableSorting: false,
    }
  ),
  columnHelper.accessor((row) => row.totalStaked, {
    id: 'totalStaked',
    cell: (info) => (
      <div className="px-2 py-5 text-left text-xs sm:text-base">
        {/* {info.getValue()} */}
        <CountUp
          start={0}
          end={parseFloat(info.getValue())}
          prefix=""
          suffix={' $BTF'}
          decimals={3}
          duration={1}
          separator=","
        />
      </div>
    ),
    header: 'Total Staked',
    enableSorting: false,
  }),
  columnHelper.accessor('Action', {
    id: 'Action',
    cell: (info) => {
      return (
        <div className="cursor-pointer p-5 text-right">
          <button
            className="w-fit rounded-lg border border-primary-sunny-main px-1 py-2 text-xs uppercase tracking-wider duration-300 ease-in-out hover:bg-primary-sunny-dark sm:w-2/4 md:w-4/5"
            onClick={() => {
              // console.log('Clicked', info.row.original);
              store.dispatch(setOpen(true));
              store.dispatch(setModalData(info.row.original));
            }}
          >
            stake
          </button>
        </div>
      );
    },
    header: () => <div className="pr-5 text-right">Action</div>,
    enableSorting: false,
  }),
];

const handlRemove = (data) => {
  store.dispatch(setRefresh(true));
  let localStorageData = localStorage.getItem('pairToken');
  localStorageData = JSON.parse(localStorageData);
  const newData = localStorageData.filter(({ pairAddress: pa }) => pa !== data.pairAddress);
  localStorage.setItem('pairToken', JSON.stringify(newData));
  // store.dispatch(setRefresh(false));
};

export const pool_columns = [
  columnHelper.accessor((row) => row.pool, {
    id: 'pool',
    cell: (info) => (
      <div className="flex w-[120%] flex-row flex-wrap justify-start gap-2 pl-2 text-left text-xs tracking-wider sm:px-5 sm:py-5 sm:text-center sm:text-base">
        <div className="flex items-center">
          <img src={Logo} alt="logo" className="h-5 w-5" />
          <img src={AVX} alt="logo" className="-ml-3 h-6 w-8" />
        </div>
        <div>{info.getValue()}</div>
      </div>
    ),
    header: () => <div className="pl-2 text-left font-normal">Pool</div>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.lpBal, {
    id: 'lpBal',
    cell: (info) => (
      <div className="px-4 py-5 text-center text-xs sm:text-base">
        <span className="text-green-400">{info.getValue().toFixed(18)}</span> LP
      </div>
    ),
    header: () => <div className="!px-2 text-center">LP Balance</div>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.poolShare, {
    id: 'poolShare',
    cell: (info) => (
      <div className="px-5 py-5 text-center text-xs sm:text-base">
        {info.getValue().toFixed(20)} %
      </div>
    ),
    header: () => <div className="!px-2 text-center">Pool Share</div>,
    enableSorting: false,
  }),
  columnHelper.accessor('Action', {
    id: 'Action',
    cell: (info) => {
      return (
        <div className=" p-5 text-right">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild className="focus-visible:outline-none">
              <button
                className="IconButton cursor-pointer rounded-full border"
                aria-label="Customise options"
              >
                <MoreHorizOutlinedIcon />
              </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="DropdownMenuContent mi-w-[200px] rounded-lg bg-bg-main p-3 duration-300 ease-linear focus-visible:outline-none"
                sideOffset={5}
              >
                <DropdownMenu.Item
                  className="DropdownMenuItem cursor-pointer rounded px-1 py-2 hover:bg-dark-bg hover:text-primary-sunny-dark focus-visible:outline-none"
                  onClick={() => store.dispatch(setIsProvide(true))}
                >
                  Provide
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  className="DropdownMenuItem cursor-pointer rounded px-1 py-2 hover:bg-dark-bg hover:text-primary-sunny-dark focus-visible:outline-none"
                  onClick={() => {
                    console.log('info.row.original', info.row.original);
                    store.dispatch(setWithDrawData(info.row.original));
                    store.dispatch(setIsProvide(false));
                  }}
                >
                  Withdraw
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  className="DropdownMenuItem cursor-pointer rounded px-1 py-2 hover:bg-dark-bg hover:text-primary-sunny-dark focus-visible:outline-none"
                  onClick={() => {
                    handlRemove(info.row.original);
                  }}
                >
                  Remove
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      );
    },
    header: () => <div className="pr-5 text-right">Action</div>,
    enableSorting: false,
  }),
];

export const topTrading_column = [
  columnHelper.accessor((row) => row.symbol, {
    id: 'symbol',
    cell: (info) => (
      <div className="flex w-[120%] flex-row flex-wrap justify-start gap-2 pl-2 text-left text-xs tracking-wider sm:px-5 sm:py-5 sm:text-center sm:text-base">
        <div className="flex items-center">
          <img src={Logo} alt="logo" className="h-5 w-5" />
          <img src={AVX} alt="logo" className="-ml-3 h-6 w-8" />
        </div>
        <div>{info.getValue().replace('/_/g', '-')}</div>
      </div>
    ),
    header: () => <div className="pl-2 text-left font-normal">Ticker</div>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.totalLocked, {
    id: 'totalLocked',
    cell: (info) => (
      <div className="px-2 py-5 text-left text-xs sm:text-base">
        {/* {info.getValue()} */}
        <CountUp
          start={0}
          end={parseFloat(info.getValue())}
          prefix={'$ '}
          suffix={''}
          decimals={6}
          duration={1}
          separator=","
        />
      </div>
    ),
    header: () => <div className="pl-2 text-left font-normal">Total Locked</div>,
  }),
  columnHelper.accessor((row) => row.sevenDaysVolume, {
    id: 'sevenDaysVolume',
    cell: (info) => (
      <div className="px-2 py-5 text-left text-xs sm:text-base">
        {/* {info.getValue()} */}
        <CountUp
          start={0}
          end={parseFloat(info.getValue())}
          prefix={'$ '}
          suffix={''}
          decimals={6}
          duration={1}
          separator=","
        />
      </div>
    ),
    header: () => <div className="pl-2 text-left font-normal">7 Days Volume</div>,
  }),
  columnHelper.accessor((row) => row.APY, {
    id: 'apy',
    cell: (info) => (
      <div className="px-2 py-5 text-left text-xs text-green-400 sm:text-base">
        {parseFloat(info.getValue()).toFixed(2)} %
      </div>
    ),
    header: () => <div className="!px-2">APY</div>,
  }),
];

export const ido_column = [
  columnHelper.accessor((row) => row.depositAmount, {
    id: 'deposit_amount',
    cell: (info) => (
      <div className="flex items-center justify-center gap-2 px-2 py-5 text-center text-xs sm:text-base">
        <span>{info.getValue() || '---'}</span> <span>{info.getValue() && 'USDT'}</span>
      </div>
    ),
    header: () => <div className="text-center">Deposit Amount</div>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.claimableAmount, {
    id: 'claimable_amount',
    cell: (info) => (
      <div className="flex items-center justify-center gap-2 px-2 py-5 text-center text-xs sm:text-base">
        <span>{info.getValue() || '---'}</span> <span>{info.getValue() && '$BTF'}</span>
      </div>
    ),
    header: () => <div className="text-center">Claimable Amount</div>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.rewardAmount, {
    id: 'reward_amount',
    cell: (info) => (
      <div className="flex items-center justify-center gap-2 px-2 py-5 text-center text-xs text-green-400 sm:text-base">
        <span>{info.getValue() || '---'}</span> <span>{info.getValue() && '$BTF'}</span>
      </div>
    ),
    header: () => <div className="text-center">Reward Amount</div>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.lockExpired, {
    id: 'lock_expired',
    cell: (info) => (
      // <div className="px-2 py-5 text-center text-xs sm:text-base">{info.getValue()}</div>
      <div className="px-2 py-5 text-center text-xs sm:text-base">
        <MyStakeDisplayTime time={info.getValue()} />
      </div>
    ),
    header: () => <div className="text-center">Lock Expiry</div>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row, {
    id: 'Action',
    cell: (info) => {
      let action = info.getValue();
      return (
        <div
          className={`${
            (action.claimDisbled || action.isClaimed) && ' disabled_button '
          } cursor-pointer p-5 text-center`}
        >
          <button
            className={` w-fit rounded-lg border border-primary-sunny-main px-1 py-2 text-xs uppercase tracking-wider duration-300 ease-in-out hover:bg-primary-sunny-dark sm:w-2/4 md:w-4/5`}
            onClick={() => {
              action.claimMethod(action.index);
            }}
          >
            Claim
          </button>
        </div>
      );
    },
    header: () => <div className="text-center">Action</div>,
    enableSorting: false,
  }),
];
