import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import TableContent from './layout/TableContent';
import { ido_column } from './Helper/ColumnHelper';
import CustomeConnectButton from './layout/CustomeConnectButton';
import ERC20ABI from '../ABI/ERC20ABI.json';
import { useAccount, useBalance, useProvider, useSigner } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
import ColourfulLoadingIcon from '../assets/colorfulLoading2.gif';
import { Skeleton } from '@mui/material';
import { ethers } from 'ethers';
import { getIDOAddress } from '../utils/addressHelpers';
import IDOABI from '../ABI/IDOABI.json';
import { useLocation, useSearchParams } from 'react-router-dom';
import { setSwapboxAlert } from '../store/SwapboxAlertSlice';
import copy from 'copy-to-clipboard';
import { IDOModal } from './layout/IDOModal';
import axios from 'axios';

const IDO = () => {
  /**Helpers */
  const { address } = useAccount();
  const provider = useProvider();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referal = queryParams.get('referal');
  const { host, pathname } = window.location;

  const { data: signer } = useSigner();
  const dispatch = useDispatch();
  const textRef = useRef(null);

  const IDO_address = getIDOAddress();

  /**State Selectors */
  let isWalletConnected = useSelector((state) => state.currenciesSuggestions.isWalletConnected);
  let SwapboxAlert = useSelector((state) => state.swapboxAlert);

  /**Mutable State */
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [usdtBalance, setUsdtBalance] = useState('');
  const [isClaim, setIsClaim] = useState(false);
  const [filterKey, setFilterkey] = useState('');
  const [disabledBody, setDisabledBody] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [rate, setRate] = useState();
  const [earnedCommision, setEarnedCommision] = useState('');
  const [hasBeenReferred, setHasBeenReferred] = useState(false);
  const [dipositorRate, setDipositorRate] = useState();
  const [depositSize, setDepositSize] = useState();
  const [lockPeriod, setLockPeriod] = useState(0);
  const [_copy, setCopy] = useState(false);

  const deposite_reference = useMemo(() => {
    if (address === referal) {
      return '0x0000000000000000000000000000000000000000';
    }
    if (!hasBeenReferred) {
      if (referal) {
        return referal;
      }
    }

    return '0x0000000000000000000000000000000000000000';
  }, [hasBeenReferred, address, referal]);
  const [disabledApprove, setDisabledApprove] = useState(true);
  const [disabledDeposite, setDisabledDeposite] = useState(true);
  const [usdt_address, setUsdt_address] = useState('');
  const [IDO_TABLE, setIdo_Table] = useState([
    // {
    //   depositAmount: 100,
    //   claimableAmount: 200,
    //   rewardAmount: 10,
    //   lockExpired: '10/01/2024 13:00',
    //   claimDisbled: true,
    // },
    // {
    //   depositAmount: 150,
    //   claimableAmount: 300,
    //   rewardAmount: 15,
    //   lockExpired: '10/01/2024 13:00',
    //   claimDisbled: true,
    // },
    // {
    //   depositAmount: 200,
    //   claimableAmount: 400,
    //   rewardAmount: 20,
    //   lockExpired: '10/01/2024 13:00',
    //   claimDisbled: true,
    // },
  ]);
  const [_idoTable, set_Idotable] = useState(IDO_TABLE);

  /**Internal Methods */
  const handleClose = () => setOpen(false);
  const handleInputChange = (event) => {
    const { value } = event.target;
    if (!value) {
      setInputValue('');

      return;
    }

    if (!isNaN(value)) {
      setInputValue(value);
    }
  };
  let textBoxValue = host + pathname + '?referal=' + address;

  const handleCopy = async () => {
    if (!isWalletConnected) return;
    const textBoxValue = host + pathname + '?referal=' + address;
    // navigator.clipboard
    //   .writeText(textBoxValue)
    //   .then(() => {
    //     toast.success('Text copied successfully!');
    //   })
    //   .catch((error) => {
    //     toast.error('Error copying text:', error);
    //   });
    copy(`${textBoxValue}`);
    setCopy(true);
    toast.success('Text copied successfully!');
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  const fetchTokenBalance = async () => {
    try {
      let _contract = new ethers.Contract(usdt_address, ERC20ABI, provider);
      const balance = await _contract.balanceOf(address);
      setUsdtBalance(ethers.utils.formatUnits(balance.toString(), 6));
    } catch (error) {
      console.log('balance_error balance fetching error IDO JSDVFJKD', error);
    }
  };

  const checkAllowance = async (token_address, decimal = 6, inputvalue) => {
    try {
      if (!usdt_address) return;
      const contract = new ethers.Contract(usdt_address, ERC20ABI, provider);

      const allowance = await contract.allowance(token_address, IDO_address);

      if (
        parseFloat(inputvalue) >
        parseFloat(ethers.utils.formatUnits(allowance?.toString(), decimal))
      ) {
        setDisabledApprove(false);
        if (!disabledDeposite) setDisabledDeposite(true);
      } else {
        setDisabledDeposite(false);
        if (!disabledApprove) setDisabledApprove(true);
      }
    } catch (error) {
      console.log('dvjkmfdnjkgvnjkf checkAllowance error ', error);
    }
  };

  function modifyFromBalance(percent) {
    if (!isWalletConnected) {
      toast.error('Please Connect Your wallet', { autoClose: 3000 });
      return;
    }
    if (percent === 100) {
      setInputValue(usdtBalance);
      return;
    }
    const balanceFormate = parseFloat(usdtBalance);

    setInputValue(((balanceFormate * percent) / 100).toFixed(6).toString());
  }

  async function Approve(_address) {
    if (!inputValue || isNaN(inputValue)) return;

    try {
      setDisabledBody(true);
      const contract = new ethers.Contract(usdt_address, ERC20ABI, signer);
      contract
        .approve(IDO_address, ethers.utils.parseUnits(inputValue, 6))
        .then((res) => {
          setDisabledBody(false);
          setDisabledApprove(true);
          setDisabledDeposite(false);
          toast.success('Approved', {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        })
        .catch((err) => {
          setDisabledBody(false);
          toast.error('Somethong went wrong');
          console.log('jkdvnjkdnvjn err', err);
        });
    } catch (err) {
      setDisabledBody(false);
      console.log('jkdvnjkdnvjn err', err);
    }
  }

  async function Deposit() {
    if (!inputValue || isNaN(inputValue)) return;
    try {
      setDisabledBody(true);

      let __contract = new ethers.Contract(IDO_address, IDOABI, signer);
      const tx = await __contract.deposit(
        ethers.utils.parseUnits(inputValue, 6),
        deposite_reference
      );
      await tx.wait();
      setDisabledBody(false);
      setInputValue('');
      fetchTokenBalance();
      toast.success('Deposited Successfully', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setTableLoading(true);

      __contract = new ethers.Contract(IDO_address, IDOABI, signer);

      const _tx = await __contract.getDeposit(address, depositSize);

      let claimableAmount = calculateClaimableAmount(_tx.amount, rate);
      let rewardAmount = calculateRewardAmount(claimableAmount, dipositorRate);
      let lockExpired = convertLockExpired(_tx.depositTime);

      const modified_table = [
        ...IDO_TABLE.reverse(),
        {
          depositAmount: parseFloat(ethers.utils.formatUnits(_tx.amount.toString(), 6)),
          claimableAmount,
          rewardAmount,
          lockExpired,
          index: depositSize,
          claimMethod: Claim,
          claimDisbled: _tx.isClaimed || checkForLockExpired(parseInt(_tx.depositTime.toString())),
          isClaimed: _tx.isClaimed,
          isLocked: checkForLockExpired(parseInt(_tx.depositTime.toString())),
          claimMethod: Claim,
        },
      ].reverse();
      setIdo_Table(modified_table);
      set_Idotable(modified_table);
      console.log('jkdvnjkdnvjn _tx', IDO_TABLE);
      setTableLoading(false);
    } catch (err) {
      setDisabledBody(false);
      setTableLoading(false);
      console.log('jkdvnjkdnvjn err', err);
    }
  }

  async function Claim(index) {
    try {
      setDisabledBody(true);

      let __contract = new ethers.Contract(IDO_address, IDOABI, signer);

      const tx = await __contract.claim(index);
      await tx.wait();
      setDisabledBody(false);
      toast.success('Claimed Successfully', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setTableLoading(true);
      refetchTableData();

      setTableLoading(false);
    } catch (err) {
      setDisabledBody(false);
      toast.error('Something went wrong');

      setTableLoading(false);
      console.log('jkdvnjkdnvjn err', err);
    }
  }

  async function claimReferral() {
    try {
      setDisabledBody(true);

      let __contract = new ethers.Contract(IDO_address, IDOABI, signer);
      const tx = await __contract.claimReferral();
      await tx.wait();
      setDisabledBody(false);
      toast.success('Claimed Successfully', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setEarnedCommision(0);
    } catch (err) {
      setDisabledBody(false);
      setTableLoading(false);
      console.log('jkdvnjkdnvjn err', err);
    }
  }

  const refetchTableData = async () => {
    try {
      setTableLoading(true);
      let __contract = new ethers.Contract(IDO_address, IDOABI, provider);

      const iterations = depositSize;

      const _idoTable = [];
      for (let index = 0; index < iterations; index++) {
        const tx = await __contract.getDeposit(address, index);
        let claimableAmount = calculateClaimableAmount(tx.amount, rate);
        let rewardAmount = calculateRewardAmount(claimableAmount, dipositorRate);
        let lockExpired = convertLockExpired(tx.depositTime);
        console.log('jvdjbjdvbjjdv', tx);

        _idoTable.push({
          depositAmount: parseFloat(ethers.utils.formatUnits(tx.amount.toString(), 6)),
          claimableAmount: isNaN(claimableAmount) ? '' : claimableAmount,
          rewardAmount: isNaN(rewardAmount) ? '' : rewardAmount,
          lockExpired,
          claimDisbled: tx.isClaimed || checkForLockExpired(parseInt(tx.depositTime.toString())),
          isClaimed: tx.isClaimed,
          isLocked: checkForLockExpired(parseInt(tx.depositTime.toString())),
          claimMethod: Claim,
          index,
        });
      }

      setIdo_Table(_idoTable.reverse());
      set_Idotable(_idoTable);
      setTableLoading(false);
    } catch (err) {
      console.log('jvdjbjdvbjjdv', err);
      toast.error('Something went wrong');
      setTableLoading(false);
    }
  };

  function calculateClaimableAmount(amount, rate) {
    const value = parseFloat(ethers.utils.formatUnits(amount.toString(), 6));
    return (value * (rate / 10)).toFixed(6).toString();
  }
  function calculateRewardAmount(claimableAmount, depositor_rate) {
    const percentage = depositor_rate / 10;
    return (claimableAmount * (percentage / 100)).toFixed(6).toString();
  }
  function convertLockExpired(hexTime) {
    // var oneYearAgoTimestamp = currentTimestamp - 365 * 24 * 60 * 60;

    const unix = parseInt(hexTime.toString());
    var _unix = unix + lockPeriod;
    return new Date(_unix * 1000).getTime() / 1000;
  }
  function checkForLockExpired(lockExpired) {
    var currentTimestamp = Math.floor(Date.now() / 1000);
    // var oneYearAgoTimestamp = currentTimestamp - 365 * 24 * 60 * 60;
    var oneYearAgoTimestamp = currentTimestamp - lockPeriod;
    var unixTimestamp = lockExpired;
    return !(unixTimestamp < oneYearAgoTimestamp || unixTimestamp === oneYearAgoTimestamp);
  }

  /**Effects */
  useEffect(() => {
    let __contract = new ethers.Contract(IDO_address, IDOABI, provider);
    __contract.usdt().then((res) => setUsdt_address(res));
  }, [IDO_address, IDOABI, provider]);

  useEffect(() => {
    if (isWalletConnected && usdt_address) fetchTokenBalance(address);
  }, [isWalletConnected, usdt_address, address]);

  useEffect(() => {
    if (inputValue && !isNaN(inputValue) && parseFloat(inputValue) !== 0) {
      checkAllowance(address, 6, inputValue);
    }
    if (parseFloat(inputValue) > parseFloat(usdtBalance)) {
      if (SwapboxAlert.alertName === '') {
        dispatch(
          setSwapboxAlert({
            alertName: 'insufficient_balance',
            message: 'Insufficient USDT balance',
          })
        );
      }
    } else {
      if (SwapboxAlert.alertName === 'insufficient_balance') {
        dispatch(
          setSwapboxAlert({
            alertName: '',
            message: '',
          })
        );
      }
    }
  }, [inputValue]);

  useEffect(() => {
    if (!usdt_address) {
      setDisabledApprove(true);
      setDisabledDeposite(true);
    }
  }, [usdt_address]);

  useEffect(() => {
    //
    if (address && rate && dipositorRate && depositSize)
      refetchTableData()
        .then((data) => {
          setTableLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setTableLoading(false);
        });
  }, [address, dipositorRate, rate, depositSize]);

  useEffect(() => {
    if (!address) return;
    setInputValue('');
    let __contract = new ethers.Contract(IDO_address, IDOABI, provider);
    __contract.rate().then((res) => {
      setRate(parseFloat(ethers.utils.formatUnits(res?.toString(), 0)));
    });
    __contract.DEPOSITOR_REWARD_RATE().then((res) => {
      setDipositorRate(parseFloat(res?.toString()));
    });
    __contract.getDepositSize(address).then((res) => {
      setDepositSize(parseInt(res?.toString()));
    });
    __contract.LOCKUP_PERIOD().then((res) => {
      setLockPeriod(parseInt(res?.toString()));
    });
    __contract.commission(address).then((res) => {
      setEarnedCommision(parseFloat(ethers.utils.formatUnits(res?.toString(), 6)));
    });
  }, [address]);

  useEffect(() => {
    if (!address) return;
    let __contract = new ethers.Contract(IDO_address, IDOABI, provider);
    __contract.hasDeposit(referal).then((res) => {
      setHasBeenReferred(res);
    });
  }, [referal]);

  useEffect(() => {
    switch (filterKey) {
      case 'Claimable':
        set_Idotable(
          IDO_TABLE.filter((em, index) => {
            return !em.claimDisbled;
          })
        );
        break;
      case 'Claimed':
        set_Idotable(
          IDO_TABLE.filter((em) => {
            return em.isClaimed;
          })
        );
        break;
      case 'Locked':
        set_Idotable(
          IDO_TABLE.filter((em, index) => {
            return em.isLocked;
          })
        );
        break;
      default:
        set_Idotable(IDO_TABLE);
        break;
    }
  }, [filterKey]);
  console.log('tabjdnjkvfn', typeof tableLoading, tableLoading);

  useEffect(() => {
    // let url = `${process.env.REACT_APP_API_URL}/check/ip`;
    let url = `https://beetrade-ob-backend.vercel.app/api/IPController/check/ip`;
    // let url = `http://192.168.29.189:4000/api/IPController/check/ip`;
    axios
      .get(url)
      .then((response) => {
        response.data.status === false ? setOpen(true) : setOpen(false);
      })
      .catch((error) => {
        console.log('error check ip', error);
      });
  }, [setOpen]);

  return (
    <>
      {open && <IDOModal open={open} handleClose={handleClose} setOpen={setOpen} />}
      <div
        className={
          disabledBody
            ? 'fixed inset-0 z-10 block h-screen w-screen items-center justify-center bg-overlay-bg bg-zinc-900 bg-opacity-20  backdrop-blur-sm'
            : 'hidden '
        }
      >
        <div className=" flex h-full w-full items-center justify-center bg-transparent">
          <img src={ColourfulLoadingIcon} className="colorful_loading_icon block  " />
        </div>
        {/* <Toast text={`Adding Liquidity`} hash={''} /> */}
      </div>

      <div className="bg_grad flex h-[calc(100vh-84px)]  flex-1 flex-col overflow-y-scroll rounded-tl-3xl bg-light-body-bg dark:bg-dark-body-bg">
        <div className="relative m-4 rounded-[10px] dark:bg-bg-main">
          <div className="p-5">
            <h1 className="font-mulish  text-base font-semibold tracking-wider text-white">BUY TOKEN</h1>
            <div className="flex flex-col justify-between gap-8 pt-5 sm:flex-row md:flex-col lg:flex-row">
              <div className="w-full md:w-full">
                <div className="flex w-full flex-col gap-5 rounded-xl bg-[#100e22] p-7 shadow-xl">
                  <div className="relative flex cursor-pointer items-center justify-between max-[425px]:text-sm">
                    <span className="font-thin">Purchase</span>
                    <div className="ido_balance_cont  flex items-center gap-1 text-[0.9rem] font-extralight">
                      <span>USDT Balance : </span>

                      <span className=" relative inline-block max-w-[4.5rem] animate-overlayShow overflow-hidden text-ellipsis   whitespace-nowrap pr-1 text-right">
                        {isWalletConnected ? usdtBalance || '0.0 ' + 'USDT' : '......'}
                      </span>
                      {isWalletConnected && (
                        <div className="ido_balance_popup absolute inline-block w-max overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-white px-4 py-2 font-sans font-medium text-black shadow-md">
                          {usdtBalance} USDT
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="my-2">
                    <div class="flex items-center justify-start gap-3 opacity-70 max-[425px]:justify-around max-[425px]:gap-1 max-[425px]:text-xs">
                      <div className="cursor-pointer" onClick={() => modifyFromBalance(25)}>
                        <span className="rounded bg-[#1b1b1b] px-3 py-1 duration-200 hover:border">
                          25%
                        </span>
                      </div>
                      <div className="cursor-pointer" onClick={() => modifyFromBalance(50)}>
                        <span className="rounded bg-[#1b1b1b] px-3 py-1 duration-200 hover:border">
                          50%
                        </span>
                      </div>
                      <div className="cursor-pointer" onClick={() => modifyFromBalance(75)}>
                        <span className="rounded bg-[#1b1b1b] px-3 py-1 duration-200 hover:border">
                          75%
                        </span>
                      </div>
                      <div className="cursor-pointer" onClick={() => modifyFromBalance(100)}>
                        <span className="rounded bg-[#1b1b1b] px-3 py-1 duration-200 hover:border">
                          MAX
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between rounded-lg border-[2px] border-gray-500 px-3 py-2">
                    <input
                      className="w-full bg-transparent py-1 text-white opacity-70 max-[425px]:text-xs"
                      type="text"
                      value={inputValue}
                      onChange={handleInputChange}
                      placeholder="Enter USDT"
                    />
                    <div className="flex flex-col items-end justify-center px-2 text-xs font-extralight">
                      <span className="text-sm tracking-widest max-[425px]:text-sm">
                        {inputValue && 'USDT'}
                      </span>
                    </div>
                    <button
                      className="hidden rounded-md border border-transparent px-2 font-thin duration-200 hover:border-white max-[425px]:px-0"
                      onClick={() => modifyFromBalance(100)}
                    >
                      Max
                    </button>
                  </div>

                  {!address ? (
                    <>
                      <div className="swap_box_alert  !mt-0 self-center">
                        <div className="w-full ">
                          <span className=" text-center text-sm">Please connect your wallet</span>
                        </div>
                      </div>
                      <div className="connect_wallet_btn_background  w-max self-center">
                        <CustomeConnectButton shouldDisplay={true} />
                      </div>
                    </>
                  ) : SwapboxAlert.alertName ? (
                    <div className="flex w-full justify-center">
                      <div className="swap_box_alert  ">
                        <div className="flex w-full ">
                          <span className="text-sm">{SwapboxAlert.message}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-5 max-[425px]:flex-col xl:mx-10">
                      <button
                        onClick={() => {
                          Approve(address);
                        }}
                        className={`${
                          disabledApprove || !inputValue ? 'disabled_button ' : ''
                        }  relative w-full cursor-pointer rounded-md border border-primary-sunny-main px-5  py-3 text-lg uppercase tracking-wider  duration-200  ease-in-out  hover:bg-primary-sunny-dark max-[425px]:py-2  max-[425px]:text-sm`}
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => Deposit()}
                        className={`${
                          disabledDeposite || !inputValue ? 'disabled_button ' : ''
                        } w-full cursor-pointer rounded-md  border border-primary-sunny-main px-5  py-3 text-lg uppercase tracking-wider  duration-200  ease-in-out  hover:bg-primary-sunny-dark max-[425px]:py-2  max-[425px]:text-sm`}
                      >
                        Deposit
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex w-full flex-col justify-center">
                <div className="rounded-xl bg-[#100e22] p-5 shadow-xl">
                  <h1 className="mb-2 text-xl font-medium" style={{ wordSpacing: '0.3rem' }}>
                    Referal Link
                  </h1>
                  <div className="flex gap-3 max-[425px]:flex-col">
                    <div className="w-full rounded-lg border-[2px] border-gray-500 px-3 py-2">
                      <div
                        className={`w-full overflow-hidden text-ellipsis whitespace-nowrap bg-transparent  py-1 text-sm text-white ${
                          isWalletConnected ? ' opacity-70 ' : ' opacity-0'
                        } `}
                      >
                        {host + pathname + '?referal=' + address}
                      </div>
                    </div>
                    <button
                      onClick={handleCopy}
                      className={`${
                        !isWalletConnected && ' disabled_button '
                      } rounded-lg border border-primary-sunny-main px-6 text-sm leading-6 tracking-wide text-white hover:bg-primary-sunny-dark max-[425px]:py-2`}
                    >
                      {_copy ? 'Copied' : 'Copy'}
                    </button>
                  </div>
                </div>
                <div className="mt-4 rounded-xl bg-[#100e22] p-5 shadow-xl">
                  <h1
                    className="mb-2 w-full text-xl font-medium "
                    style={{ wordSpacing: '0.3rem' }}
                  >
                    Referal Comission
                  </h1>
                  <div className="flex items-center justify-between gap-3">
                    <span
                      className='
                      className="w-full bg-transparent text-sm text-white opacity-70'
                    >
                      <span className="pr-1">{earnedCommision}</span>
                      USDT
                    </span>
                    <button
                      onClick={claimReferral}
                      className={`${
                        !isWalletConnected && ' disabled_button '
                      } rounded-lg border border-primary-sunny-main px-6 py-2 text-sm leading-6 tracking-wide text-white hover:bg-primary-sunny-dark`}
                    >
                      Referal
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-10 flex gap-5 border-b-[1px] border-gray-600">
              {/* <div
                className={`cursor-pointer  pb-1 font-normal tracking-wider ${
                  !isClaim
                    ? 'border-b-2 border-b-primary-sunny-main text-primary-sunny-main'
                    : 'text-base'
                }`}
                onClick={() => {
                  setIsClaim(false);
                }}
              >
                Claimable
              </div>
              <div
                className={`cursor-pointer pb-1 font-normal tracking-wider ${
                  isClaim
                    ? 'border-b-2 border-b-primary-sunny-main text-primary-sunny-main'
                    : 'text-base'
                }`}
                onClick={() => {
                  set_Idotable(
                    IDO_TABLE.filter((em) => {
                      return em.isClaimed;
                    })
                  );
                }}
              >
                Claimed
              </div> */}
              {isWalletConnected && (
                <select
                  className="-my-4 mx-0 cursor-pointer  rounded-md border-[1px] border-[#6B7280] bg-[#100E22]  px-3 py-1"
                  onChange={(e) => setFilterkey(e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="Claimable" className="inline-block py-[0.8rem]">
                    Claimable
                  </option>
                  <option value="Locked" className="py-[0.8rem]">
                    Locked
                  </option>
                  <option value="Claimed" className="py-[0.8rem]">
                    Claimed
                  </option>
                </select>
              )}
            </div>
            {isWalletConnected &&
              (tableLoading == true ? (
                <div className="flex flex-col gap-5 px-6">
                  {Array(7)
                    .fill(null)
                    .map((item, i) => {
                      return (
                        <div key={i} className="flex w-full items-center gap-16">
                          {Array(5)
                            .fill(null)
                            .map((item, i) => {
                              return (
                                <Skeleton
                                  style={{
                                    width: '100%',
                                    height: '2.5rem',

                                    borderRadius: '2px',
                                    background: 'rgba(255,255,255,0.1)',
                                  }}
                                />
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <TableContent data={_idoTable} columns={ido_column} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default IDO;
