import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
/*Icons and logos*/
import swap_icon from '../assets/icons/swap.png';
import ColourfulLoadingIcon from '../assets/colorfulLoading2.gif';
/*Layouts and Modals */
import CurrencySuggestionsModal from './layout/currenciesSuggestionsModal';
import CustomeConnectButton from './layout/CustomeConnectButton';
import Toast from './shared/Toast';
/*Reducers */
import { setLabel, setDisplayCurrencies } from '../store/CurrenciesSuggestionsSlice';
import SwapChart from './Charts/SwapChart';
import { ChartData } from '../utils';
import { useAccount, useBalance, useProvider, useSigner } from 'wagmi';
/*Loader*/
import { BallTriangle } from 'react-loader-spinner';
/*Ether.js */
import ERC20ABI from '../ABI/ERC20ABI.json';
import FactoryABI from '../ABI/FujiFactoryABI.json';
import PangolinPairABI from '../ABI/PangolinPair.json';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

/*Helpers*/
import { getPairs, getAmountsOut, SwapAmount, SwapToken } from './Helper/SwappingHelper';
import { getAddresses, getRouterAddress } from '../utils/addressHelpers';
import { setRefresh } from '../store/CustomTokenSlice';
import PangolinRouterABI from '../ABI/PangolinRouterABI.json';
import { setSwapboxAlert } from '../store/SwapboxAlertSlice';
import Button from './shared/Button';
import { async } from 'q';
import { toast } from 'react-toastify';
import MissingImageIcon from '../assets/icons/Missing.svg';
import CoinGekoIds from '../utils/CoinGekoIds.json';

/*Rendering Swap Page*/
export default function Swap() {
  let addresses = getAddresses();
  let factoryAddress = addresses[0].factory;

  let [input, setInput] = React.useState('');
  let [output, setOutput] = React.useState('');
  let [slippage, setSlippage] = React.useState('');

  let from = useSelector((state) => state.currenciesSuggestions.converting_from);
  let to = useSelector((state) => state.currenciesSuggestions.converting_to);
  let SwapboxAlert = useSelector((state) => state.swapboxAlert);

  let isWalletConnected = useSelector((state) => state.currenciesSuggestions.isWalletConnected);

  const [overlaySwapBox, setOverlaySwapBox] = React.useState(false);
  const [convertingFrom, setConvertingFrom] = React.useState(from);
  const [balanceFrom, setBalanceFrom] = React.useState(BigNumber(0));
  // const [balanceFrom_modified, setBalanceFrom_modified] = React.useState(balanceFrom);

  const [convertingTo, setConvertingTo] = React.useState(to);
  const [balanceTo, setBalanceTo] = React.useState(BigNumber(0));

  const [isPaired, setIsPaired] = React.useState(false);
  let [disabledBody, setDisabledBody] = useState(false);

  const displayCurrencies = useSelector((state) => state.currenciesSuggestions.displayCurrencies);
  const label = useSelector((state) => state.currenciesSuggestions.label);
  const dispatch = useDispatch();
  const [isInputBalance, setIsInputBalance] = useState(false);
  const [resultGotten, setResultGotten] = React.useState(false);
  let balanceFromPopUp = React.useRef();
  let balanceToPopUp = React.useRef();

  //WAGMI
  const provider = useProvider();
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const routeraddress = getRouterAddress();

  const [isLoading, setIsLoading] = useState(false);

  let AVAXBalanceObj = useBalance({
    address: address,
  });

  const { data } = useBalance({ address });

  const balanceFromFormatted = useMemo(() => {
    return ethers.utils.formatUnits(balanceFrom.toString(), convertingFrom.decimals);
  }, [balanceFrom]);

  const balanceToFormatted = useMemo(() => {
    return ethers.utils.formatUnits(balanceTo.toString(), convertingTo.decimals);
  }, [balanceTo]);

  const useAvaxBalance = () => {
    const displayAlert = React.useCallback((text) => {
      alert(text);
    }, []);
    return { displayAlert };
  };

  //Effects
  useEffect(() => {
    fetchTokenBalance(convertingFrom.address, 'from');
    fetchTokenBalance(convertingTo.address, 'to');
    dispatch(setRefresh(false));
  }, []);

  useEffect(() => {
    fetchTokenBalance(convertingFrom.address, 'from');
    fetchTokenBalance(convertingTo.address, 'to');
  }, [isWalletConnected]);

  useEffect(() => {
    fetchTokenBalance(convertingFrom.address, 'from');
    fetchTokenBalance(convertingTo.address, 'to');
    checkForPairing();
    checkAllowance(
      convertingFrom.symbol,
      convertingFrom.address,
      convertingFrom.decimals,
      parseFloat(input.toString())
    );
    // Swapping(input);
  }, [convertingFrom, convertingTo]);

  useEffect(() => {
    fetchTokenBalance(convertingFrom.address, 'from');
    fetchTokenBalance(convertingTo.address, 'to');
  }, [disabledBody]);

  useEffect(() => {
    checkForPairing();
  }, [isWalletConnected]);

  useEffect(() => {
    setConvertingFrom(from);
    setConvertingTo(to);
    Swapping(input);
  }, [from.symbol, to.symbol]);

  const [displayleftAprv, setDisplayLeftAprv] = useState(false);

  // const [displayrightAprv, setDisplayRightAprv] = useState(false);

  useEffect(() => {
    // console.log('balanceFromFormatted', balanceFromFormatted, input);

    const inputvalue = parseFloat(input);
    const balancevalue = parseFloat(balanceFromFormatted);

    if (inputvalue > balanceFromFormatted) {
      if (SwapboxAlert.alertName === '') {
        dispatch(
          setSwapboxAlert({
            alertName: 'insufficient_balance',
            message: 'Insufficient ' + convertingFrom.symbol + ' balance',
          })
        );
      }
    } else {
      if (isInputBalance) Swapping(input, 'input_end');
      if (SwapboxAlert.alertName === 'insufficient_balance') {
        dispatch(
          setSwapboxAlert({
            alertName: '',
            message: '',
          })
        );
      }
    }

    if (!isNaN(input) && input > 0) {
      if (SwapboxAlert.alertName === 'invalid_input') {
        dispatch(
          setSwapboxAlert({
            alertName: '',
            message: '',
          })
        );
      }
    }
  }, [balanceFromFormatted, input, SwapboxAlert]);

  const checkAllowance = async (token_name, token_address, decimal, inputvalue) => {
    if (token_address === '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7') {
      setDisplayLeftAprv(false);
      return;
    }

    if (inputvalue === '') {
      setDisplayLeftAprv(false);
      setInput(inputvalue);
      setOutput(inputvalue);
    }
    try {
      const contract = new ethers.Contract(token_address, ERC20ABI, provider);
      const allowance = await contract.allowance(address, routeraddress);
      if (
        parseFloat(inputvalue) >
        parseFloat(ethers.utils.formatUnits(allowance?.toString(), decimal))
      ) {
        setDisplayLeftAprv(true);
      } else {
        setDisplayLeftAprv(false);
      }
    } catch (error) {
      console.log('checkAllowance', error);
    }
  };

  const handleApprove = async (value) => {
    // let tokenaddress = addresses[0].DividendToken;
    // console.log('handleApprove', value);

    try {
      setIsLoading(true);
      const contract = new ethers.Contract(convertingFrom.address, ERC20ABI, signer);
      const tx = await contract.approve(
        routeraddress,
        ethers.utils.parseUnits(value, convertingFrom.decimals)
      );
      await tx.wait();
      Swapping(value);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchTokenBalance = async (token_address, token_label) => {
    try {
      if (token_label === 'from') {
        if (convertingFrom.address == '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7') {
          AVAXBalanceObj.refetch(true, false)
            .then((res) => {
              // console.log('AVAX Refetch', res);
              let balance = res.data.value;

              const bigBalance = BigNumber(balance.toString());

              setBalanceFrom(bigBalance.toString());
            })
            .catch((err) => console.log('AVAX BALANCE ERROR', err));
          // let balance = data?.value;

          // const bigBalance = BigNumber(balance.toString());

          // setBalanceFrom(bigBalance.toString());
          return;
        }
        try {
          let contract = new ethers.Contract(token_address, ERC20ABI, provider);
          const balance = await contract.balanceOf(address);
          console.log(' Balance : ', balance);
          const bigBalance = BigNumber(balance.toString());
          console.log(bigBalance);
          setBalanceFrom(bigBalance);
        } catch (err) {
          console.log('Balance From Error', err);
          setBalanceFrom(0.0);
        }
      }
      if (token_label === 'to') {
        if (convertingTo.address == '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7') {
          AVAXBalanceObj.refetch(true, false)
            .then((res) => {
              // console.log('AVAX Refetch', res);
              let balance = res.data.value;

              const bigBalance = BigNumber(balance.toString());

              setBalanceTo(bigBalance.toString());
            })
            .catch((err) => console.log('AVAX BALANCE ERROR', err));

          // let balance = data?.value;
          // const bigBalance = BigNumber(balance.toString());

          // setBalanceTo(bigBalance);
          // setBalanceTo(ethers.utils.parseUnits(balance, convertingTo.decimals).toString());
          return;
        }
        try {
          let contract = new ethers.Contract(token_address, ERC20ABI, provider);
          const balance = await contract.balanceOf(address);
          let bigBalance = BigNumber(balance.toString());
          // if (!balance) {
          //   setBalanceTo(ethers.utils.formatUnits(BigNumber(0), convertingTo.decimals).toString());
          // }
          setBalanceTo(bigBalance);
        } catch (err) {
          console.log('BalanceTo Error', err);
          setBalanceTo(BigNumber(0));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function swapCurrencies() {
    setResultGotten(false);
    setConvertingFrom(convertingTo);
    setConvertingTo(convertingFrom);
    setInput(output);
    setOutput(input);

    // Swapping(input, convertingTo, convertingFrom);
  }

  function changeCurrency(label) {
    if (!isWalletConnected) {
      toast.error('Please Connect Your wallet', { autoClose: 3000 });
      return;
    }
    setResultGotten(false);
    dispatch(setLabel(label));
    dispatch(setDisplayCurrencies(true));
  }

  async function checkForPairing() {
    // let path = [convertingFrom.address, convertingTo.address];

    let address =
      (await getPairs(
        factoryAddress,
        FactoryABI,
        provider,
        convertingFrom.address,
        convertingTo.address
      )) || '0x0000000000000000000000000000000000000000';
    // console.log('output', output);

    if (address === '0x0000000000000000000000000000000000000000') {
      dispatch(
        setSwapboxAlert({
          alertName: 'pairing_alert',
          message: 'These currencies do not have pair',
        })
      );
      setIsPaired(false);
      setInput('');
      setOutput('');
      setResultGotten(false);
      return;
    } else {
      setIsPaired(true);
      // console.log('paired', isPaired);

      if (SwapboxAlert.alertName === 'pairing_alert') {
        dispatch(
          setSwapboxAlert({
            alertName: '',
            message: '',
          })
        );
      }
    }
  }

  async function Swapping(value, end = '') {
    if (isNaN(value) || value === '') {
      console.log('input isNan', value);
      setInput('');
      setOutput('');
      setDisplayLeftAprv(false);
      return;
    }

    if (!isPaired || parseFloat(value) === 0) {
      setDisplayLeftAprv(false);

      // console.log("isn't paired or zero", value);
      if (end === 'input_end' || end === '') {
        setInput(value);
        setOutput('');
      } else {
        setOutput(value);
        setInput('');
      }
      return;
    }

    checkForPairing();
    setResultGotten(false);
    setOverlaySwapBox(true);

    if (end === 'input_end' || end === '') {
      if (value.length > convertingFrom.decimals + 2) {
        setInput(value);
        setOutput(0);
        return;
      }

      let path = [convertingFrom.address, convertingTo.address];

      checkAllowance(
        convertingFrom.symbol,
        convertingFrom.address,
        convertingFrom.decimals,
        parseFloat(value.toString())
      );
      let output = await getAmountsOut(
        routeraddress,
        PangolinRouterABI,
        provider,
        path,
        ethers.utils.parseUnits(
          parseFloat(value).toFixed(convertingFrom.decimals).toString(),
          convertingFrom.decimals
        )
      );

      setOutput(ethers.utils.formatUnits(output, convertingTo.decimals));
      setInput(value);
      setOverlaySwapBox(false);
    }

    if (end === 'output_end') {
      if (value.length > convertingTo.decimals + 2) {
        setOutput(value);
        setInput(0);
        return;
      }

      let path = [convertingTo.address, convertingFrom.address];
      checkAllowance(
        convertingTo.symbol,
        convertingTo.address,
        convertingTo.decimals,
        parseFloat(value.toString())
      );
      let output = await getAmountsOut(
        routeraddress,
        PangolinRouterABI,
        provider,
        path,
        ethers.utils.parseUnits(
          parseFloat(value).toFixed(convertingTo.decimals).toString(),
          convertingTo.decimals
        )
      );

      setInput(ethers.utils.formatUnits(output, convertingFrom.decimals));
      setOutput(value);
      setOverlaySwapBox(false);
    }

    // handleApprove();
    setIsInputBalance(false);
    setResultGotten(true);
    // console.log('output gotten', ethers.utils.formatUnits(output, convertingTo.decimals));
  }

  async function TokenSwapping() {
    setIsLoading(true);
    if (!isPaired) {
      setIsLoading(false);
      return;
    }
    if (input === '0' || input === '' || output === '0') {
      dispatch(
        setSwapboxAlert({
          alertName: 'invalid_input',
          message: 'Enter a Valid Amount',
        })
      );
      setIsLoading(false);
      return;
    }
    setDisabledBody(true);
    await SwapToken(
      parseFloat(input),
      parseFloat(output),
      convertingFrom,
      convertingTo,
      address,
      signer
    );

    setIsLoading(false);
    setInput('');
    setOutput('');
    fetchTokenBalance(convertingFrom.address, 'from');
    fetchTokenBalance(convertingTo.address, 'to');
    setDisabledBody(false);
  }

  function modifyFromBalance(percent) {
    if (!isWalletConnected) {
      toast.error('Please Connect Your wallet', { autoClose: 3000 });
      return;
    }

    const balanceFormate = parseFloat(
      ethers.utils.formatUnits(balanceFrom.toString(), convertingFrom.decimals).toString()
    );
    setIsInputBalance(true);
    setInput(((balanceFormate * percent) / 100).toFixed(convertingFrom.decimals).toString());
  }

  function handleChange(changeEvent) {
    let { value, name } = changeEvent.target;

    if (!isNaN(value)) {
      switch (name) {
        case 'input_currency':
          Swapping(value, 'input_end')
            .then(() => setOverlaySwapBox(false))
            .catch(() => setOverlaySwapBox(false));
          break;
        case 'slippage':
          setSlippage(value);
          break;
        case 'output_currency':
          Swapping(value, 'output_end');
          break;
        default:
          return;
      }
    }
  }

  function fetchCoinGekoId() {
    return CoinGekoIds[convertingFrom.symbol.toLowerCase()] || '';
  }
  // console.log(ethers.utils.formatUnits(balanceFrom.toString(), convertingFrom.decimals));
  return (
    <>
      <div
        className={
          disabledBody
            ? 'fixed inset-0 z-10 block h-screen w-screen items-center justify-center bg-overlay-bg bg-zinc-900 bg-opacity-20  backdrop-blur-sm'
            : 'hidden '
        }
      >
        <div className=" flex h-full w-full items-center justify-center bg-transparent">
          <img src={ColourfulLoadingIcon} className="colorful_loading_icon block  " />
        </div>
        {/* <Toast text={`Adding Liquidity`} hash={''} /> */}
      </div>

      <div className="swap_page_cont bg_grad h-[calc(100vh-84px)] flex-1 overflow-y-scroll rounded-tl-3xl bg-light-body-bg p-0 ease-in dark:bg-dark-body-bg  sm:items-center sm:p-8">
        <div className="swap_box_cont sm:h-initial relative  h-min  dark:bg-bg-main">
          {overlaySwapBox && (
            <div className="overlay_swapbox absolute inset-0 z-10 flex h-full w-full items-center justify-center rounded-[1.2rem] bg-gray-800 bg-opacity-70 opacity-70">
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#F8B327"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                visible={true}
                wrapperStyle={{ fill: 'red', transition: '0.5s' }}
              />
            </div>
          )}
          <div className="swap_box_head flex h-max items-center justify-between">
            <div className="flex-auto text-ellipsis  font-mulish text-base tracking-wider text-white">
              <span className="">Swap</span>
            </div>

            <div className="flex h-max items-center gap-4">
              <div>
                <span className=" pl-10 font-lexend font-thin" style={{ color: '#889AB4' }}>
                  Slippage
                </span>
              </div>
              <div className="slippage_cont flex w-fit items-center justify-center rounded-full  px-1 py-1 text-sm text-white">
                <input
                  className="slippage_input w-8 bg-transparent pr-0.5 text-right font-sans "
                  placeholder="5"
                  type="text"
                  name="slippage"
                  value={slippage}
                  onChange={handleChange}
                  maxLength={2}
                />
                <span className=" inset-0  right-0 top-1 bg-transparent p-0 pt-0.5">%</span>
              </div>
            </div>
          </div>

          <div className="swap_box_from ">
            <div
              className="swap_box_from_dropdown !duration-300"
              name="currency_from"
              onClick={() => {
                changeCurrency('from');
              }}
            >
              <div className={convertingFrom && convertingFrom.logoURI ? 'currency_icon' : ''}>
                <img
                  src={convertingFrom.logoURI}
                  alt={'#' + convertingFrom.symbol}
                  loading="lazy"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = MissingImageIcon;
                  }}
                />
              </div>
              <div className="currency_name">
                <h2>{convertingFrom ? convertingFrom.symbol : ''}</h2>
              </div>
              <div className="dropdown_icon">
                <i className="bi bi-caret-down-fill"></i>
              </div>
            </div>

            <div className="swap_box_from_balance">
              <div className="percents_selectors">
                <button onClick={() => modifyFromBalance(25)}>25%</button>
                <button onClick={() => modifyFromBalance(50)}>50%</button>
                <button onClick={() => modifyFromBalance(75)}>75%</button>
                <button onClick={() => modifyFromBalance(100)}>MAX</button>
              </div>
              <div className="balance_label">
                <span>Balance : </span>
                <span
                  className="balance"
                  onMouseOver={() => {
                    if (isWalletConnected) balanceFromPopUp.current.style.opacity = '1';
                  }}
                  onMouseOut={() => (balanceFromPopUp.current.style.opacity = '0')}
                >
                  {balanceFromFormatted}
                </span>
                <div
                  ref={balanceFromPopUp}
                  className="balance_popup absolute top-1 bg-white p-2 text-black"
                >
                  <span>
                    {ethers.utils.formatUnits(balanceFrom.toString(), convertingFrom.decimals)}
                    <b>{' ' + convertingFrom.symbol}</b>
                  </span>
                </div>
              </div>
            </div>
            <div className="swap_box_from_input ">
              <div className="input_box">
                <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="text"
                    className="bg-transparent "
                    name="input_currency"
                    value={input}
                    onChange={handleChange}
                    placeholder="0"
                  />
                </form>
              </div>
              <div className="conversion_result text-xs ">
                <span className={false ? 'text-transparent' : ''}>
                  {'~' + (output || ' ')}
                  {convertingTo.symbol.toLowerCase() !== 'select' && ' ' + convertingTo.symbol}
                </span>
              </div>
            </div>
          </div>

          <div className="swap_box_swapping_btn py-1 dark:bg-bg-main">
            <img
              src={swap_icon}
              alt="SWAP"
              onClick={swapCurrencies}
              loading="lazy"
              className="cursor-pointer rounded-full bg-[rgb(107,114,128)]  drop-shadow-md duration-300 ease-linear hover:border-primary-sunny-main"
            />
          </div>

          <div className="swap_box_from swap_box_to">
            <div
              className="swap_box_to_dropdown swap_box_from_dropdown"
              name="currency_to"
              onClick={() => {
                changeCurrency('to');
              }}
            >
              <div className={convertingTo && convertingTo.logoURI ? 'currency_icon' : ''}>
                <img
                  src={convertingTo.logoURI}
                  alt={'#' + convertingTo.symbol}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = MissingImageIcon;
                  }}
                  loading="lazy"
                />
              </div>
              <div className="currency_name">
                <h2 style={{ letterSpacing: '1px' }}>{convertingTo ? convertingTo.symbol : ''}</h2>
              </div>
              <div className="dropdown_icon">
                <i className="bi bi-caret-down-fill"></i>
              </div>
            </div>
            <div className="swap_box_to_balance swap_box_from_balance">
              <div className="balance_label">
                <span>Balance : </span>
                <span
                  className="balance"
                  onMouseOver={() => {
                    if (isWalletConnected) balanceToPopUp.current.style.opacity = '1';
                  }}
                  onMouseOut={() => (balanceToPopUp.current.style.opacity = '0')}
                >
                  {balanceToFormatted}
                </span>
                <div
                  ref={balanceToPopUp}
                  className="balance_popup absolute top-1 bg-white p-2 text-black"
                >
                  <span>
                    {balanceToFormatted}
                    <b>{' ' + convertingTo.symbol}</b>
                  </span>
                </div>
              </div>
            </div>
            <div className="swap_box_to_input swap_box_from_input">
              <div className="input_box">
                <input
                  type="text"
                  name="output_currency"
                  onChange={handleChange}
                  value={output}
                  className="bg-transparent"
                  placeholder="0"
                />
              </div>
            </div>
          </div>

          <div className="swap_box_connect_wallet_btn">
            <div className="flex flex-col items-center justify-center">
              {
                displayleftAprv ? (
                  // <CustomeConnectButton
                  //   from="SwapPage"
                  //   ApproveMethod={() => handleApprove(input)}
                  //   text="Approve"
                  // />
                  <Button
                    isLoading={isLoading}
                    text={'Approve'}
                    action={() => handleApprove(input)}
                    disabled={isLoading || !address}
                  />
                ) : // <button onClick={() => handleApprove(input)}> Approve </button>
                isWalletConnected ? (
                  SwapboxAlert.alertName === '' && (
                    <Button
                      isLoading={isLoading}
                      text={'Swap'}
                      action={TokenSwapping}
                      disabled={isLoading || !address}
                    />
                  )
                ) : (
                  <CustomeConnectButton
                    from="SwapPage"
                    SwappingMethod={TokenSwapping}
                    shouldDisplay={SwapboxAlert.alertName ? false : true}
                  />
                )
                // <Button
                //   isLoading={isLoading}
                //   text={'Swap'}
                //   action={TokenSwapping}
                //   disabled={isLoading || !address}
                // />
              }
              {SwapboxAlert.alertName && (
                <div className="swap_box_alert">
                  <div className="w-full text-center">
                    <span className="text-sm">{SwapboxAlert.message}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="swap_chart_sec  ">
          <div className="swap_chart  shadow-table  dark:bg-bg-main">
            <SwapChart currency={fetchCoinGekoId()} symbol={convertingFrom.symbol} />
          </div>
        </div>
      </div>

      <CurrencySuggestionsModal open={displayCurrencies} label={label} />
    </>
  );
}
