/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import CustomeConnectButton from '../../layout/CustomeConnectButton';
import useTradeInfo from '../../../hooks/useTradeContext';
import { useAccount, useSigner } from 'wagmi';
import { getLimitOrderContract, getTokenContract } from '../../../utils/contractHelpers';
import { getLimitOrderAddress } from '../../../utils/addressHelpers';
import Button from '../../shared/Button';
import { useAllowance, useBalance } from '../../../hooks/trade/useTokenInfo';
import { ethers } from 'ethers';
import { makeOrder } from '../../../actions/Order';
import { figureError } from '../../../utils/functions';

export default function MarketPanel() {
  const actions = ['Buy', 'Sell'];
  const tradings = ['Limit', 'Market'];
  const percents = ['25%', '50%', '75%', '100%'];
  const [selectedAction, setSelectedAction] = useState('buy');
  const [selectedTrading, setSelectedTrading] = useState(0);
  const [selectedPercent, setSelectedPercent] = useState(-1);
  const [price, setPrice] = useState('');
  const [amount, setAmount] = useState('');
  const [total, setTotal] = useState('');

  const { selectedPair, pairs, pending, setPending } = useTradeInfo();
  const pair = pairs[selectedPair];

  const token0 = selectedAction === 'buy' ? pair.token1 : pair.token0;
  const token1 = selectedAction === 'buy' ? pair.token0 : pair.token1;

  const balance0 = useBalance(pair.token0);
  const balance1 = useBalance(pair.token1);
  const balance = selectedAction === 'buy' ? balance1 : balance0;
  const allowance = useAllowance(token0.address, getLimitOrderAddress());

  const { data: signer } = useSigner();
  const { address: account } = useAccount();

  async function onApprove(address) {
    setPending(true);
    const tokenContract = getTokenContract(address, signer);
    try {
      const tx = await tokenContract.approve(
        getLimitOrderAddress(),
        '115792089237316195423570985008687907853269984665640564039457584007913129639935'
      );
      await tx.wait();
      toast.success('Token approved successfully');
    } catch (error) {
      console.log(error);
      figureError(error);
    }
    setPending(false);
  }

  async function onLimitOrder() {
    setPending(true);
    const limitorderContract = getLimitOrderContract(signer);

    try {
      const amountGive = (
        selectedPercent === 3
          ? balance
          : ethers.utils.parseUnits(
              (selectedAction === 'buy' ? total : amount).toString(),
              token0.decimals
            )
      ).toString();
      const amountGet = ethers.utils
        .parseUnits((selectedAction === 'buy' ? amount : total).toString(), token1.decimals)
        .toString();

      const tx = await limitorderContract.makeOrder(
        token0.isNative ? ethers.constants.AddressZero : token0.address,
        token1.isNative ? ethers.constants.AddressZero : token1.address,
        amountGive,
        amountGet,
        ethers.constants.AddressZero,
        0,
        0,
        5,
        [],
        {
          value: token0.isNative ? amountGive : 0,
        }
      );

      const result = await tx.wait();
      const event = result.events.find((data) => data.event === 'PlaceOrder');
      makeOrder(
        account,
        { ...token0, amount: amountGive },
        { ...token1, amount: amountGet },
        price,
        event.args.index / 1,
        event.args.nonce / 1,
        selectedAction
      );
      toast.success('Transaction confirmed successfully');
    } catch (error) {
      console.log(error);
      figureError(error);
    }
    setPending(false);
  }

  useEffect(() => {
    if (selectedPercent >= 0) {
      const percent = ((selectedPercent + 1) * 25) / 100;
      if (selectedAction === 'sell') {
        const _amount = percent * (balance0 / Math.pow(10, pair.token0.decimals));
        setAmount(_amount.toFixed(6));
      } else {
        const _amount = percent * (balance1 / Math.pow(10, pair.token1.decimals));
        setTotal(_amount.toFixed(6));
        if (price) {
          setAmount(_amount / price);
        } else if (amount) {
          setPrice(_amount / amount);
        }
      }
    }
  }, [selectedPercent, price, balance0, balance1]);

  useEffect(() => {
    setTotal((price * amount).toFixed(6));
  }, [price, amount]);

  function isNumber(e) {
    const char = e.target.value[e.target.value.length - 1];
    if ((char >= '0' && char <= '9') || char === '.' || !char) return true;
    return false;
  }
  return (
    <StyledPanel className="h-full w-full 2xl:w-[320px]">
      <FilterPanel
        className="flex min-h-[32px] text-xs font-semibold text-[#a7acb9]"
        active={actions.map((data) => data.toLowerCase()).indexOf(selectedAction) + 1}
      >
        {actions.map((data, i) => {
          return (
            <div
              key={i}
              className="flex flex-1 cursor-pointer items-center justify-center transition hover:text-[#CA8D31]"
              onClick={() => setSelectedAction(data.toLowerCase())}
            >
              {data}
            </div>
          );
        })}
      </FilterPanel>

      <FilterPanel
        className="mx-auto mt-2 flex h-5 w-[120px] text-xs font-semibold text-[#a7acb9]"
        active={selectedTrading + 1}
      >
        {tradings.map((data, i) => {
          return (
            <div
              key={i}
              className="flex flex-1 cursor-pointer items-center justify-center rounded transition hover:text-[#CA8D31]"
              onClick={() => setSelectedTrading(i)}
            >
              {data}
            </div>
          );
        })}
      </FilterPanel>
      <div className="mx-3 mt-3 text-xs">
        <div>
          {token0.symbol}:{' '}
          {balance ? (balance / Math.pow(10, token0.decimals)).toFixed(4) : '0.0000'}
        </div>
        <div className="my-1.5 flex h-[30px] overflow-hidden rounded bg-[#131313]">
          <input
            type="text"
            className="h-full flex-1 border-r-4 border-[#1b1b1b] bg-transparent px-2"
            placeholder="Price"
            onChange={(e) => {
              if (isNumber(e)) setPrice(e.target.value);
            }}
            value={price}
          />
          <div className="flex h-full w-12 cursor-pointer items-center justify-center rounded  text-[#808080]">
            {pair.token1.symbol}
          </div>
        </div>
        <div className="my-1.5 flex h-[30px] overflow-hidden rounded bg-[#131313]">
          <input
            type="text"
            className="h-full flex-1 border-r-4 border-[#1b1b1b] bg-transparent px-2"
            placeholder="Amount"
            onChange={(e) => {
              if (isNumber(e)) setAmount(e.target.value);
              setSelectedPercent(-1);
            }}
            value={amount}
          />
          <div className="flex h-full w-12 cursor-pointer items-center justify-center rounded  text-[#808080]">
            {pair.token0.symbol}
          </div>
        </div>
        <FilterPanel
          className="my-3 flex h-5 w-full rounded border border-[#CA8D31] text-xs font-semibold"
          active={selectedPercent + 1}
        >
          {percents.map((data, i) => {
            return (
              <div
                key={i}
                className="flex flex-1 cursor-pointer items-center justify-center border-r border-[#CA8D31] transition hover:text-[#CA8D31]"
                onClick={() => {
                  setSelectedPercent(i);
                }}
              >
                {data}
              </div>
            );
          })}
        </FilterPanel>
        <div className="mb-3 flex h-[30px] overflow-hidden rounded bg-[#131313]">
          <input
            type="text"
            className="h-full flex-1 border-r-4 border-[#1b1b1b] bg-transparent px-2"
            placeholder="Total"
            onChange={(e) => {
              if (isNumber(e)) setTotal(e.target.value);
            }}
            value={total}
          />
          <div className="flex h-full w-12 cursor-pointer items-center justify-center rounded  text-[#808080]">
            {pair.token1.symbol}
          </div>
        </div>
        <div className="font-medim my-4 text-sm text-white">
          Fees (0.25%) :{' '}
          {(selectedAction === 'buy' ? (total * 0.25) / 100 : (amount * 0.25) / 100).toFixed(2)}{' '}
          {token0.symbol}
        </div>
      </div>
      <div className="mx-6 flex justify-center">
        {!account ? (
          <div className="connect_wallet_btn_background  w-max self-center">
            <CustomeConnectButton shouldDisplay={true} />
          </div>
        ) : allowance / 1 <=
            ethers.utils.parseUnits(total ? total.toString() : '0', pair.token1.decimals) &&
          !token0.isNative ? (
          <Button
            text={'Approve'}
            action={() => onApprove(token0.address)}
            disabled={pending}
            isLoading={pending}
          />
        ) : (
          <Button
            text={'Confirm'}
            action={() => onLimitOrder()}
            disabled={pending || !total || !Number(total)}
            isLoading={pending}
          />
        )}
      </div>
    </StyledPanel>
  );
}

const FilterPanel = styled.div`
  > div:nth-child(${({ active }) => active}) {
    background: #ca8d31;
    color: white;
  }
`;

const StyledPanel = styled.div`
  background: #1b1b1b;
  border-radius: 6px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    display: block !important;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb:vertical {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #eebb19;
  }
`;
