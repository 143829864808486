export default {
  name: 'Pangolin Tokenlist',
  logoURI:
    'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x60781C2586D68229fde47564546784ab3fACA982/logo_48.png',
  keywords: [],
  version: {
    major: 8,
    minor: 5,
    patch: 0,
  },
  timestamp: '2023-02-30T12:00:00+00:00',
  tokens: [
    {
      chainId: 9001,
      address: '0xFA3C22C069B9556A4B2f7EcE1Ee3B467909f4864',
      decimals: 6,
      name: 'Osmosis',
      symbol: 'Osmo',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/9001/0xFA3C22C069B9556A4B2f7EcE1Ee3B467909f4864/logo_24.png',
    },
    {
      chainId: 9001,
      address: '0x2C68D1d6aB986Ff4640b51e1F14C716a076E44C4',
      decimals: 18,
      name: 'Stride Staked Evmos',
      symbol: 'stEVMOS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/9001/0x2C68D1d6aB986Ff4640b51e1F14C716a076E44C4/logo_24.png',
    },
    {
      chainId: 9001,
      address: '0xD4949664cD82660AaE99bEdc034a0deA8A0bd517',
      decimals: 18,
      name: 'Wrapped Evmos',
      symbol: 'WEVMOS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/9001/0xD4949664cD82660AaE99bEdc034a0deA8A0bd517/logo_24.png',
    },
    {
      chainId: 9001,
      address: '0xC5e00D3b04563950941f7137B5AfA3a534F0D6d6',
      decimals: 6,
      name: 'Cosmos Hub',
      symbol: 'ATOM',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/9001/0xC5e00D3b04563950941f7137B5AfA3a534F0D6d6/logo_24.png',
    },
    {
      chainId: 9001,
      address: '0x15C3Eb3B621d1Bff62CbA1c9536B7c1AE9149b57',
      decimals: 6,
      name: 'Axelar USDC',
      symbol: 'axlUSDC',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/9001/0x15C3Eb3B621d1Bff62CbA1c9536B7c1AE9149b57/logo_24.png',
    },
    {
      chainId: 295,
      address: '0x00000000000000000000000000000000000cbA44',
      decimals: 8,
      name: 'HBARX',
      symbol: 'HBARX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295/0x00000000000000000000000000000000000cbA44/logo_24.png',
    },
    {
      chainId: 295,
      address: '0x00000000000000000000000000000000001a88b2',
      decimals: 8,
      name: 'Pangolin Hedera',
      symbol: 'PBAR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295/0x00000000000000000000000000000000001a88b2/logo_24.png',
    },
    {
      chainId: 295,
      address: '0x00000000000000000000000000000000001A8837',
      decimals: 8,
      name: 'Wrapped HBAR',
      symbol: 'WHBAR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295/0x00000000000000000000000000000000001A8837/logo_24.png',
    },
    {
      chainId: 295,
      address: '0x0000000000000000000000000000000000101Ae3',
      decimals: 6,
      name: 'USD Coin',
      symbol: 'USDC.hts',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295/0x0000000000000000000000000000000000101Ae3/logo_24.png',
    },
    {
      chainId: 295,
      address: '0x0000000000000000000000000000000000101aF0',
      decimals: 6,
      name: 'Tether USD',
      symbol: 'USDT.hts',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295/0x0000000000000000000000000000000000101aF0/logo_24.png',
    },
    {
      chainId: 295,
      address: '0x00000000000000000000000000000000000D1ea6',
      decimals: 6,
      name: 'Calaxy',
      symbol: 'CLXY',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295/0x00000000000000000000000000000000000D1ea6/logo_24.png',
    },
    {
      chainId: 295,
      address: '0x000000000000000000000000000000000011afa2',
      decimals: 8,
      name: 'GRELF',
      symbol: 'GRELF',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295/0x000000000000000000000000000000000011afa2/logo_24.png',
    },
    {
      chainId: 295,
      address: '0x00000000000000000000000000000000000Ec585',
      decimals: 8,
      name: 'HeadStarter',
      symbol: 'HST',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295/0x00000000000000000000000000000000000Ec585/logo_24.png',
    },
    {
      chainId: 9000,
      address: '0x171a6A80cdDDd8f69134ff0471523400f2C16ABe',
      decimals: 18,
      name: 'Pangolin Evmos',
      symbol: 'evmPNG',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/9000/0x171a6A80cdDDd8f69134ff0471523400f2C16ABe/logo_24.png',
    },
    {
      chainId: 114,
      address: '0x6169CD307Be7E24152dF23a7A945A1ea3eC7b438',
      decimals: 18,
      name: 'Pangolin Coston2',
      symbol: 'PCT2',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/114/0x6169CD307Be7E24152dF23a7A945A1ea3eC7b438/logo_24.png',
    },
    {
      chainId: 296,
      address: '0x000000000000000000000000000000000000073D',
      decimals: 8,
      name: 'Wrapped Hedera',
      symbol: 'WHBAR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/296/0x000000000000000000000000000000000000073D/logo_24.png',
    },
    {
      chainId: 296,
      address: '0x0000000000000000000000000000000002D9E4F8',
      decimals: 6,
      name: 'USDC',
      symbol: 'USDC',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/296/0x0000000000000000000000000000000002D9E4F8/logo_24.png',
    },
    {
      chainId: 296,
      address: '0x0000000000000000000000000000000000000744',
      decimals: 8,
      name: 'Pangolin Hedera',
      symbol: 'PBAR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/296/0x0000000000000000000000000000000000000744/logo_24.png',
    },
    {
      chainId: 296,
      address: '0x0000000000000000000000000000000002bE71e8',
      decimals: 6,
      name: 'Sauce',
      symbol: 'SAUCE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/296/0x0000000000000000000000000000000002bE71e8/logo_24.png',
    },
    {
      chainId: 16,
      address: '0x0A8744c2a48b0e09a3e4C3A381A8E0d8d900bAFe',
      decimals: 18,
      name: 'Pangolin',
      symbol: 'PCT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/16/0x0A8744c2a48b0e09a3e4C3A381A8E0d8d900bAFe/logo_24.png',
    },
    {
      chainId: 11111,
      address: '0xbdf33c7128fBB220fc0e4Be277697cEeef8BdfF5',
      decimals: 18,
      name: 'Pangolin',
      symbol: 'wagmiPNG',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0xbdf33c7128fBB220fc0e4Be277697cEeef8BdfF5/logo_24.png',
    },
    {
      chainId: 11111,
      address: '0x3Ee7094DADda15810F191DD6AcF7E4FFa37571e4',
      decimals: 18,
      name: 'Wrapped WAGMI',
      symbol: 'wWAGMI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0x3Ee7094DADda15810F191DD6AcF7E4FFa37571e4/logo_24.png',
    },
    {
      chainId: 11111,
      address: '0xf1db872E6454D553686b088c1Ea3889cF2FE3ABe',
      decimals: 18,
      name: 'OG',
      symbol: 'OG',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0xf1db872E6454D553686b088c1Ea3889cF2FE3ABe/logo_24.png',
    },
    {
      chainId: 11111,
      address: '0x4eaA03A9C9c9CE745517538d262653B9e43c51f2',
      decimals: 18,
      name: 'staySAFU',
      symbol: 'staySAFU',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0x4eaA03A9C9c9CE745517538d262653B9e43c51f2/logo_24.png',
    },
    {
      chainId: 19,
      address: '0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED',
      decimals: 18,
      name: 'Wrapped Songbird',
      symbol: 'WSGB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED/logo_24.png',
    },
    {
      chainId: 19,
      address: '0xb2987753D1561570f726Aa373F48E77e27aa5FF4',
      decimals: 18,
      name: 'Pangolin Songbird',
      symbol: 'PSB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xb2987753D1561570f726Aa373F48E77e27aa5FF4/logo_24.png',
    },
    {
      chainId: 19,
      address: '0x697bb3B5E1eCf4fEbE6016321b0648d3d6C270B6',
      decimals: 18,
      name: 'sDOOD Token',
      symbol: 'sDOOD',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x697bb3B5E1eCf4fEbE6016321b0648d3d6C270B6/logo_24.png',
    },
    {
      chainId: 19,
      address: '0x8d32E20d119d936998575B4AAff66B9999011D27',
      decimals: 18,
      name: 'CanaryX',
      symbol: 'CNYX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x8d32E20d119d936998575B4AAff66B9999011D27/logo_24.png',
    },
    {
      chainId: 19,
      address: '0xD7565b16b65376e2Ddb6c71E7971c7185A7Ff3Ff',
      decimals: 18,
      name: 'OracleSwap',
      symbol: 'ORACLE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xD7565b16b65376e2Ddb6c71E7971c7185A7Ff3Ff/logo_24.png',
    },
    {
      chainId: 19,
      address: '0xC348F894d0E939FE72c467156E6d7DcbD6f16e21',
      decimals: 18,
      name: 'Experimental Finance Token',
      symbol: 'EXFI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xC348F894d0E939FE72c467156E6d7DcbD6f16e21/logo_24.png',
    },
    {
      chainId: 19,
      address: '0x9E2E6c16803878C18E54Ed74F05AeafCCe464626',
      decimals: 18,
      name: 'sFortuna',
      symbol: 'SFORT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x9E2E6c16803878C18E54Ed74F05AeafCCe464626/logo_24.png',
    },
    {
      chainId: 19,
      address: '0x9dC8639bff70B019088f0b7D960561654269B5BE',
      decimals: 18,
      name: 'Honey Stick',
      symbol: 'HS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x9dC8639bff70B019088f0b7D960561654269B5BE/logo_24.png',
    },
    {
      chainId: 19,
      address: '0xe4671844Fcb3cA9A80A1224B6f9A0A6c2Ba2a7d5',
      decimals: 18,
      name: 'Cootie Coin',
      symbol: 'COOT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xe4671844Fcb3cA9A80A1224B6f9A0A6c2Ba2a7d5/logo_24.png',
    },
    {
      chainId: 19,
      address: '0x671ee7ac6ef2803776dD1711e577540A6007cC25',
      decimals: 18,
      name: 'Ocean',
      symbol: 'OCN',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x671ee7ac6ef2803776dD1711e577540A6007cC25/logo_24.png',
    },
    {
      chainId: 19,
      address: '0xd46bd7A5EBE52199BD6f0EfD59a1d7CeF7f35eae',
      decimals: 18,
      name: 'Infinty Token',
      symbol: 'INFT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xd46bd7A5EBE52199BD6f0EfD59a1d7CeF7f35eae/logo_24.png',
    },
    {
      chainId: 19,
      address: '0xb1D60fA6FABd9A2Ad89960d070350552E47fb0D6',
      decimals: 18,
      name: 'LoveTokenV2',
      symbol: 'CARE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xb1D60fA6FABd9A2Ad89960d070350552E47fb0D6/logo_24.png',
    },
    {
      chainId: 19,
      address: '0x61b64c643fCCd6ff34Fc58C8ddff4579A89E2723',
      decimals: 18,
      name: 'Astral Credits',
      symbol: 'XAC',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/assets/19/0x61b64c643fCCd6ff34Fc58C8ddff4579A89E2723/logo_24.png',
    },
    {
      chainId: 14,
      address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
      decimals: 18,
      name: 'Wrapped Flare',
      symbol: 'WFLR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/14/0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d/logo_24.png',
    },
    {
      chainId: 14,
      address: '0xB5010D5Eb31AA8776b52C7394B76D6d627501C73',
      decimals: 18,
      name: 'Pangolin Flare',
      symbol: 'PFL',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/14/0xB5010D5Eb31AA8776b52C7394B76D6d627501C73/logo_24.png',
    },
    {
      chainId: 19,
      address: '0xfd2a0fD402828fDB86F9a9D5a760242AD7526cC0',
      decimals: 18,
      name: 'SparkS',
      symbol: 'SPRK',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xfd2a0fD402828fDB86F9a9D5a760242AD7526cC0/logo_24.png',
    },
    {
      chainId: 11111,
      address: '0x21cf0eB2E3Ab483a67C900b27dA8F34185991982',
      decimals: 18,
      name: 'Wrapped AVAX',
      symbol: 'WAVAX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0x21cf0eB2E3Ab483a67C900b27dA8F34185991982/logo_24.png',
    },
    {
      chainId: 43113,
      address: '0x2058ec2791dD28b6f67DB836ddf87534F4Bbdf22',
      name: 'The Fuji stablecoin',
      symbol: 'FUJISTABLE',
      decimals: 6,
      logoURI: 'https://i.imgur.com/LPZDXoT.png',
    },
    {
      chainId: 43113,
      address: '0x97132C109c6816525F7f338DCb7435E1412A7668',
      name: 'To the Moon',
      symbol: 'FUJIMOON',
      decimals: 9,
      logoURI: 'https://i.imgur.com/jNTqyQA.png',
    },
    {
      chainId: 43113,
      address: '0xF561800EdedF4CE146ED427Cacf1Fb8aB3B2D452',
      name: 'Wrapped WAGMI',
      symbol: 'WWAGMI',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43113/0xF561800EdedF4CE146ED427Cacf1Fb8aB3B2D452/logo_24.png',
    },
    {
      chainId: 43113,
      address: '0x224695Ba2a98E4a096a519B503336E06D9116E48',
      name: 'The Crypt',
      symbol: 'RIP',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/antiyro/WagmiTokenList/master/wagmi.png',
    },
    {
      chainId: 43113,
      address: '0xAF5D473b3f8F96A5B21c6bbB97e09b491335acb9',
      name: 'Fuji PNG',
      symbol: 'fujiPNG',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43113/0xAF5D473b3f8F96A5B21c6bbB97e09b491335acb9/logo_24.png',
    },
    {
      chainId: 43113,
      address: '0x0a8c21858aC24e1305BaFBDdf4DfB73a2CC9ddBC',
      name: 'JAWZ Fuji TestNet',
      symbol: 'JAWZ',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43113/0x0a8c21858aC24e1305BaFBDdf4DfB73a2CC9ddBC/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x60781C2586D68229fde47564546784ab3fACA982',
      decimals: 18,
      name: 'Pangolin',
      symbol: 'PNG',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x60781C2586D68229fde47564546784ab3fACA982/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      decimals: 18,
      name: 'Wrapped AVAX',
      symbol: 'WAVAX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x78ea17559B3D2CF85a7F9C2C704eda119Db5E6dE',
      decimals: 18,
      name: 'Avaware',
      symbol: 'AVE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x78ea17559B3D2CF85a7F9C2C704eda119Db5E6dE/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xdb333724fAE72b4253FC3d44c8270CBBC86d147b',
      decimals: 18,
      name: 'Cabbage.Cash',
      symbol: 'CABAG',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xdb333724fAE72b4253FC3d44c8270CBBC86d147b/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x488F73cddDA1DE3664775fFd91623637383D6404',
      decimals: 18,
      name: 'YetiSwap',
      symbol: 'YTS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x488F73cddDA1DE3664775fFd91623637383D6404/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xC38f41A296A4493Ff429F1238e030924A1542e50',
      decimals: 18,
      name: 'Snowball',
      symbol: 'SNOB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xC38f41A296A4493Ff429F1238e030924A1542e50/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x6e7f5C0b9f4432716bDd0a77a3601291b9D9e985',
      decimals: 9,
      name: 'Spore',
      symbol: 'SPORE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x6e7f5C0b9f4432716bDd0a77a3601291b9D9e985/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
      decimals: 18,
      name: 'PenguinToken',
      symbol: 'PEFI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50',
      decimals: 8,
      name: 'Blocknet',
      symbol: 'aaBLOCK',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084',
      decimals: 18,
      name: 'LydiaFinance Token',
      symbol: 'LYD',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
      decimals: 18,
      name: 'VersoToken',
      symbol: 'VSO',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x1ECd47FF4d9598f89721A2866BFEb99505a413Ed',
      decimals: 18,
      name: 'AVME',
      symbol: 'AVME',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x1ECd47FF4d9598f89721A2866BFEb99505a413Ed/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x65378b697853568dA9ff8EaB60C13E1Ee9f4a654',
      decimals: 18,
      name: 'Husky',
      symbol: 'HUSKY',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x65378b697853568dA9ff8EaB60C13E1Ee9f4a654/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xD606199557c8Ab6F4Cc70bD03FaCc96ca576f142',
      decimals: 18,
      name: 'Gondola',
      symbol: 'GDL',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xD606199557c8Ab6F4Cc70bD03FaCc96ca576f142/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x81440C939f2C1E34fc7048E518a637205A632a74',
      decimals: 18,
      name: 'Cycle Token',
      symbol: 'CYCLE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x81440C939f2C1E34fc7048E518a637205A632a74/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4',
      decimals: 18,
      name: 'Avalaunch',
      symbol: 'XAVA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x8D88e48465F30Acfb8daC0b3E35c9D6D7d36abaf',
      decimals: 18,
      name: 'Canary',
      symbol: 'CNR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x8D88e48465F30Acfb8daC0b3E35c9D6D7d36abaf/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xa5E59761eBD4436fa4d20E1A27cBa29FB2471Fc6',
      decimals: 18,
      name: 'Sherpa',
      symbol: 'SHERPA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xa5E59761eBD4436fa4d20E1A27cBa29FB2471Fc6/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17',
      decimals: 18,
      name: 'Dypius',
      symbol: 'DYP',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x264c1383EA520f73dd837F915ef3a732e204a493',
      decimals: 18,
      name: 'Binance',
      symbol: 'BNB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x264c1383EA520f73dd837F915ef3a732e204a493/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xB1466d4cf0DCfC0bCdDcf3500F473cdACb88b56D',
      decimals: 18,
      name: 'Weble Ecosystem Token',
      symbol: 'WET',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB1466d4cf0DCfC0bCdDcf3500F473cdACb88b56D/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7',
      decimals: 18,
      name: 'Yak Token',
      symbol: 'YAK',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
      decimals: 18,
      name: 'BENQI',
      symbol: 'QI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x9E037dE681CaFA6E661e6108eD9c2bd1AA567Ecd',
      decimals: 18,
      name: 'Wrapped AllianceBlock Token',
      symbol: 'WALBT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9E037dE681CaFA6E661e6108eD9c2bd1AA567Ecd/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x21c5402C3B7d40C89Cc472C9dF5dD7E51BbAb1b1',
      decimals: 18,
      name: 'TundraToken',
      symbol: 'TUNDRA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x21c5402C3B7d40C89Cc472C9dF5dD7E51BbAb1b1/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8',
      decimals: 18,
      name: 'Gaj Finance',
      symbol: 'GAJ',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC',
      decimals: 18,
      name: 'TEDDY',
      symbol: 'TEDDY',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
      decimals: 18,
      name: 'JoeToken',
      symbol: 'JOE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0',
      decimals: 18,
      name: 'MarginSwap',
      symbol: 'MFI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x440aBbf18c54b2782A4917b80a1746d3A2c2Cce1',
      decimals: 18,
      name: 'SHIBAVAX',
      symbol: 'SHIBX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x440aBbf18c54b2782A4917b80a1746d3A2c2Cce1/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
      decimals: 18,
      name: 'Frax Share',
      symbol: 'FXS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xc12e249FaBe1c5Eb7C558E5F50D187687a244E31',
      decimals: 18,
      name: 'Blue Token',
      symbol: 'BLUE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xc12e249FaBe1c5Eb7C558E5F50D187687a244E31/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x01C2086faCFD7aA38f69A6Bd8C91BEF3BB5adFCa',
      decimals: 18,
      name: 'YAY Games',
      symbol: 'YAY',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x01C2086faCFD7aA38f69A6Bd8C91BEF3BB5adFCa/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x397bBd6A0E41bdF4C3F971731E180Db8Ad06eBc1',
      decimals: 6,
      name: 'Avaxtars Token',
      symbol: 'AVXT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x397bBd6A0E41bdF4C3F971731E180Db8Ad06eBc1/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347',
      decimals: 8,
      name: 'TOK',
      symbol: 'TOK',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xb54f16fB19478766A268F172C9480f8da1a7c9C3',
      decimals: 9,
      name: 'Time',
      symbol: 'TIME',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb54f16fB19478766A268F172C9480f8da1a7c9C3/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x90842eb834cFD2A1DB0b1512B254a18E4D396215',
      decimals: 9,
      name: 'Good Bridging',
      symbol: 'GB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x90842eb834cFD2A1DB0b1512B254a18E4D396215/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x0ebd9537A25f56713E34c45b38F421A1e7191469',
      decimals: 18,
      name: 'OpenOcean',
      symbol: 'OOE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x0ebd9537A25f56713E34c45b38F421A1e7191469/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x3709E8615E02C15B096f8a9B460ccb8cA8194e86',
      decimals: 18,
      name: 'Vee',
      symbol: 'VEE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x3709E8615E02C15B096f8a9B460ccb8cA8194e86/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x938FE3788222A74924E062120E7BFac829c719Fb',
      decimals: 18,
      name: 'Ape In',
      symbol: 'APEIN',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x938FE3788222A74924E062120E7BFac829c719Fb/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xfC108f21931576a21D0b4b301935DAc80d9E5086',
      decimals: 18,
      name: 'Iron Finance ICE Token',
      symbol: 'IronICE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xfC108f21931576a21D0b4b301935DAc80d9E5086/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xA384Bc7Cdc0A93e686da9E7B8C0807cD040F4E0b',
      decimals: 18,
      name: 'WOWSwap',
      symbol: 'WOW',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xA384Bc7Cdc0A93e686da9E7B8C0807cD040F4E0b/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
      decimals: 18,
      name: 'Spell Token',
      symbol: 'SPELL',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xCE1bFFBD5374Dac86a2893119683F4911a2F7814/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xb27c8941a7Df8958A1778c0259f76D1F8B711C35',
      decimals: 18,
      name: 'Kalao Token',
      symbol: 'KLO',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb27c8941a7Df8958A1778c0259f76D1F8B711C35/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x027dbcA046ca156De9622cD1e2D907d375e53aa7',
      decimals: 9,
      name: 'Ampleforth',
      symbol: 'AMPL',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x027dbcA046ca156De9622cD1e2D907d375e53aa7/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x8B1d98A91F853218ddbb066F20b8c63E782e2430',
      decimals: 18,
      name: 'OrcaDAO',
      symbol: 'ORCA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x8B1d98A91F853218ddbb066F20b8c63E782e2430/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x544c42fBB96B39B21DF61cf322b5EDC285EE7429',
      decimals: 18,
      name: 'INSUR Token',
      symbol: 'INSUR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x544c42fBB96B39B21DF61cf322b5EDC285EE7429/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xc3344870d52688874b06d844E0C36cc39FC727F6',
      decimals: 18,
      name: 'Ankr Staked Avax',
      symbol: 'ankrAVAX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xc3344870d52688874b06d844E0C36cc39FC727F6/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xB00F1ad977a949a3CCc389Ca1D1282A2946963b0',
      decimals: 18,
      name: 'Boo Finance Token',
      symbol: 'BOOFI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB00F1ad977a949a3CCc389Ca1D1282A2946963b0/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
      decimals: 18,
      name: 'Hurricane Token',
      symbol: 'HCT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x45C13620B55C35A5f539d26E88247011Eb10fDbd/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x214DB107654fF987AD859F34125307783fC8e387',
      decimals: 18,
      name: 'Frax Share',
      symbol: 'FXS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x214DB107654fF987AD859F34125307783fC8e387/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xb2a85C5ECea99187A977aC34303b80AcbDdFa208',
      decimals: 18,
      name: 'ROCO',
      symbol: 'ROCO',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb2a85C5ECea99187A977aC34303b80AcbDdFa208/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xeA6887e4a9CdA1B77E70129E5Fba830CdB5cdDef',
      decimals: 18,
      name: 'IMX',
      symbol: 'IMX.a',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xeA6887e4a9CdA1B77E70129E5Fba830CdB5cdDef/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x340fE1D898ECCAad394e2ba0fC1F93d27c7b717A',
      decimals: 18,
      name: 'Orbs',
      symbol: 'ORBS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x340fE1D898ECCAad394e2ba0fC1F93d27c7b717A/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xA32608e873F9DdEF944B24798db69d80Bbb4d1ed',
      decimals: 18,
      name: 'CRA',
      symbol: 'CRA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xA32608e873F9DdEF944B24798db69d80Bbb4d1ed/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x885d748C00A279B67A7749EC6b03301700dd0455',
      decimals: 18,
      name: 'Maximus',
      symbol: 'MAXI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x885d748C00A279B67A7749EC6b03301700dd0455/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x7C08413cbf02202a1c13643dB173f2694e0F73f0',
      decimals: 9,
      name: 'Maximizer',
      symbol: 'MAXI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7C08413cbf02202a1c13643dB173f2694e0F73f0/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x4f60a160D8C2DDdaAfe16FCC57566dB84D674BD6',
      decimals: 18,
      name: 'Jewels',
      symbol: 'JEWEL',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4f60a160D8C2DDdaAfe16FCC57566dB84D674BD6/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x8aE8be25C23833e0A01Aa200403e826F611f9CD2',
      decimals: 18,
      name: 'CRAFT',
      symbol: 'CRAFT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x8aE8be25C23833e0A01Aa200403e826F611f9CD2/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x321E7092a180BB43555132ec53AaA65a5bF84251',
      decimals: 18,
      name: 'Governance OHM',
      symbol: 'gOHM',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x321E7092a180BB43555132ec53AaA65a5bF84251/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x637afeff75ca669fF92e4570B14D6399A658902f',
      decimals: 18,
      name: 'Poly-Peg COOK',
      symbol: 'COOK',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x637afeff75ca669fF92e4570B14D6399A658902f/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x9E3Ca00f2d4A9E5d4f0add0900de5f15050812cF',
      decimals: 18,
      name: 'NFTrade Token',
      symbol: 'NFTD',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9E3Ca00f2d4A9E5d4f0add0900de5f15050812cF/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xec3492a2508DDf4FDc0cD76F31f340b30d1793e6',
      decimals: 18,
      name: 'Colony Token',
      symbol: 'CLY',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xec3492a2508DDf4FDc0cD76F31f340b30d1793e6/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xf69c2fcd9128d49DfA22348C69177f9380438eB8',
      decimals: 6,
      name: 'NFTSoccerGames',
      symbol: 'NFSG',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xf69c2fcd9128d49DfA22348C69177f9380438eB8/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x483416eB3aFA601B9C6385f63CeC0C82B6aBf1fb',
      decimals: 18,
      name: 'Skill Token',
      symbol: 'SKILL',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x483416eB3aFA601B9C6385f63CeC0C82B6aBf1fb/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xBD100d061E120b2c67A24453CF6368E63f1Be056',
      decimals: 18,
      name: 'iDeFiYieldProtocol',
      symbol: 'iDYP',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xBD100d061E120b2c67A24453CF6368E63f1Be056/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x22d4002028f537599bE9f666d1c4Fa138522f9c8',
      decimals: 18,
      name: 'Platypus',
      symbol: 'PTP',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x22d4002028f537599bE9f666d1c4Fa138522f9c8/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xcA220F1e486A8e35d6F1DCD62073Ad8DD04659ED',
      decimals: 6,
      name: 'BiAltin',
      symbol: 'GLDB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xcA220F1e486A8e35d6F1DCD62073Ad8DD04659ED/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x7086e045b78E1e72F741F25231c08d238812CF8a',
      decimals: 18,
      name: 'RaceX',
      symbol: 'RACEX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7086e045b78E1e72F741F25231c08d238812CF8a/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1',
      decimals: 18,
      name: 'Tomb',
      symbol: 'TOMB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xbca7f1998Dc9FFB70b086543a808960a460aBcA7',
      decimals: 18,
      name: 'Kitty',
      symbol: 'KITTY',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xbca7f1998Dc9FFB70b086543a808960a460aBcA7/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09',
      decimals: 18,
      name: 'MilkyWay Token by SpaceSwap v2',
      symbol: 'MILK2',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xC1d02E488a9Ce2481BFdcd797d5373Dd2E70a9C2',
      decimals: 18,
      name: 'SHAKE token by SpaceSwap v2',
      symbol: 'SHAKE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xC1d02E488a9Ce2481BFdcd797d5373Dd2E70a9C2/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D',
      decimals: 18,
      name: 'Defrost Finance Token',
      symbol: 'MELT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xe0Ce60AF0850bF54072635e66E79Df17082A1109',
      decimals: 18,
      name: 'IceToken',
      symbol: 'ICE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xe0Ce60AF0850bF54072635e66E79Df17082A1109/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xf693248F96Fe03422FEa95aC0aFbBBc4a8FdD172',
      decimals: 18,
      name: 'Treasure Under Sea',
      symbol: 'TUS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xf693248F96Fe03422FEa95aC0aFbBBc4a8FdD172/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6',
      decimals: 18,
      name: 'Imperium Empires Token',
      symbol: 'IME',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xB15f02F9Da8CD1f99E9dd375F21dc96D25ddd82C',
      decimals: 18,
      name: 'HERMES',
      symbol: 'HERMES',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB15f02F9Da8CD1f99E9dd375F21dc96D25ddd82C/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xfa4B6db72A650601E7Bd50a0A9f537c9E98311B2',
      decimals: 18,
      name: 'HERMES Shares',
      symbol: 'HSHARES',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xfa4B6db72A650601E7Bd50a0A9f537c9E98311B2/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x1DB749847C4abB991d8B6032102383e6BfD9B1c7',
      decimals: 18,
      name: 'Dogeon Token',
      symbol: 'DON',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x1DB749847C4abB991d8B6032102383e6BfD9B1c7/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x3EeFb18003D033661f84e48360eBeCD181A84709',
      decimals: 18,
      name: 'Islander',
      symbol: 'ISA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x3EeFb18003D033661f84e48360eBeCD181A84709/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x7f041ce89A2079873693207653b24C15B5e6A293',
      decimals: 18,
      name: 'LOOT',
      symbol: 'LOOT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7f041ce89A2079873693207653b24C15B5e6A293/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xE6B9d092223f39013656702A40dbE6B7DeCc5746',
      decimals: 18,
      name: 'ANGLE',
      symbol: 'ANGLE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xE6B9d092223f39013656702A40dbE6B7DeCc5746/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x921f99719Eb6C01b4B8f0BA7973A7C24891e740A',
      decimals: 18,
      name: 'MetaBrands',
      symbol: 'MAGE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x921f99719Eb6C01b4B8f0BA7973A7C24891e740A/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x9C8E99eb130AED653Ef90fED709D9C3E9cC8b269',
      decimals: 18,
      name: 'Hertz Token',
      symbol: 'HTZ',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9C8E99eb130AED653Ef90fED709D9C3E9cC8b269/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x0fEc6d8A84A85b79A1FFE0E28c1902E08b653EFE',
      decimals: 18,
      name: 'Hoopoe Ventures',
      symbol: 'HOOP',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x0fEc6d8A84A85b79A1FFE0E28c1902E08b653EFE/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xC7f4debC8072e23fe9259A5C0398326d8EfB7f5c',
      decimals: 18,
      name: 'HeroesChained',
      symbol: 'HeC',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xC7f4debC8072e23fe9259A5C0398326d8EfB7f5c/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x7b2B702706D9b361dfE3f00bD138C0CFDA7FB2Cf',
      decimals: 18,
      name: 'Pollen',
      symbol: 'PLN',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7b2B702706D9b361dfE3f00bD138C0CFDA7FB2Cf/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x7761E2338B35bCEB6BdA6ce477EF012bde7aE611',
      decimals: 18,
      name: 'chikn egg',
      symbol: 'EGG',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7761E2338B35bCEB6BdA6ce477EF012bde7aE611/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xab592d197ACc575D16C3346f4EB70C703F308D1E',
      decimals: 18,
      name: 'chikn feed',
      symbol: 'FEED',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xab592d197ACc575D16C3346f4EB70C703F308D1E/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      decimals: 18,
      name: 'Dragon Crypto Aurum',
      symbol: 'DCAU',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x120AD3e5A7c796349e591F1570D9f7980F4eA9cb',
      decimals: 6,
      name: 'Axelar Wrapped LUNA',
      symbol: 'LUNA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x120AD3e5A7c796349e591F1570D9f7980F4eA9cb/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xf32398dae246C5f672B52A54e9B413dFFcAe1A44',
      decimals: 18,
      name: 'Kassandra',
      symbol: 'KACY',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xf32398dae246C5f672B52A54e9B413dFFcAe1A44/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x402FD175049e95CeF2cC9ca1FECdb6d9736e690d',
      decimals: 18,
      name: 'Catoshi',
      symbol: 'CATS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x402FD175049e95CeF2cC9ca1FECdb6d9736e690d/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x1fE4751d9bDabaC8D90067056cB45AB6823d2C12',
      decimals: 18,
      name: 'ArgonToken',
      symbol: 'ARGON',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x1fE4751d9bDabaC8D90067056cB45AB6823d2C12/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x7F29e7086453ed97283893E763aE0989F4B70f8d',
      decimals: 6,
      name: 'DGC Token',
      symbol: 'DGC',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7F29e7086453ed97283893E763aE0989F4B70f8d/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x164334Ed9E63FbEdC8B52E6dbD408Af4F051419f',
      decimals: 6,
      name: 'ENXT Token',
      symbol: 'ENXT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x164334Ed9E63FbEdC8B52E6dbD408Af4F051419f/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xE06fba763C2104dB5027F57f6A5Be0a0D86308af',
      decimals: 18,
      name: 'Akitavax',
      symbol: 'AKITAX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xE06fba763C2104dB5027F57f6A5Be0a0D86308af/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xf03Dccaec9A28200A6708c686cf0b8BF26dDc356',
      decimals: 18,
      name: 'YDragon',
      symbol: 'YDR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xf03Dccaec9A28200A6708c686cf0b8BF26dDc356/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB',
      decimals: 18,
      name: 'HonToken',
      symbol: 'HON',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xd9D90f882CDdD6063959A9d837B05Cb748718A05',
      decimals: 18,
      name: 'More Token',
      symbol: 'MORE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xd9D90f882CDdD6063959A9d837B05Cb748718A05/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x312eE43Df66d1Fd1EA28e5b28F355Da84dCA13C2',
      decimals: 12,
      name: 'SwapXI Token',
      symbol: 'SWAPXI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x312eE43Df66d1Fd1EA28e5b28F355Da84dCA13C2/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5',
      decimals: 18,
      name: 'FIRE',
      symbol: 'FIRE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xCe2fbed816E320258161CeD52c2d0CEBcdFd8136',
      decimals: 18,
      name: 'BRIBE',
      symbol: 'BRIBE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xCe2fbed816E320258161CeD52c2d0CEBcdFd8136/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xe19A1684873faB5Fb694CfD06607100A632fF21c',
      decimals: 18,
      name: 'BavaToken',
      symbol: 'BAVA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xe19A1684873faB5Fb694CfD06607100A632fF21c/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
      decimals: 18,
      name: 'Aave Token',
      symbol: 'AAVE.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x63a72806098Bd3D9520cC43356dD78afe5D386D9/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x5947BB275c521040051D82396192181b413227A3',
      decimals: 18,
      name: 'Chainlink Token',
      symbol: 'LINK.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x5947BB275c521040051D82396192181b413227A3/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76',
      decimals: 18,
      name: 'SushiToken',
      symbol: 'SUSHI.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x50b7545627a5162F82A992c33b87aDc75187B218',
      decimals: 8,
      name: 'Wrapped BTC',
      symbol: 'WBTC.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x50b7545627a5162F82A992c33b87aDc75187B218/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
      decimals: 18,
      name: 'Wrapped Ether',
      symbol: 'WETH.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x00EE200Df31b869a321B10400Da10b561F3ee60d',
      decimals: 18,
      name: 'Arable Protocol',
      symbol: 'ACRE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x00EE200Df31b869a321B10400Da10b561F3ee60d/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE',
      decimals: 18,
      name: 'Staked AVAX',
      symbol: 'sAVAX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x0659133127749Cc0616Ed6632912ddF7cc8D7545',
      decimals: 18,
      name: 'DefiLaunch Token',
      symbol: 'DLAUNCH',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x0659133127749Cc0616Ed6632912ddF7cc8D7545/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xb67a9374Da03d4114a6FB8f0E7F2b82b5cB34ee3',
      decimals: 18,
      name: 'Augmented Finance',
      symbol: 'AGF',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb67a9374Da03d4114a6FB8f0E7F2b82b5cB34ee3/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xB0a6e056B587D0a85640b39b1cB44086F7a26A1E',
      decimals: 18,
      name: 'OddzToken',
      symbol: 'ODDZ',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB0a6e056B587D0a85640b39b1cB44086F7a26A1E/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xf3EC49ACb3084618121741e4BBB20996D383E9b2',
      decimals: 18,
      name: 'KING',
      symbol: 'KING',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xf3EC49ACb3084618121741e4BBB20996D383E9b2/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x94d649E017625dE1Aa04733aA046dE0161DF18eF',
      decimals: 18,
      name: 'King Shares',
      symbol: 'KSHARE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x94d649E017625dE1Aa04733aA046dE0161DF18eF/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x4960bc6032351ab87627B8F36b37B4938A44C7eF',
      decimals: 18,
      name: 'RICH',
      symbol: 'RICH',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4960bc6032351ab87627B8F36b37B4938A44C7eF/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x44754455564474A89358B2C2265883DF993b12F0',
      decimals: 18,
      name: 'ZeroSwapToken',
      symbol: 'ZEE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x44754455564474A89358B2C2265883DF993b12F0/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xD4d026322C88C2d49942A75DfF920FCfbC5614C1',
      decimals: 18,
      name: 'DEAPCOIN',
      symbol: 'DEP',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xD4d026322C88C2d49942A75DfF920FCfbC5614C1/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x15c841043e13fFAA9a99FabEa236D40F45615623',
      decimals: 18,
      name: 'BUSINESSES',
      symbol: 'BUSINESSES',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x15c841043e13fFAA9a99FabEa236D40F45615623/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x4B322765D761984C0f9E72200B62a9b445F38caC',
      decimals: 18,
      name: 'dolla',
      symbol: 'DOLLA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4B322765D761984C0f9E72200B62a9b445F38caC/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xc69Eba65e87889f0805dB717Af06797055A0BA07',
      decimals: 18,
      name: 'NitroNetwork',
      symbol: 'ncash',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xc69Eba65e87889f0805dB717Af06797055A0BA07/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x979fFD8eEd7a43629eA29581DF4Bfe2b3F224e47',
      decimals: 18,
      name: 'Omlira',
      symbol: 'OML',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x979fFD8eEd7a43629eA29581DF4Bfe2b3F224e47/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xE16253892F126D068E711C2fdde6DB56969dBCf6',
      decimals: 18,
      name: 'Splash Token',
      symbol: 'SPLASH',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4ec58f9D205F9c919920313932cc71EC68d123C7/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xbc6f589171d6d66EB44ebCC92dFFb570Db4208da',
      decimals: 18,
      name: 'Wave Token',
      symbol: 'WAVE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xbc6f589171d6d66EB44ebCC92dFFb570Db4208da/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x97E7044ab98B7891546485f5f66EDcc2F58be106',
      decimals: 18,
      name: 'EMERALD',
      symbol: 'EM',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x97E7044ab98B7891546485f5f66EDcc2F58be106/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12',
      decimals: 6,
      name: 'Axelar Wrapped ATOM',
      symbol: 'axlATOM',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x449674B82F05d498E126Dd6615a1057A9c088f2C',
      decimals: 18,
      name: 'LostToken',
      symbol: 'LOST',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x449674B82F05d498E126Dd6615a1057A9c088f2C/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x1B88D7aD51626044Ec62eF9803EA264DA4442F32',
      decimals: 18,
      name: 'ZooToken',
      symbol: 'ZOO',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x1B88D7aD51626044Ec62eF9803EA264DA4442F32/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x056D114fF1e01dE3BCA30F0Efa3655DF42880e5B',
      decimals: 18,
      name: 'KyteOne',
      symbol: 'KTE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x056D114fF1e01dE3BCA30F0Efa3655DF42880e5B/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x1111111111182587795eF1098ac7da81a108C97a',
      decimals: 18,
      name: 'Bold Point Token',
      symbol: 'BPT',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x1111111111182587795eF1098ac7da81a108C97a/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xd13eB71515DC48a8a367D12F844e5737bab415dF',
      decimals: 18,
      name: 'Spice',
      symbol: 'SFI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xd13eB71515DC48a8a367D12F844e5737bab415dF/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x5a15Bdcf9a3A8e799fa4381E666466a516F2d9C8',
      decimals: 18,
      name: 'Snail Trail',
      symbol: 'SLIME',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x5a15Bdcf9a3A8e799fa4381E666466a516F2d9C8/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x714f020C54cc9D104B6F4f6998C63ce2a31D1888',
      decimals: 18,
      name: 'STEP.APP',
      symbol: 'FITFI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x714f020C54cc9D104B6F4f6998C63ce2a31D1888/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x4156F18bF7C1ef04248632C66Aa119De152D8f2E',
      decimals: 18,
      name: 'Zeus Node Finance',
      symbol: 'ZEUS',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4156F18bF7C1ef04248632C66Aa119De152D8f2E/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      decimals: 6,
      name: 'Tether USD',
      symbol: 'USDT.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      decimals: 6,
      name: 'USD Coin',
      symbol: 'USDC.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      decimals: 18,
      name: 'Dai Stablecoin',
      symbol: 'DAI.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x564A341Df6C126f90cf3ECB92120FD7190ACb401',
      decimals: 6,
      name: 'BiLira',
      symbol: 'TRYB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x564A341Df6C126f90cf3ECB92120FD7190ACb401/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98',
      decimals: 18,
      name: 'Binance USD',
      symbol: 'BUSD.e',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x4fbf0429599460D327BD5F55625E30E4fC066095',
      decimals: 18,
      name: 'TSD Stablecoin',
      symbol: 'TSD',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4fbf0429599460D327BD5F55625E30E4fC066095/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x130966628846BFd36ff31a822705796e8cb8C18D',
      decimals: 18,
      name: 'Magic Internet Money',
      symbol: 'MIM',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x130966628846BFd36ff31a822705796e8cb8C18D/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x783C08b5F26E3daf8C4681F3bf49844e425b6393',
      decimals: 18,
      name: 'Avaware USD',
      symbol: 'AUSD',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x783C08b5F26E3daf8C4681F3bf49844e425b6393/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x346A59146b9b4a77100D369a3d18E8007A9F46a6',
      decimals: 18,
      name: 'AVAI',
      symbol: 'AVAI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x346A59146b9b4a77100D369a3d18E8007A9F46a6/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64',
      decimals: 18,
      name: 'Frax',
      symbol: 'FRAX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b',
      decimals: 18,
      name: 'miMatic',
      symbol: 'MAI',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x026187BdbC6b751003517bcb30Ac7817D5B766f8',
      decimals: 18,
      name: 'Defrost Finance H2O',
      symbol: 'H2O',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x026187BdbC6b751003517bcb30Ac7817D5B766f8/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      decimals: 6,
      name: 'USD Coin',
      symbol: 'USDC',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x6feFd97F328342a8A840546A55FDcfEe7542F9A8',
      decimals: 18,
      name: 'agEUR',
      symbol: 'agEUR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x6feFd97F328342a8A840546A55FDcfEe7542F9A8/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11',
      decimals: 6,
      name: 'Axelar Wrapped UST',
      symbol: 'axlUST',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      decimals: 6,
      name: 'TetherToken',
      symbol: 'USDt',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948',
      decimals: 18,
      name: 'Moremoney USD',
      symbol: 'MONEY',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB',
      decimals: 18,
      name: 'JPY Coin',
      symbol: 'JPYC',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
      decimals: 6,
      name: 'Wormhole UST',
      symbol: 'UST',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb599c3590F42f8F995ECfa0f85D2980B76862fc1/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x61eCd63e42C27415696e10864d70ecEA4aA11289',
      decimals: 18,
      name: 'Rugpull Prevention',
      symbol: 'RUGPULL',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x61eCd63e42C27415696e10864d70ecEA4aA11289/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xcF8419A615c57511807236751c0AF38Db4ba3351',
      decimals: 18,
      name: 'AxialToken',
      symbol: 'AXIAL',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xcF8419A615c57511807236751c0AF38Db4ba3351/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x491a4eB4f1FC3BfF8E1d2FC856a6A46663aD556f',
      decimals: 4,
      name: 'BRZ',
      symbol: 'BRZ',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x491a4eB4f1FC3BfF8E1d2FC856a6A46663aD556f/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x827eb4bada6cb76c90f887969b3fe5faD585FFe3',
      decimals: 18,
      name: 'XETA',
      symbol: 'XETA',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x827eb4bada6cb76c90f887969b3fe5faD585FFe3/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x6B56Ec4a92765203508FB40feC9fa23E549B705a',
      decimals: 18,
      name: 'Unbound Dollar',
      symbol: 'UND',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x6B56Ec4a92765203508FB40feC9fa23E549B705a/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xF915fDDa4c882731C0456a4214548Cd13A822886',
      decimals: 18,
      name: 'Unbound',
      symbol: 'UNB',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xF915fDDa4c882731C0456a4214548Cd13A822886/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x51e48670098173025C477D9AA3f0efF7BF9f7812',
      decimals: 18,
      name: 'DegenX',
      symbol: 'DGNX',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x51e48670098173025C477D9AA3f0efF7BF9f7812/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xcf799767d366d789e8B446981C2D578E241fa25c',
      decimals: 18,
      name: 'Decentralized USD',
      symbol: 'USDD',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xcf799767d366d789e8B446981C2D578E241fa25c/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
      decimals: 18,
      name: 'BUSD Token',
      symbol: 'BUSD',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xbBAAA0420D474B34Be197f95A323C2fF3829E811',
      decimals: 18,
      name: 'LODE Token',
      symbol: 'LODE',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xbBAAA0420D474B34Be197f95A323C2fF3829E811/logo_24.png',
    },
    {
      chainId: 43114,
      address: '0xE397784960F814BA35C9Ee0BC4c9DFFdf86925f9',
      decimals: 18,
      name: 'MSTR Token',
      symbol: 'MSTR',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xE397784960F814BA35C9Ee0BC4c9DFFdf86925f9/logo_24.png',
    },
    {
      chainId: 80001,
      address: '0x4828a3D98E428e73184374845f23C40eB76bA695',
      decimals: 18,
      name: 'Pangolin',
      symbol: 'mumbaiPNG',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/80001/0x4828a3D98E428e73184374845f23C40eB76bA695/logo_24.png',
    },
  ],
};
