import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StakingModal from './StakingModal';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function TableContent({ data, columns }) {
  // const [maindata, setMainData] = useState(() => [...data]);
  const [sorting, setSorting] = useState([]);
  const open = useSelector((state) => state.modal.open);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div>
        <table className="w-full min-w-[-webkit-fill-available]">
          <thead className="block w-full">
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="flex w-full">
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={`w-full px-2 py-5 text-left !text-sm font-normal tracking-wider text-gray-300 sm:text-xl`}
                  >
                    {/* {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())} */}
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex flex-row gap-[1px] item-center'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() && !header.column.getIsSorted() && (
                          <div className="flex flex-col items-center justify-start gap-[2px]">
                            <div className="h-[3px] w-1">
                              <ArrowDropUpIcon viewBox="0 0 35 35" htmlColor="gray" />
                            </div>
                            <div className="h-[3px] w-1">
                              <ArrowDropDownIcon viewBox="0 0 35 35" htmlColor="gray" />
                            </div>
                          </div>
                        )}
                        {{
                          asc: (
                            <div className="h-[3px] w-1">
                              <ArrowDropUpIcon viewBox="0 0 35 35" htmlColor="gray" />
                            </div>
                          ),
                          desc: (
                            <div className="h-[3px] w-1">
                              <ArrowDropDownIcon viewBox="0 0 35 35" htmlColor="gray" />
                            </div>
                          ),
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="hide-scroll hide-scroll-sm block max-h-[40vh] w-full overflow-y-scroll">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="table_row  flex w-full  !text-left">
                {row.getVisibleCells().map((cell) => (
                  <td className="w-full">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <StakingModal open={open} />
    </>
  );
}
