import * as Dialog from '@radix-ui/react-dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUnstakeOpen, setUnstakeModalData } from '../../store/UnstakSlice';
import { ReactComponent as Closee } from '../../assets/close.svg';
import DisplayTime from './DisplayTime';
import { BigNumber, ethers } from 'ethers';
import LockUpABI from '../../ABI/DrivenLockupABI.json';
import { getAddresses } from '../../utils/addressHelpers';
import { getContract } from '../../utils/contractHelpers';
import { useAccount, useSigner } from 'wagmi';
import { setRefresh } from '../../store/ModalSlice';

export default function UnStakingModal({ open }) {
  const dispatch = useDispatch();
  const [btfValue, setBtfValue] = useState();
  const data = useSelector((state) => state.unstake.modalData);
  const [message, setMessage] = useState('');
  const [disable, setDisable] = useState(true);
  const addresses = getAddresses();
  const locupAddress = addresses[0].lockup;
  const { data: signer } = useSigner();
  const { address: account } = useAccount();

  const [available, setAvailable] = useState('0');
  useEffect(() => {
    if (open) {
      setAvailable(ethers.utils.formatEther(data?.user?.available));
    }
  }, [open]);

  useEffect(() => {
    dispatch(setUnstakeOpen(false));
    dispatch(setUnstakeModalData([]));
  }, [account]);
  
  useEffect(()=>{
    if(btfValue && btfValue !== '')
      setDisable(false)
    else 
      setDisable(true)
    console.log("value inptted", btfValue)
  }, [btfValue])

  const handleBtfValue = (e) => {
    let value = e.target.value;
    // debugger;
    if (isNaN(value)) return;
    if (value === '') {
      setBtfValue('');
      return;
    }
    value = parseFloat(value);
    if (value === null) {
      setMessage('');
      setDisable(true);
    } else if (value === 0) {
      setMessage('The amount should be more then 0');
      setDisable(true);
    } else if (value > parseFloat(available)) {
      setMessage('The value should be less then max amount');
      setDisable(true);
    } else {
      setDisable(false);
      setMessage('');
    }
    setBtfValue(value);
  };

  const UnstakeBtf = async () => {
    try {
      setDisable(true);
      const lockupContract = getContract(LockUpABI, locupAddress, signer);
      const tx = await lockupContract.withdraw(ethers.utils.parseEther(btfValue), data.stackType);
      await tx.wait();
      setDisable(false);
      dispatch(setRefresh(true));
      dispatch(setUnstakeOpen(false));
    } catch (error) {
      setDisable(false);
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Dialog.Root open={open}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed top-0 left-0 right-0 h-screen w-screen bg-overlay-bg backdrop-blur-sm data-[state=open]:animate-overlayShow" />
            <Dialog.Content className="animate_div fixed top-1/2 left-1/2 w-[90vw] -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white p-4 drop-shadow-[0_4px_40px_rgba(247,185,62,0.25)] focus-visible:outline-none dark:bg-[#1D202C] sm:w-1/3">
              <div className="absolute right-1 items-center justify-end px-5">
                <Dialog.Close
                  asChild
                  onClick={() => {
                    dispatch(setUnstakeOpen(false));
                    dispatch(setUnstakeModalData([]));
                  }}
                  className="cursor-pointer"
                >
                  <Closee />
                </Dialog.Close>
              </div>
              <Dialog.Content
                asChild
                className="flex flex-col items-center justify-center focus-visible:outline-none"
              >
                <>
                  <div className="flex flex-col items-center justify-center">
                    <div className="mt-4 text-xl font-medium sm:text-3xl">
                      UnStake $BTF - <span className="tracking-widest">{data.term}</span>
                    </div>
                    <div className="my-10 flex items-center justify-center">
                      <DisplayTime />
                    </div>
                    <div className="flex w-[-webkit-fill-available] flex-col justify-start p-5">
                      <div className="mx-auto flex flex-row items-center gap-5">
                        <div className="rounded-full bg-black px-3 py-2 text-xs text-white">
                          Available amount :{' '}
                          <span className="pl-2 tracking-widest text-primary-sunny-main">
                            {parseFloat(available).toFixed(6)}
                          </span>{' '}
                          BTF
                        </div>
                        <div>
                          <button
                            className="cursor-pointer rounded-full bg-black px-3 py-1 text-white"
                            onClick={() => setBtfValue(available)}
                          >
                            Max
                          </button>
                        </div>
                      </div>
                      <div className="mx-auto mt-2 flex flex-row items-center gap-5">
                        <form className="mt-2 flex flex-row items-center gap-5 ">
                          <div>
                            <input
                              className="rounded-xl border border-primary-sunny-blurSemiDarkBg bg-gray-800 py-4 px-2 text-white focus-visible:outline-none"
                              type="text"
                              value={btfValue}
                              placeholder="0"
                              onChange={(e) => handleBtfValue(e)}
                            />
                          </div>
                          <div>
                            <button
                              className={`rounded-xl bg-primary-sunny-blurSemiDarkBg p-4 capitalize tracking-wider duration-200 ease-in-out focus-visible:outline-none 
                              ${
                                disable
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer hover:bg-primary-sunny-dark'
                              }`}
                              onClick={() => UnstakeBtf()}
                              disabled={disable}
                              type="button"
                            >
                              UNSTAKE
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="flex justify-center py-3 text-xs">{message}</div>
                    </div>
                  </div>
                </>
              </Dialog.Content>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </>
  );
}
