/* eslint-disable no-undef */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useOpenOrders, useOrderHistory } from '../../../hooks/trade/useOrderInfo';
import { useAccount, useSigner } from 'wagmi';
import Button from '../../shared/Button';
import useTradeInfo from '../../../hooks/useTradeContext';
import { getLimitOrderContract } from '../../../utils/contractHelpers';
import { cancelOrder } from '../../../actions/Order';

export default function OrderPanel() {
  const filters = ['Open Orders', 'Order History' /*'Funds'*/];

  const { address: account } = useAccount();
  const [activeFilter, setActiveFilter] = useState(0);
  const histories = useOrderHistory();
  const orders = useOpenOrders();
  const { data: signer } = useSigner();

  const userHistories = histories.filter(
    (data) => account && data.owner.toLowerCase() === account.toLowerCase()
  );
  const userOrders = orders.filter(
    (data) => account && data.owner.toLowerCase() === account.toLowerCase()
  );

  const { pending, setPending } = useTradeInfo();

  async function onCancel(index) {
    setPending(true);
    const swapContract = getLimitOrderContract(signer);
    try {
      const tx = await swapContract.cancelOrder(
        userOrders[index].index,
        userOrders[index].nonce,
        userOrders[index].tokenGive.amount
      );
      await tx.wait();
      setPending(false);
      await cancelOrder(account, userOrders[index].nonce);
    } catch (error) {
      console.log(error);
    }
    setPending(false);
  }

  return (
    <StyledPanel className="h-full pt-1">
      <FilterPanel className="mx-3 flex" active={activeFilter + 1}>
        {filters.map((data, i) => {
          return (
            <div
              key={i}
              className="mr-3 flex h-6 w-[120px] cursor-pointer items-center justify-center rounded text-xs font-semibold uppercase text-[#a7acb9] transition hover:text-[#CA8D31]"
              onClick={() => setActiveFilter(i)}
            >
              {data}
            </div>
          );
        })}
      </FilterPanel>
      <div>
        <div className="mt-3 flex  h-8 w-[calc(100vw-12px)] items-center justify-between overflow-x-scroll bg-black px-2 text-xs md:w-full">
          <div className="min-w-[90px] text-center">DATE & TIME</div>
          <div className="min-w-[90px] text-center">PAIR</div>
          <div className="min-w-[60px] text-center">TYPE</div>
          <div className="min-w-[60px] text-center">AMOUNT</div>
          <div className="min-w-[60px] text-center">PRICE</div>
          <div className="min-w-[60px] text-center">TOTAL</div>
          {activeFilter !== 1 ? <div className="w-[60px]">ACTION</div> : ''}
        </div>
        {(activeFilter !== 1 ? userOrders : userHistories).map((data, i) => {
          const createdTime = new Date(data.createdTime);
          return (
            <div
              key={i}
              className={`${
                data.type === 'buy' ? 'text-success' : 'text-danger'
              } my-1  flex h-8 w-[calc(100vw-12px)] items-center justify-between overflow-x-scroll px-2 text-xs md:w-full`}
            >
              <div className="min-w-[90px] text-center">
                {createdTime.toLocaleDateString()} {createdTime.toLocaleTimeString()}
              </div>
              <div className="min-w-[90px] text-center">
                {data.tokenGive.symbol}/{data.tokenGet.symbol}
              </div>
              <div className="min-w-[60px] text-center">{data.type.toUpperCase()}</div>
              <div className="min-w-[60px] text-center">
                {(data.tokenGive.amount / Math.pow(10, data.tokenGive.decimals)).toFixed(2)}
              </div>
              <div className="min-w-[60px] text-center">{data.rate.toFixed(2)}</div>
              <div className="min-w-[60px] text-center">
                {(
                  ((data.tokenGet.amount / Math.pow(10, data.tokenGet.decimals)) *
                    data.tokenGive.amount) /
                  Math.pow(10, data.tokenGive.decimals)
                ).toFixed(2)}
              </div>
              {activeFilter === 0 ? (
                <div
                  className="flex h-6 w-[60px] cursor-pointer items-center justify-center rounded-lg border border-danger text-xs font-semibold text-danger"
                  onClick={() => onCancel(i)}
                >
                  Cancel
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
    </StyledPanel>
  );
}
const FilterPanel = styled.div`
  > div:nth-child(${({ active }) => active}) {
    background: #ca8d31;
    color: white;
  }
`;

const StyledPanel = styled.div`
  background: #1b1b1b;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    display: block !important;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb:vertical {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #eebb19;
  }
  > div > div::-webkit-scrollbar {
    display: none !important;
  }
`;
