import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  open: false,
  refresh: false,
  tokenInputData: {
    token0: {
      address: '',
      symbol: '',
    },
    token1: {
      address: '',
      symbol: '',
    },
    totalSupply: 0,
  },
};

export const CustomeTokenSlice = createSlice({
  name: 'CustomeTokenSlice',
  initialState: {
    ...initialState,
  },
  reducers: {
    setOpen: (data, action) => {
      data.open = action.payload;
    },

    setTokenInputData: (data, action) => {
      data.tokenInputData = action.payload;
    },

    setRefresh: (data, action) => {
      data.refresh = action.payload;
    },
  },
});

export const { setOpen, setTokenInputData, setRefresh } = CustomeTokenSlice.actions;
export default CustomeTokenSlice.reducer;
