// import { Signer } from "@ethersproject/abstract-signer";
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { activeNetwork, networks } from '../config/networkConfig';
import { getLimitOrderAddress, getMulticallAddress } from './addressHelpers';
import MulticallABI from '../ABI/MulticallABI.json';
import TokenABI from '../ABI/TokenABI.json';
import ERC20ABI from '../ABI/ERC20ABI.json';
import LimitOrderABI from '../ABI/LimitOrderABI.json';
// import { Address } from "cluster";

const chainId = networks[activeNetwork]['chainId'];
const simpleRpcProvider = new StaticJsonRpcProvider(networks[activeNetwork]['rpc'][chainId]);
export const getContract = (abi, address, signer) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new Contract(address, abi, signerOrProvider);
};

export const getMulticallContract = () => {
  return getContract(MulticallABI, getMulticallAddress(), simpleRpcProvider);
};

export const getDrivenContract = (signer, tokenAddress) => {
  return getContract(TokenABI, tokenAddress, signer);
};

export const getTokenContract = (tokenAddress, signer) => {
  return getContract(ERC20ABI, tokenAddress, signer);
};

export const getLimitOrderContract = (signer) => {
  return getContract(LimitOrderABI, getLimitOrderAddress(), signer);
};
