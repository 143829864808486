import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
// import { breakPoints } from '../../utils';
import SidebarContent from '../layout/SidebarContent';
// import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Closee } from '../../assets/close.svg';
import Logo from '../../assets/logo.png';

// const sm = window.matchMedia(`(min-width: ${breakPoints.sm})`);

export default function Sidebar({ open, setOpen }) {
  return (
    <div>
      {/* {sm.matches ? ( */}
      {/* <SidebarContent />
       ) : ( */}
      <Dialog.Root open={open}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-overlay-bg data-[state=open]:animate-overlayShow" />
          <Dialog.Content className="animatingg fixed inset-0 w-9/12 bg-white focus-visible:outline-none dark:bg-[#1D202C]">
            <div className="absolute right-1 z-30 items-center justify-end px-5 pt-[1.6rem]">
              <Dialog.Close
                asChild
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Closee />
              </Dialog.Close>
            </div>
            {/* <Dialog.Content asChild className="focus-visible:outline-none"> */}
              <div className='z-50'>
                <div className="px-5 pt-4 focus-visible:outline-none">
                  <div className="mb-2 flex items-center justify-between gap-4 rounded-xl ">
                    <div className="flex flex-row items-center gap-1">
                      {/* <Logo className="h-8 w-8" /> */}
                      <img src={Logo} alt="Logo" className="h-8 w-8" />
                      <span className="text-sm"> Bee-Trade Finance </span>
                    </div>
                  </div>
                  <div className="mb-1 h-[1px] w-full border-[1px] border-[rgba(194,194,194,0.26)]"></div>
                </div>
                <SidebarContent setOpen={setOpen}/>
              </div>
            {/* </Dialog.Content> */}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      {/* )} */}
    </div>
  );
}
