import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';

export default function DisplayTime({ time }) {
  const [diff, setDiff] = useState();
  useEffect(() => {
    let interval;
    let endTime = moment(time * 1000);
    let currentTime = moment();
    if (!interval) {
      interval = setInterval(() => {
        currentTime = moment();
        const diff = moment.duration(endTime.diff(currentTime));
        setDiff(diff);
      }, 1000);
    }

    return () =>{
      clearInterval(interval);
    }
  }, []);

  return (
    <>
      <div className="grid auto-cols-max grid-flow-col gap-2 sm:gap-5 text-center">
      <div className="bg-neutral rounded-box text-neutral-content flex flex-col rounded-lg bg-primary-sunny-blurSemiDarkBg p-2 w-[40px] sm:w-[60px]">
          {/* <span className="countdown font-mono text-5xl"> */}
            <span className="text-xl sm:text-4xl">{diff?.years() > 0 ? diff?.years() : 0}</span>
          {/* </span> */}
          Y
        </div>
      <div className="bg-neutral rounded-box text-neutral-content flex flex-col rounded-lg bg-primary-sunny-blurSemiDarkBg p-2 w-[40px] sm:w-[60px]">
          {/* <span className="countdown font-mono text-5xl"> */}
            <span className="text-xl sm:text-4xl">{diff?.months() > 0 ? diff?.months() : 0}</span>
          {/* </span> */}
          M
        </div>
        <div className="bg-neutral text-neutral-content flex flex-col rounded-lg bg-primary-sunny-blurSemiDarkBg p-2 w-[40px] sm:w-[60px]">
          {/* <span className="countdown font-mono text-5xl"> */}
            <span className="text-xl sm:text-4xl">{diff?.days() > 0 ? diff?.days() : 0}</span>
          {/* </span> */}
          D
        </div>
        <div className="bg-neutral text-neutral-content flex flex-col rounded-lg bg-primary-sunny-blurSemiDarkBg p-2 w-[40px] sm:w-[60px]">
          {/* <span className="countdown font-mono text-5xl"> */}
            <span  className="text-xl sm:text-4xl">{diff?.hours() > 0 ? diff?.hours() : 0}</span>
          {/* </span> */}
          H
        </div>
        <div className="bg-neutral text-neutral-content flex flex-col rounded-lg bg-primary-sunny-blurSemiDarkBg p-2 w-[40px] sm:w-[60px]">
          {/* <span className="countdown font-mono text-5xl"> */}
            <span className="text-xl sm:text-4xl">{diff?.minutes() > 0 ? diff?.minutes() : 0}</span>
          {/* </span> */}
          m
        </div>
      </div>
    </>
  );
}
