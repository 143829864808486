import React from 'react';
import ExpandableTable from './ExpandableTable';

export default function PersonalStake({ data }) {
  return (
    <>
      <div className="shadow-table m-4 rounded-2xl border-2 border-gray-600 border-opacity-25 bg-white !bg-opacity-70 dark:bg-bg-main">
        <header className="flex flex-row items-center border-b border-gray-600 p-5 ">
          <section className="flex-auto font-mulish tracking-wider">Your Staked BTF</section>
        </header>
        <section>
          <div className="overflow-x-auto">
            <ExpandableTable data={data} />
            {/* <ExpandableTable_ data={data}/> */}
            {/* <ExpandableTable data={data}/> */}
          </div>
        </section>
      </div>
    </>
  );
}
