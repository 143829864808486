import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';

export default function MyStakeDisplayTime({ time }) {
  const [diff, setDiff] = useState();
  useEffect(() => {
    let interval;
    let endTime = moment(time * 1000);
    let currentTime = moment();
    if (!interval) {
      interval = setInterval(() => {
        currentTime = moment();
        const diff = moment.duration(endTime.diff(currentTime));
        setDiff(diff);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="grid auto-cols-max grid-flow-col gap-2 text-center sm:gap-5">
        <div className="flex w-[40px] flex-row items-center justify-center gap-1 rounded-lg bg-primary-sunny-blurSemiDarkBg p-1 text-xs ">
          {/* <span className="countdown font-mono text-5xl"> */}
          <span className="text-xs">{diff?.years() > 0 ? diff?.years() : 0}</span>
          {/* </span> */}
          <span>Y</span>
        </div>
        <div className="text-x flex w-[40px] flex-row items-center justify-center gap-1 rounded-lg bg-primary-sunny-blurSemiDarkBg p-1 text-xs">
          {/* <span className="countdown font-mono text-5xl"> */}
          <span className="text-xs">{diff?.months() > 0 ? diff?.months() : 0}</span>
          {/* </span> */}
          <span>M</span>
        </div>
        <div className="text-x flex w-[40px] flex-row items-center justify-center gap-1 rounded-lg bg-primary-sunny-blurSemiDarkBg p-1 text-xs">
          {/* <span className="countdown font-mono text-5xl"> */}
          <span className="text-xs">{diff?.days() > 0 ? diff?.days() : 0}</span>
          {/* </span> */}D
        </div>
        <div className="text-x flex w-[40px] flex-row items-center justify-center gap-1 rounded-lg bg-primary-sunny-blurSemiDarkBg p-1 text-xs">
          {/* <span className="countdown font-mono text-5xl"> */}
          <span className="text-xs">{diff?.hours() > 0 ? diff?.hours() : 0}</span>
          {/* </span> */}H
        </div>
        <div className="text-x flex w-[40px] flex-row items-center justify-center gap-1 rounded-lg bg-primary-sunny-blurSemiDarkBg p-1 text-xs">
          {/* <span className="countdown font-mono text-5xl"> */}
          <span className="text-xs">{diff?.minutes() > 0 ? diff?.minutes() : 0}</span>
          {/* </span> */}m
        </div>
      </div>
    </>
  );
}
