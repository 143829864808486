import React, { useState, useEffect, useMemo, useCallback } from 'react';
import TableContent from './layout/TableContent';
import { useAccount, useProvider } from 'wagmi';
import { BigNumber, ethers } from 'ethers';
import ERC20ABI from '../ABI/ERC20ABI.json';
import { getAddresses, getLockUpAddresses, getTokenAddresses } from '../utils/addressHelpers';
import { setBtfBalance, setRefresh, setTotalBtf } from '../store/ModalSlice';
import { useDispatch, useSelector } from 'react-redux';
import PersonalStake from './PersonalStake';
import { multicallv2 } from '../utils/multicall';
import TokenABI from '../ABI/TokenABI.json';
import DrivenLockupABI from '../ABI/DrivenLockupABI.json';
import { pools } from '../utils/utils';
import { stake_columns } from './Helper/ColumnHelper';

const roundOff = (figure, decimals) => {
  if (!decimals) decimals = 2;
  var d = Math.pow(10, decimals);
  let val = (parseInt((figure * d).toString()) / d).toFixed(decimals);
  return parseFloat(val);
};

const calAPY = (lockupRate, totalStaked, decimal) => {
  if (totalStaked === 0) return 0;
  const rewardPerBlock = lockupRate / totalStaked;
  let aprvalue = (rewardPerBlock * 28555 * 365) / Math.pow(10, decimal - 2);
  aprvalue = roundOff(aprvalue, 4);
  return aprvalue;
};

export default function Staking() {
  const { address } = useAccount();
  const [data, setData] = useState();
  const provider = useProvider();
  const accBalance = useSelector((state) => state.modal.btfFormated);
  const totalBtfStaked = useSelector((state) => state.modal.totalBtf);
  const refresh = useSelector((state) => state.modal.requireRefresh);
  const dispatch = useDispatch();

  const addresses = getAddresses();
  const fetchData = async () => {
    try {
      const tokenAddress = addresses[0].DividendToken;
      const contract = new ethers.Contract(tokenAddress, ERC20ABI, provider);
      const BTFBalance = await contract.balanceOf(address);
      dispatch(setBtfBalance(BTFBalance.toString()));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (address) {
      fetchData();
    }
  }, [address, refresh]);

  // For fetching General Data

  const fetchPoolData = async () => {
    try {
      let lockUpContractCall = [];
      let tokenContractCall = [];
      let totalBtfStaked = 0;
      const tokenAddress = addresses[0].DividendToken;
      tokenContractCall.push(
        ...['name', 'symbol', 'decimals'].map((method) => ({
          address: tokenAddress,
          name: method,
        }))
      );
      const tokenCallContractData = await multicallv2(TokenABI, tokenContractCall);
      const lockupAddress = addresses[0].lockup;

      lockUpContractCall.push(
        ...[
          'bonusEndBlock',
          'earnedToken',
          'stakingToken',
          'PRECISION_FACTOR',
          'uniRouterAddress',
        ].map((method) => ({
          address: lockupAddress,
          name: method,
        }))
      );
      pools[0].forEach((pool) => {
        lockUpContractCall.push(
          ...['lockups'].map((method) => ({
            address: lockupAddress,
            name: method,
            params: [pool.stackType],
          }))
        );
      });

      // console.log('lockUpContractCall', lockUpContractCall);

      const lockUpContractData = await multicallv2(DrivenLockupABI, lockUpContractCall);

      pools[0] = pools[0].map((pool, i) => {
        const decimals = tokenCallContractData[2][0];
        const rate = lockUpContractData[pool.stackType + 5].rate;
        const stakingToken = lockUpContractData[2][0];
        const apy = calAPY(
          parseFloat(rate.toString()),
          parseFloat(
            ethers.utils.formatUnits(
              lockUpContractData[pool.stackType + 5].totalStaked,
              pool.decimals
            )
          ),
          tokenCallContractData[2][0]
        );
        const totalStaked = ethers.utils.formatUnits(
          lockUpContractData[pool.stackType + 5].totalStaked,
          pool.decimals
        );
        totalBtfStaked = totalBtfStaked + parseInt(totalStaked);
        dispatch(setTotalBtf(totalBtfStaked));
        return {
          ...pool,
          decimals,
          rate,
          apy,
          totalStaked,
          stakingToken,
          lockupAddress,
        };
      });
      setData(pools[0]);
      dispatch(setRefresh(false));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      fetchPoolData();
    }, 10000);
    fetchPoolData();

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchPoolData();
    }
  }, [refresh]);

  const fetchUserData = async () => {
    let userPool = [...data];
    if (address) {
      let tokenContractCall = [];
      let lockUpContractCall = [];

      const lockupAddresses = getLockUpAddresses();
      const tokenAddresses = getTokenAddresses();

      tokenAddresses.forEach((tokenAddress, index) => {
        const lockupAddress = lockupAddresses[index];

        tokenContractCall.push(
          ...['balanceOf'].map((method) => ({
            address: tokenAddress,
            name: method,
            params: [address],
          }))
        );

        tokenContractCall.push(
          ...['allowance'].map((method) => ({
            address: tokenAddress,
            name: method,
            params: [address, lockupAddress],
          }))
        );
      });

      for (let pool of userPool) {
        if (pool.contractType == 'lockup') {
          lockUpContractCall.push(
            ...['userInfo'].map((method) => ({
              address: lockupAddresses[0],
              name: method,
              params: [pool.stackType, address],
            }))
          );
          lockUpContractCall.push(
            ...['pendingReward', 'getActiveStake'].map((method) => ({
              address: lockupAddresses[0],
              name: method,
              params: [address, pool.stackType],
            }))
          );
        }
      }

      const fetchUserInfo = async () => {
        const tokenCallContractData = await multicallv2(TokenABI, tokenContractCall);

        const tokenInfo = new Map();
        tokenAddresses.forEach((tokenAddresses, index) => {
          tokenInfo.set(tokenAddresses, {
            balance: tokenCallContractData[index * 2][0],
            allowance: tokenCallContractData[index * 2 + 1][0],
          });
        });

        const lockUpContractData = await multicallv2(DrivenLockupABI, lockUpContractCall);

        let index = 0;
        userPool = userPool.map((pool, i) => {
          const userstack = lockUpContractData[3 * index][0].toString();
          const pendingreward = ethers.utils
            .formatUnits(BigNumber.from(lockUpContractData[3 * index + 1][0]), 18)
            .toString();
          const stakeendinEpoch = parseInt(lockUpContractData[3 * index + 2][0][3].toString());
          const stakeendin = lockUpContractData[3 * index + 2][0][3];
          const available = lockUpContractData[3 * index][1].toString();
          index++;
          return {
            ...pool,
            user: {
              ...pool.user,
              userstack,
              pendingreward,
              stakeendinEpoch,
              stakeendin,
              available,
            },
          };
        });
        dispatch(setRefresh(false));
        return userPool;
      };
      return await fetchUserInfo();
    }
  };
  const [userData, setUserData] = useState(data);
  useEffect(() => {
    (async function () {
      if (address) {
        const newUserData = await fetchUserData();
        setUserData(newUserData);
        return;
      }
      setUserData(data);
    })();
  }, [data, address]);
  const isPersonalTableVisiable = useMemo(() => {
    if (!userData) return false;
    return !userData.reduce((prev, { user }) => prev && user.userstack == '0', true);
  }, [userData]);

  return (
    <>
      <div className="bg_grad flex h-[calc(100vh-84px)]  flex-1 flex-col overflow-y-scroll rounded-tl-3xl bg-light-body-bg dark:bg-dark-body-bg">
        <div className=" !py-4 sm:p-8">
          <div className="staking_bg shadow-table m-4 rounded-2xl border-2 border-gray-600 border-opacity-25 !bg-opacity-70 dark:bg-bg-main">
            <header className="flex flex-row items-center border-b-[1px] border-gray-600 p-5">
              <section className="flex-auto font-mulish tracking-wider text-white ">
                Stake $BTF
              </section>
              <section>
                <div className="flex flex-col gap-8 sm:flex-row md:flex-row">
                  <div className="flex flex-col items-end tracking-wider  ">
                    <div className="text-white opacity-70">Total $BTF Staked</div>
                    <div className="mt-1">
                      <span className="text-lg font-medium tracking-wide text-primary-sunny-main">
                        {totalBtfStaked}
                      </span>{' '}
                      $BTF
                    </div>
                  </div>
                  <div className="flex flex-col items-end  tracking-wider">
                    <div className="text-white opacity-70">Available $BTF</div>
                    <div className="mt-1 text-lg font-medium tracking-wide text-primary-sunny-main">
                      {parseFloat(accBalance).toFixed(2)}
                    </div>
                  </div>
                </div>
              </section>
            </header>

            <section>
              <div className="overflow-x-auto duration-200 ease-in-out">
                {userData && <TableContent data={userData} columns={stake_columns} />}
              </div>
            </section>
          </div>
        </div>
        <div className="!py-4 sm:p-8">
          {address && isPersonalTableVisiable && <PersonalStake data={userData} />}
        </div>
      </div>
    </>
  );
}
