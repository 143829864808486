import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useProvider, useSigner } from 'wagmi';
import { setDisplayCurrencies, setLabel } from '../store/CurrenciesSuggestionsSlice';
import { getRouterAddress } from '../utils/addressHelpers';
import CurrencySuggestionsModal from './layout/currenciesSuggestionsModal';
import ROUTERABI from '../ABI/PangolinRouterABI.json';
import { ethers } from 'ethers';
import ERC20ABI from '../ABI/ERC20ABI.json';
import Toast from './shared/Toast';
import { toast } from 'react-toastify';
import Button from './shared/Button';
import CustomeConnectButton from './layout/CustomeConnectButton';

export default function WidthdrawLiquidity() {
  const displayCurrencies = useSelector((state) => state.currenciesSuggestions.displayCurrencies);
  const withdrawData = useSelector((state) => state.dropdown.withdrawData);
  // console.log('withdrawData', withdrawData);
  const label = useSelector((state) => state.currenciesSuggestions.label);
  const dispatch = useDispatch();
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const provider = useProvider();

  const [liquidityAmt, setLiquidityAmt] = useState();

  const routeraddress = getRouterAddress();
  const handleChange = (liquidity) => {
    if (isNaN(liquidity)) {
      return;
    }
    setLiquidityAmt(liquidity);
  };

  const fetchAllowance = async () => {
    try {
      const contract = new ethers.Contract(withdrawData.pairAddress, ERC20ABI, provider);
      const allowance = await contract.allowance(address, routeraddress);
      return allowance;
    } catch (error) {
      console.log(error);
    }
  };

  const [approve, setApprove] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async function () {
      if (liquidityAmt) {
        let allowance = await fetchAllowance();
        console.log('allowance', allowance.toString());
        // debugger;
        if (
          parseFloat(liquidityAmt) > parseFloat(ethers.utils.formatUnits(allowance.toString(), 18))
        ) {
          setApprove(true);
        } else {
          setApprove(false);
        }
      }
    })();
  }, [liquidityAmt]);

  const Approve = async () => {
    setLoading(true);
    try {
      const contract = new ethers.Contract(withdrawData.pairAddress, ERC20ABI, signer);
      const tx = await contract.approve(routeraddress, ethers.utils.parseUnits(liquidityAmt, 18));
      await tx.wait();
      setApprove(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const RemoveLiquidity = async () => {
    if (liquidityAmt === '' || liquidityAmt === null || liquidityAmt === undefined) {
      toast.error('Please enter amount..', { autoClose: 3000 });
      return;
    }
    if (parseFloat(liquidityAmt) > parseFloat(withdrawData.totalSupply)) {
      toast.error('The amount must be less then balance..', { autoClose: 3000 });
      return;
    }
    try {
      setLoading(true);
      console.log('first', ethers.utils.parseUnits(liquidityAmt, 18).toString());
      const contract = new ethers.Contract(routeraddress, ROUTERABI, signer);
      const tx = await contract.removeLiquidity(
        withdrawData.token0.address,
        withdrawData.token1.address,
        ethers.utils.parseUnits(liquidityAmt, 18),
        0,
        0,
        address,
        Math.ceil(Date.now() / 1000) + 10000
      );
      await toast.promise(tx.wait(), {
        pending: {
          render() {
            return (
              <Toast
                text={`Adding ${withdrawData.token0.symbol} and ${withdrawData.token1.symbol} in pool`}
                hash={''}
              />
            );
          },
        },
        success: {
          render() {
            return (
              <Toast
                text={`Added ${withdrawData.token0.symbol} and ${withdrawData.token1.symbol} in pool`}
                hash={tx.hash}
              />
            );
          },
        },
        error: {
          render() {
            return (
              <Toast
                text={`Error while adding ${withdrawData.token0.symbol} and ${withdrawData.token1.symbol}`}
                hash={tx.hash}
              />
            );
          },
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCalculatePercentage = (type) => {
    let balance;
    let newValue;
    balance = parseFloat(withdrawData.lpBal).toFixed(18);
    switch (type) {
      case '1':
        newValue = (balance * 25) / 100;
        break;
      case '2':
        newValue = (balance * 50) / 100;
        break;
      case '3':
        newValue = (balance * 75) / 100;
        break;
      case '4':
        newValue = balance;
        break;
      default:
        return;
    }
    setLiquidityAmt(parseFloat(newValue).toFixed(18));
  };

  return (
    <>
      <section className="mx-auto max-w-[500px] p-5 pt-0">
        <div className="liquidity_inputs relative my-3 flex flex-row justify-around py-2 ">
          <div className="flex flex-1 flex-col gap-5">
            <div className="my-2">
              <div class="flex items-center justify-start gap-3 opacity-70">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleCalculatePercentage('1');
                  }}
                >
                  <span className="rounded bg-[#1b1b1b] py-1 px-3">25%</span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleCalculatePercentage('2');
                  }}
                >
                  <span className="rounded bg-[#1b1b1b] py-1 px-3">50%</span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleCalculatePercentage('3');
                  }}
                >
                  <span className="rounded bg-[#1b1b1b] py-1 px-3">75%</span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleCalculatePercentage('4');
                  }}
                >
                  <span className="rounded bg-[#1b1b1b] py-[2px] px-3" type="button">
                    MAX
                  </span>
                </div>
              </div>
            </div>
            <div
              className="rounded-lg border-[2px] border-gray-500 py-2 px-3 duration-300 ease-linear hover:text-primary-sunny-dark"
              onClick={() => {
                if (address) {
                  dispatch(setLabel('Token Pair'));
                  dispatch(setDisplayCurrencies(true));
                } else {
                  toast.error('Please Connect Your wallet', { autoClose: 3000 });
                }
              }}
            >
              <button className="flex w-full items-center justify-between font-semibold">
                <span>{withdrawData.pool}</span>
                <div className="dropdown_icon">
                  <i className="bi bi-caret-down-fill"></i>
                </div>
              </button>
            </div>
            <div className="rounded-lg border-[2px] border-gray-500 py-2 px-3">
              <input
                type="text"
                className="w-full bg-transparent py-1 text-white opacity-70"
                placeholder="0.000"
                value={liquidityAmt}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                disabled={address ? false : true}
              />
              <div className="text-xs opacity-75">
                Balance : &nbsp;
                <span className="tracking-wider">{parseFloat(withdrawData.lpBal).toFixed(18)}</span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              {address ? (
                withdrawData && withdrawData.pool !== 'Select' ? (
                  approve ? (
                    // <button
                    //   className="text-gra w-full rounded-md py-3 px-5 text-lg font-semibold uppercase tracking-wider dark:bg-primary-sunny-blurSemiDarkBg"
                    //   onClick={() => {
                    //     Approve();
                    //   }}
                    //   disabled={loading}
                    // >
                    //   {loading ? 'Loading' : 'Approve'}
                    // </button>
                    <Button
                      isLoading={loading}
                      text={'Approve'}
                      action={Approve}
                      disabled={!address || loading}
                    />
                  ) : (
                    // <button
                    //   className="text-gra w-full rounded-md py-3 px-5 text-lg font-semibold uppercase tracking-wider dark:bg-primary-sunny-blurSemiDarkBg"
                    //   onClick={() => {
                    //     RemoveLiquidity();
                    //   }}
                    //   disabled={loading}
                    // >
                    //   {loading ? 'Loading' : 'unpool'}
                    // </button>
                    <Button
                      isLoading={loading}
                      text={'Remove Liquidity'}
                      action={RemoveLiquidity}
                      disabled={!address || loading}
                    />
                  )
                ) : (
                  <div className="swap_box_alert  self-center pb-5 ">
                    <div className="w-full ">
                      <span className=" text-center text-sm">Please Select Pair</span>
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div className="swap_box_alert  self-center pb-5 ">
                    <div className="w-full ">
                      <span className=" text-center text-sm">Please connect your wallet</span>
                    </div>
                  </div>
                  <div className="connect_wallet_btn_background  w-max self-center">
                    <CustomeConnectButton shouldDisplay={true} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <CurrencySuggestionsModal open={displayCurrencies} label={label} />
    </>
  );
}
