export let pools = [
  [
    {
      contractType: 'lockup',
      term: '1 year',
      lockupAddress: '',
      stakingToken: '',
      stackType: 0,
      duration: 0,
      total: 0,
      user: {
        tokenbalance: '0',
        userstack: '0',
        available: '0',
        pendingreward: '0',
        stakeendin: '0',
        stakeendinEpoch: 0,
      },
      subRows: [
        {
          term: 'extra',
          user: {
            tokenbalance: 'extra',
            userstack: 'extra',
            pendingreward: 'extra',
          },
          apy: 'extra',
        },
      ],
    },
    {
      contractType: 'lockup',
      lockupAddress: '',
      stakingToken: '',
      term: '2 years',
      stackType: 1,
      duration: 0,
      total: 0,
      user: {
        tokenbalance: '0',
        userstack: '0',
        available: '0',
        pendingreward: '0',
        stakeendin: '0',
        stakeendinEpoch: 0,
      },
      subRows: [
        {
          term: 'extra',
          user: {
            tokenbalance: 'extra',
            userstack: 'extra',
            pendingreward: 'extra',
          },
          apy: 'extra',
        },
      ],
    },
    {
      contractType: 'lockup',
      lockupAddress: '',
      stakingToken: '',
      term: '3 years',
      stackType: 2,
      duration: 0,
      total: 0,
      user: {
        tokenbalance: '0',
        userstack: '0',
        available: '0',
        pendingreward: '0',
        stakeendin: '0',
        stakeendinEpoch: 0,
      },
      subRows: [
        {
          term: 'extra',
          user: {
            tokenbalance: 'extra',
            userstack: 'extra',
            pendingreward: 'extra',
          },
          apy: 'extra',
        },
      ],
    },
    {
      contractType: 'lockup',
      lockupAddress: '',
      stakingToken: '',
      term: '4 years',
      stackType: 3,
      duration: 0,
      total: 0,
      user: {
        tokenbalance: '0',
        userstack: '0',
        available: '0',
        pendingreward: '0',
        stakeendin: '0',
        stakeendinEpoch: 0,
      },
      subRows: [
        {
          term: 'extra',
          user: {
            tokenbalance: 'extra',
            userstack: 'extra',
            pendingreward: 'extra',
          },
          apy: 'extra',
        },
      ],
    },
  ],
];
