import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useBalance, useProvider, useSigner } from 'wagmi';
import { setDisplayCurrencies, setLabel } from '../store/CurrenciesSuggestionsSlice';
// import { setDisabledBody } from '../store/DisabledBodySlice';
import { getAddresses, getRouterAddress } from '../utils/addressHelpers';
import CurrencySuggestionsModal from './layout/currenciesSuggestionsModal';
import ERC20ABI from '../ABI/ERC20ABI.json';
import FACTORYABI from '../ABI/FujiFactoryABI.json';
import ROUTERABI from '../ABI/PangolinRouterABI.json';
import PangolinRouterABI from '../ABI/PangolinRouterABI.json';

import Defalut_Logo from '../assets/Defalut_token_logo.png';
import ColourfulLoadingIcon from '../assets/colorfulLoading2.gif';

import {
  SwapAmount,
  getAmountsOut,
  getPairs,
  getReserves,
  getToken0,
} from './Helper/SwappingHelper';
import PangolinPairABI from '../ABI/PangolinPair.json';
import { BigNumber as BN } from 'bignumber.js';
import { TailSpin } from 'react-loader-spinner';
import Button from './shared/Button';
import { ToastContainer, toast } from 'react-toastify';
import Toast from './shared/Toast';
import { setRefresh } from '../store/CustomTokenSlice';
import CustomeConnectButton from './layout/CustomeConnectButton';

const WAVAX = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7';

export default function PoolLiquidity() {
  const displayCurrencies = useSelector((state) => state.currenciesSuggestions.displayCurrencies);
  let [disabledBody, setDisabledBody] = useState(false);

  const label = useSelector((state) => state.currenciesSuggestions.label);
  let selectA = useSelector((state) => state.currenciesSuggestions.select_A);
  let selectB = useSelector((state) => state.currenciesSuggestions.select_B);
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const dispatch = useDispatch();

  let AVAXBalanceObj = useBalance({
    address: address,
  });

  const [leftVal, setLeftVal] = useState(selectA);
  const [rightVal, setRightVal] = useState(selectB);
  const [leftInput, setLeftInput] = useState('');
  const [rightInput, setRightInput] = useState('');
  const [leftBalance, setLeftBalance] = useState(BigNumber.from('0'));
  const [rightBalance, setRightBalance] = useState(BigNumber.from('0'));
  const [infoData, setInfoData] = useState(false);
  const [isPair, setIsPair] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLeftVal(selectA);
    setRightVal(selectB);
  }, [selectA.symbol, selectB.symbol]);

  // For fetching Token Balance
  const provider = useProvider();
  const fetchTokenBalance = async (token_address) => {
    try {
      let contract = new ethers.Contract(token_address, ERC20ABI, provider);
      provider
        .getCode(address)
        .then((res) => console.log('Provider getCode Response', res))
        .catch((err) => console.log('Provider getCode Error', err));

      const balance = await contract.balanceOf(address);
      console.log('balance', balance);

      return balance.toString();
    } catch (error) {
      console.log('fetchTokenBalance error', error);
    }
  };

  useEffect(() => {
    if (selectA.address === undefined || selectA.address === null) return;
    if (selectA.address === WAVAX) {
      AVAXBalanceObj.refetch(true, false)
        .then((res) => {
          // console.log('AVAX Refetch', res);
          let balance = res.data.value;

          const bigBalance = BN(balance.toString());

          setLeftBalance(bigBalance.toString());
        })
        .catch((err) => console.log('AVAX BALANCE ERROR', err));
      // setLeftBalance(data?.value);
      return;
    }
    (async function () {
      let bal = await fetchTokenBalance(selectA.address);
      setLeftBalance(bal);
    })();
  }, [selectA, address]);

  useEffect(() => {
    if (selectB.address === undefined || selectB.address === null) return;
    if (selectB.address === WAVAX) {
      AVAXBalanceObj.refetch(true, false)
        .then((res) => {
          // console.log('AVAX Refetch', res);
          let balance = res.data.value;

          const bigBalance = BN(balance.toString());

          setRightBalance(bigBalance.toString());
        })
        .catch((err) => console.log('AVAX BALANCE ERROR', err));
      // setRightBalance(data?.value);
      return;
    }
    (async function () {
      let bal = await fetchTokenBalance(selectB.address);
      setRightBalance(bal);
    })();
  }, [selectB, address]);

  // EOF

  // Staring logic of pair Token address cheking
  const [rightDisabled, setRightDisabled] = useState(true);
  const addresses = getAddresses();
  const [reserves, setReserves] = useState();

  const getPair = async (addressA, addressB) => {
    let factoryAddress = addresses[0].factory;
    let pairAddress = await getPairs(factoryAddress, FACTORYABI, provider, addressA, addressB);
    if (pairAddress === '0x0000000000000000000000000000000000000000') {
      setRightDisabled(false);
      return false;
    } else {
      try {
        let Reserves = await getReserves(pairAddress, PangolinPairABI, provider);
        let token0 = await getToken0(pairAddress, PangolinPairABI, provider);
        if (token0 != addressA) {
          const temp = Reserves[0];
          Reserves[0] = Reserves[1];
          Reserves[1] = temp;
        }
        setReserves(Reserves);
        if (Reserves[0].isZero() && Reserves[1].isZero()) {
          setRightDisabled(false);
        } else {
          setRightDisabled(true);
        }
        return true;
      } catch (err) {
        console.log('Pair getting errr', err);
      }
    }
  };

  const [checkReserv, setCheckReserv] = useState(true);

  useEffect(() => {
    if (selectA.address && selectB.address && checkReserv) {
      try {
        (async function () {
          const isPair = await getPair(selectA.address, selectB.address);
          console.log('isPair', isPair);
          setIsPair(isPair);
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectA, selectB, checkReserv]);

  // EOF

  const handleCreatePaire = async () => {
    let factoryAddress = addresses[0].factory;
    try {
      setIsLoading(true);
      const contract = new ethers.Contract(factoryAddress, FACTORYABI, signer);
      const tx = await contract.createPair(selectA.address, selectB.address);
      await tx.wait();
      const isPair = await getPair(selectA.address, selectB.address);
      setIsPair(isPair);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const [displayleftAprv, setDisplayLeftAprv] = useState(false);
  const [displayrightAprv, setDisplayRightAprv] = useState(false);

  // For checking Allowance on input change
  const checkAllowance = async (token_name, token_address, decimal, inputvalue) => {
    if (token_address === WAVAX) return;
    if (inputvalue === '' && token_name === selectA.symbol) {
      setDisplayLeftAprv(false);
    } else if (inputvalue === '' && token_name === selectB.symbol) {
      setDisplayRightAprv(false);
    }
    try {
      const contract = new ethers.Contract(token_address, ERC20ABI, provider);
      const allowance = await contract.allowance(address, routeraddress);
      return allowance;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async function () {
      if (leftInput === '' || leftInput === undefined) return;
      let allowance = await checkAllowance(
        selectA.symbol,
        selectA.address,
        selectA.decimals,
        parseFloat(leftInput.toString())
      );
      if (
        parseFloat(leftInput) >
        parseFloat(ethers.utils.formatUnits(leftBalance || 0, selectA?.decimals))
      ) {
        setMessageLeft('Not enough Balance');
      }

      if (
        parseFloat(leftInput) >
        parseFloat(ethers.utils.formatUnits(allowance?.toString(), selectA.decimals))
      ) {
        setDisplayLeftAprv(true);
      } else {
        setDisplayLeftAprv(false);
      }
    })();
  }, [leftInput]);

  useEffect(() => {
    (async function () {
      console.log('Called', rightInput);

      if (rightInput === '' || rightInput === undefined) return;
      let allowance = await checkAllowance(
        selectB.symbol,
        selectB.address,
        selectB.decimals,
        parseFloat(rightInput.toString())
      );
      if (
        parseFloat(rightInput) >
        parseFloat(ethers.utils.formatUnits(rightBalance || 0, selectB?.decimals))
      ) {
        console.log('no balance right side');
        setMessageRight('Not enough Balance');
      }
      if (
        parseFloat(rightInput) >
        parseFloat(ethers.utils.formatUnits(allowance?.toString(), selectB.decimals))
      ) {
        console.log('Approved');
        setDisplayRightAprv(true);
      } else {
        console.log('Not Approved');

        setDisplayRightAprv(false);
      }
    })();
  }, [rightInput]);

  // EOF

  // Approve button functionaity
  const handleApprove = async (value, side) => {
    if (side === 'left') {
      try {
        const contract = new ethers.Contract(selectA.address, ERC20ABI, signer);
        const tx = await contract.approve(
          routeraddress,
          ethers.utils.parseUnits(value, selectA.decimals)
        );
        await tx.wait();
        await toast.promise(tx.wait(), {
          pending: 'Approving...',
          success: 'Approved',
          error: 'Can not be Approved..!',
        });
        setDisplayLeftAprv(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const contract = new ethers.Contract(selectB.address, ERC20ABI, signer);
        const tx = await contract.approve(
          routeraddress,
          ethers.utils.parseUnits(value, selectB.decimals)
        );
        await toast.promise(tx.wait(), {
          pending: 'Approving...',
          success: 'Approved',
          error: 'Can not be Approved..!',
        });
        setDisplayRightAprv(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  // EOF

  // Smart Contract calling - Liquidity Adding function
  const routeraddress = getRouterAddress();

  const handleAddLiqidity = async () => {
    if (selectA.symbol === 'Select' || selectB.symbol === 'Select') {
      toast.error('Plase select first both tokens..', { autoClose: 3000 });
    }
    if (selectA.symbol === 'AVAX') {
      AddAvaxLiquidity(selectB.address, rightInput, selectB.decimals, leftInput, selectA.decimals);
    } else if (selectB.symbol === 'AVAX') {
      AddAvaxLiquidity(selectA.address, leftInput, selectA.decimals, rightInput, selectB.decimals);
    } else {
      if (leftInput === '' || rightInput === '') return;
      else {
        try {
          setIsLoading(true);
          setCheckReserv(false);
          setDisabledBody(true);
          let Adesired = parseFloat(leftInput) - (parseFloat(leftInput) * 0.5) / 100;
          let Bdesired = parseFloat(rightInput) - (parseFloat(rightInput) * 0.5) / 100;
          // console.log('Adesired', Adesired, Bdesired);
          const contract = new ethers.Contract(routeraddress, ROUTERABI, signer);
          console.log(
            ethers.utils.parseUnits(leftInput, selectA.decimals).toString(),
            ethers.utils.parseUnits(rightInput, selectB.decimals).toString(),
            ethers.utils
              .parseUnits(Adesired.toFixed(selectA.decimals), selectA.decimals)
              .toString(),
            ethers.utils.parseUnits(Bdesired.toFixed(selectB.decimals), selectB.decimals).toString()
          );

          const tx = await contract.addLiquidity(
            selectA.address,
            selectB.address,
            ethers.utils.parseUnits(leftInput, selectA.decimals),
            ethers.utils.parseUnits(rightInput, selectB.decimals),
            ethers.utils.parseUnits(Adesired.toFixed(selectA.decimals), selectA.decimals),
            ethers.utils.parseUnits(Bdesired.toFixed(selectB.decimals), selectB.decimals),
            address,
            Math.ceil(Date.now() / 1000) + 10000
          );
          setDisabledBody(true);

          await toast.promise(tx.wait(), {
            pending: {
              render() {
                setDisabledBody(true);

                return (
                  <Toast
                    text={`Adding ${selectA.symbol} and ${selectB.symbol} in pool`}
                    hash={''}
                  />
                );
              },
            },
            success: {
              render() {
                setDisabledBody(false);

                setLeftInput('');
                setRightInput('');
                fetchTokenBalance(selectA.address).then((res) => setLeftBalance(res));
                fetchTokenBalance(selectB.address).then((res) => setRightBalance(res));

                return (
                  <Toast
                    text={`Added ${selectA.symbol} and ${selectB.symbol} in pool`}
                    hash={tx.hash}
                  />
                );
              },
            },
            error: {
              render() {
                setDisabledBody(false);

                return (
                  <Toast
                    text={`Error while adding ${selectA.symbol} and ${selectB.symbol}`}
                    hash={tx.hash}
                  />
                );
              },
            },
          });
          setCheckReserv(true);
          setIsLoading(false);
          dispatch(setRefresh(true));
        } catch (error) {
          setIsLoading(false);
          setDisabledBody(false);

          console.log(error);
          return <Toast text={`Error while adding ${selectA.symbol} and ${selectB.symbol}`} />;
        }
      }
    }
  };

  const { data } = useBalance({ address });

  const AddAvaxLiquidity = async (token_address, amount, decimal, avaxValue, avxDecimal) => {
    try {
      setDisabledBody(true);

      let desired = parseFloat(leftInput) - (parseFloat(amount) * 5) / 100;
      let Avaxdesired = parseFloat(avaxValue) - (parseFloat(avaxValue) * 5) / 100;
      console.log(
        'desired',
        token_address,
        ethers.utils.parseUnits(amount.toString(), decimal).toString(),
        ethers.utils.parseUnits(desired.toFixed(decimal).toString(), decimal).toString(),
        ethers.utils.parseUnits(Avaxdesired.toFixed(avxDecimal).toString(), avxDecimal).toString()
      );
      const contract = new ethers.Contract(routeraddress, ROUTERABI, signer);
      const tx = await contract.addLiquidityAVAX(
        token_address,
        ethers.utils.parseUnits(amount.toString(), decimal),
        ethers.utils.parseUnits(desired.toFixed(decimal).toString(), decimal),
        ethers.utils.parseUnits(Avaxdesired.toFixed(avxDecimal).toString(), avxDecimal),
        address,
        Math.ceil(Date.now() / 1000) + 10000,
        {
          value: ethers.utils.parseUnits(avaxValue.toString(), avxDecimal),
        }
      );
      await toast.promise(tx.wait(), {
        pending: {
          render() {
            setDisabledBody(true);
            return (
              <Toast text={`Adding ${selectA.symbol} and ${selectB.symbol} in pool`} hash={''} />
            );
          },
        },
        success: {
          render() {
            setDisabledBody(false);
            dispatch(setRefresh(true));
            setLeftInput('');
            setRightInput('');
            if (selectA.address === WAVAX) {
              setLeftBalance(data?.value);
            } else fetchTokenBalance(selectA.address).then((res) => setLeftBalance(res));

            if (selectB.address === WAVAX) {
              setRightBalance(data?.value);
            } else fetchTokenBalance(selectB.address).then((res) => setRightBalance(res));

            return (
              <Toast
                text={`Added ${selectA.symbol} and ${selectB.symbol} in pool`}
                hash={tx.hash}
              />
            );
          },
        },
        error: {
          render() {
            setDisabledBody(false);
            return (
              <Toast
                text={`Error while adding ${selectA.symbol} and ${selectB.symbol}`}
                hash={tx.hash}
              />
            );
          },
        },
      });
    } catch (error) {
      console.log(error);
      setDisabledBody(false);
    }
  };

  // EOF

  // Calculating B value onChange of A's input

  // const calculateValueB = async (input) => {
  //   if (selectA.address && selectB.address) {
  //     if (input === '' || input === undefined || input === null) {
  //       setRightInput(0);
  //       return;
  //     }
  //     let outputB;
  //     let decimalB = selectB.decimals;
  //     // outputB = await SwapAmount(input, reserves, selectA.decimals, selectB.decimals);
  //     // console.log('outputB', outputB);
  //     outputB = await getAmountsOut(
  //       routeraddress,
  //       ROUTERABI,
  //       provider,
  //       [selectA.address, selectB.address],
  //       ethers.utils.parseUnits(input, selectA.decimals)
  //     );
  //     setRightInput(ethers.utils.formatUnits(outputB, decimalB));
  //   }
  // };

  // const calculateValueA = async (input) => {
  //   if (selectA.address && selectB.address) {
  //     if (input === '' || input === undefined || input === null) {
  //       setRightInput(0);
  //       return;
  //     }
  //     let outputB;
  //     let decimalB = selectB.decimals;
  //     outputB = await SwapAmount(input, reserves, selectA.decimals, selectB.decimals);
  //     console.log('outputB', outputB);
  //     outputB = await getAmountsOut(
  //       routeraddress,
  //       ROUTERABI,
  //       provider,
  //       [selectA.address, selectB.address],
  //       ethers.utils.parseUnits(input, selectB.decimals)
  //     );
  //     setLeftInput(ethers.utils.formatUnits(outputB, decimalB));
  //   }
  // };

  const calculateValueB = async (input) => {
    if (input === '' || input === undefined) {
      setRightInput('0');
      return;
    }
    let inputA = input;
    // let reserveA = reserves[0];
    let decimalA = selectA.decimals;

    let outputB;
    let decimalB = selectB.decimals;
    // let reserveB = reserves[1];
    let path = [selectA.address, selectB.address];

    outputB = await getAmountsOut(
      routeraddress,
      PangolinRouterABI,
      provider,
      path,
      // ethers.utils.parseUnits(inputA.toString(), decimalA)
      ethers.utils.parseUnits(parseFloat(inputA.toString()).toFixed(decimalA).toString(), decimalA)
    );

    setRightInput(ethers.utils.formatUnits(outputB, decimalB));
    // let parsedA = ethers.utils.parseUnits(inputA, decimalA);

    // let nRa, nRb;
    // if (decimalA >= decimalB) {
    //   nRa = reserveA;
    //   nRb = ethers.utils.parseUnits(reserveB.toString(), decimalA - decimalB);
    // } else {
    //   nRa = ethers.utils.parseUnits(reserveB.toString(), decimalB - decimalA);
    //   nRb = reserveA;
    // }

    // let bigparsedA = BN(input.toString());
    // let bigNrA = BN(nRa.toString());
    // let bigNrB = BN(nRb.toString());

    // console.log('variables', input.toString(), nRa.toString(), nRb.toString());
    // outputB = bigparsedA.multipliedBy(bigNrB.div(bigNrA));
    // setRightInput(outputB.toFixed(decimalB));

    // outputB = parsedA.mul(nRb).div(nRa)
    // console.log("reserveA_reserveB", ethers.utils.formatUnits(outputB, decimalB).toString())
    // checkAllowance(
    //   selectB.symbol,
    //   selectB.address,
    //   decimalB,
    //   ethers.utils.formatUnits(outputB, decimalB)
    // );
  };

  const calculateValueA = async (input) => {
    if (input === '' || input === undefined) {
      setLeftInput('0');
      return;
    }
    let inputA = input;
    // let reserveA = reserves[0];
    let decimalA = selectA.decimals;
    let path = [selectB.address, selectA.address];

    let outputB;
    let decimalB = selectB.decimals;
    // let reserveB = reserves[1];
    outputB = await getAmountsOut(
      routeraddress,
      PangolinRouterABI,
      provider,
      path,
      ethers.utils.parseUnits(inputA, decimalB)
    );

    setLeftInput(ethers.utils.formatUnits(outputB, decimalA));
    // let parsedA = ethers.utils.parseUnits(inputA, decimalA);

    // let nRa, nRb;
    // if (decimalA >= decimalB) {
    //   nRa = reserveA;
    //   nRb = ethers.utils.parseUnits(reserveB.toString(), decimalA - decimalB);
    // } else {
    //   nRa = ethers.utils.parseUnits(reserveB.toString(), decimalB - decimalA);
    //   nRb = reserveA;
    // }

    // let bigparsedA = BN(inputA.toString());
    // let bigNrA = BN(nRa.toString());
    // let bigNrB = BN(nRb.toString());

    // outputB = bigparsedA.multipliedBy(bigNrA.div(bigNrB));
    // console.log('variables', decimalA);
    // setLeftInput(outputB.toFixed(decimalA));
  };

  // EOF

  const [display_left, setDisplayLeft] = useState(false);
  const [display_right, setDisplayRight] = useState(false);
  const [message_left, setMessageLeft] = useState('');
  const [message_right, setMessageRight] = useState('');

  const handleCalculatePercentage = (side, type) => {
    let balance;
    let newValue;
    if (side === 'left') {
      balance = parseFloat(ethers.utils.formatUnits(leftBalance || 0, selectA?.decimals));
    } else {
      balance = parseFloat(ethers.utils.formatUnits(rightBalance || 0, selectB?.decimals));
    }
    console.log('balance', balance);
    switch (type) {
      case '1':
        newValue = balance * (25 / 100);
        break;
      case '2':
        newValue = balance * (50 / 100);
        break;
      case '3':
        newValue = balance * (75 / 100);
        break;
      case '4':
        newValue = balance;
        break;
      default:
        return;
    }
    if (side === 'left') {
      setLeftInput(newValue.toFixed(selectA.decimals).toString());

      // setLeftInput(newValue.toString());
      calculateValueB(newValue.toString());
    } else {
      setRightInput(newValue.toFixed(selectB.decimals).toString());
      // setRightInput(newValue.toString());
      calculateValueA(newValue.toString());
    }
  };

  return (
    <>
      <div
        className={
          disabledBody
            ? 'fixed inset-0 z-10 block h-screen w-screen items-center justify-center  bg-zinc-900   bg-overlay-bg bg-opacity-20  backdrop-blur-sm'
            : 'hidden '
        }
      >
        <div className=" flex h-full w-full items-center justify-center bg-transparent">
          <img src={ColourfulLoadingIcon} className="colorful_loading_icon block" alt='' />
        </div>
      </div>

      <section className="p-5 pt-0">
        <div className="flex flex-col justify-center">
          <div className="liquidity_inputs relative my-3 flex flex-row justify-center gap-10 py-2">
            <div className="flex w-[400px] flex-col gap-5">
              <div className="my-2">
                <div class="flex items-center justify-start gap-3 opacity-70">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCalculatePercentage('left', '1');
                    }}
                  >
                    <span className="rounded bg-[#1b1b1b] py-1 px-3">25%</span>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCalculatePercentage('left', '2');
                    }}
                  >
                    <span className="rounded bg-[#1b1b1b] py-1 px-3">50%</span>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCalculatePercentage('left', '3');
                    }}
                  >
                    <span className="rounded bg-[#1b1b1b] py-1 px-3">75%</span>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCalculatePercentage('left', '4');
                    }}
                  >
                    <span className="rounded bg-[#1b1b1b] py-1 px-3">MAX</span>
                  </div>
                </div>
              </div>
              <div
                className="flex cursor-pointer items-center justify-between rounded-lg border-[2px] border-gray-500 py-2 px-3 duration-300 ease-linear hover:text-primary-sunny-dark"
                onClick={() => {
                  if (address) {
                    dispatch(setLabel('Select_A'));
                    dispatch(setDisplayCurrencies(true));
                  } else {
                    toast.error('Please Connect Your wallet', { autoClose: 3000 });
                  }
                }}
              >
                <div className="flex flex-row items-center gap-2">
                  <div>
                    {leftVal.logoURI && (
                      <img
                        src={leftVal.logoURI !== '' ? leftVal.logoURI : Defalut_Logo}
                        alt="#currency"
                        className="h-8 w-12"
                      />
                    )}
                  </div>
                  <button className="currency_name flex w-full items-start font-semibold">
                    <span>{leftVal ? leftVal.symbol : 'Select'}</span>
                  </button>
                </div>
                <div className="dropdown_icon">
                  {leftVal && <i className="bi bi-caret-down-fill"></i>}
                </div>
              </div>
              <div className="rounded-lg border-[2px] border-gray-500 py-2 px-3">
                <input
                  type="text"
                  className="bg-transparent py-1 text-white opacity-70"
                  placeholder="0.000"
                  value={leftInput}
                  onChange={(e) => {
                    if (isNaN(e.target.value)) {
                      // setLeftInput('0');
                      return;
                    }
                    setLeftInput(e.target.value.trim(' '));
                    if (rightDisabled) calculateValueB(e.target.value);
                    if (
                      parseFloat(e.target.value) >
                      parseFloat(ethers.utils.formatUnits(leftBalance || 0, selectA?.decimals))
                    ) {
                      setDisplayLeft(true);
                      setMessageLeft('Not enough Balance');
                    } else {
                      setDisplayLeft(false);
                    }
                    // e.target.value === '' ? setInfoData(false) : setInfoData(true);
                  }}
                  disabled={address && isPair ? false : true}
                />
                <div className="text-xs opacity-75">
                  Balance : &nbsp;
                  <span className="tracking-wider">
                    {leftVal
                      ? ethers.utils.formatUnits(leftBalance || 0, selectA?.decimals)
                      : '0.0'}
                  </span>
                </div>
              </div>
              <div className={`${display_left ? 'block' : 'hidden'} text-red-500`}>
                {message_left}
              </div>
              {console.log(displayleftAprv, 'left approve')}
              <div className={`${displayleftAprv ? 'block' : 'hidden'}`}>
                <button
                  // className="w-full rounded-md py-3 px-5 text-lg font-semibold uppercase tracking-wider text-white dark:bg-primary-sunny-blurSemiDarkBg"
                  className="w-full  cursor-pointer rounded-md border  border-primary-sunny-main py-3 px-5 text-lg  uppercase  tracking-wider  duration-300 ease-in-out  hover:bg-primary-sunny-dark "
                  onClick={() => {
                    handleApprove(leftInput, 'left');
                  }}
                >
                  Approve <span className="px-2">{selectA.symbol}</span>
                </button>
              </div>
            </div>
            <div className="verticle_line mx-5 w-[2px] bg-[#2d2d2d]"></div>
            <div className="flex w-[400px] flex-col justify-end gap-5">
              <div className="my-2">
                <div class="flex items-center justify-start gap-3 opacity-70">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCalculatePercentage('right', '1');
                    }}
                  >
                    <span className="rounded bg-[#1b1b1b] py-1 px-3">25%</span>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCalculatePercentage('right', '2');
                    }}
                  >
                    <span className="rounded bg-[#1b1b1b] py-1 px-3">50%</span>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCalculatePercentage('right', '3');
                    }}
                  >
                    <span className="rounded bg-[#1b1b1b] py-1 px-3">75%</span>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleCalculatePercentage('right', '4');
                    }}
                  >
                    <span className="rounded bg-[#1b1b1b] py-1 px-3">MAX</span>
                  </div>
                </div>
              </div>
              <div
                className="flex cursor-pointer items-center justify-between rounded-lg border-[2px] border-gray-500 py-2 px-3 duration-300 ease-linear hover:text-primary-sunny-dark"
                onClick={() => {
                  if (address) {
                    dispatch(setLabel('Select_B'));
                    dispatch(setDisplayCurrencies(true));
                  } else {
                    toast.error('Please Connect Your wallet', { autoClose: 3000 });
                  }
                }}
              >
                <div className="flex flex-row items-center gap-2">
                  <div>
                    {rightVal.logoURI && (
                      <img
                        src={rightVal.logoURI !== '' ? rightVal.logoURI : Defalut_Logo}
                        alt="#currency"
                        className="h-8 w-12"
                      />
                    )}
                  </div>
                  <button className="currency_name flex w-full items-start font-semibold">
                    <span>{rightVal ? rightVal.symbol : 'Select'}</span>
                  </button>
                </div>
                <div className="dropdown_icon">
                  <i className="bi bi-caret-down-fill"></i>
                </div>
              </div>
              <div className="rounded-lg border-[2px] border-gray-500 py-2 px-3">
                <input
                  type="text"
                  className="bg-transparent py-1 text-white opacity-70"
                  placeholder="0.000"
                  value={rightInput}
                  onChange={(e) => {
                    if (isNaN(e.target.value)) {
                      // setLeftInput('0');
                      return;
                    }
                    setRightInput(e.target.value.trim());
                    calculateValueA(e.target.value);
                    checkAllowance(
                      selectB.symbol,
                      selectB.address,
                      selectB.decimals,
                      e.target.value
                    );
                    if (
                      parseFloat(e.target.value) >
                      parseFloat(ethers.utils.formatUnits(rightBalance || 0, selectB?.decimals))
                    ) {
                      setDisplayRight(true);
                      setMessageRight('Not enough Balance');
                    } else {
                      setDisplayRight(false);
                    }
                  }}
                  disabled={address && isPair ? false : true}
                />
                <div className="text-xs opacity-75">
                  Balance :{' '}
                  <span className="tracking-wider">
                    {rightVal
                      ? ethers.utils.formatUnits(rightBalance || 0, selectB?.decimals)
                      : '0.0'}
                  </span>
                </div>
              </div>
              <div className={`${display_right ? 'block' : 'hidden'} text-red-500 `}>
                {message_right}
              </div>
              <div className={`${displayrightAprv ? 'block' : 'hidden'}`}>
                <button
                  className="w-full  cursor-pointer rounded-md border  border-primary-sunny-main py-3 px-5 text-lg  uppercase  tracking-wider  duration-300 ease-in-out  hover:bg-primary-sunny-dark"
                  onClick={() => {
                    handleApprove(rightInput, 'right');
                  }}
                >
                  Approve <span className="px-2">{selectB.symbol}</span>
                </button>
              </div>
            </div>
          </div>
          {!address ? (
            <>
              {/* // <div className="my-5 flex items-center justify-center  ">
            //   <div className="w-max rounded-md p-2 dark:bg-[#26253a]">
            //     Please connect your wallet
            //   </div>
            // </div> */}
              <div className="swap_box_alert  self-center pb-5 ">
                <div className="w-full ">
                  <span className=" text-center text-sm">Please connect your wallet</span>
                </div>
              </div>
              <div className="connect_wallet_btn_background  w-max self-center">
                <CustomeConnectButton shouldDisplay={true} />
              </div>
            </>
          ) : infoData ? (
            <div className="my-5 flex items-center justify-center rounded-md py-2 px-10 dark:bg-[#26253a]">
              <div className="w-full">
                <div className="my-3 flex flex-row justify-between gap-2 text-xs opacity-70 sm:text-base">
                  <div>
                    {selectA.symbol} - {selectB.symbol}
                  </div>
                  <div>1.00</div>
                </div>
                <div className="my-3 flex flex-row justify-between gap-2 text-xs opacity-70 sm:text-base">
                  <div>LP from Tx</div>
                  <div>1.00 LP</div>
                </div>
                <div className="my-3 flex flex-row justify-between gap-2 text-xs opacity-70 sm:text-base">
                  <div>LP after Tx</div>
                  <div>1.00 LP</div>
                </div>
                <div className="my-3 flex flex-row justify-between gap-2 text-xs opacity-70 sm:text-base">
                  <div>Pool Share after Tx</div>
                  <div>1.00 %</div>
                </div>
                <div className="my-3 flex flex-row justify-between gap-2 text-xs opacity-70 sm:text-base">
                  <div>Gas Fee</div>
                  <div>1.00 BTF</div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex items-center justify-center">
            {address &&
              ((rightVal && rightVal.symbol.toLowerCase() === 'select') ||
              (leftVal && leftVal.symbol.toLowerCase() === 'select') ? (
                <div className="swap_box_alert  self-center pb-5 ">
                  <div className="w-full ">
                    <span className=" text-center text-sm">Please Select Both of the Tokens</span>
                  </div>
                </div>
              ) : isPair ? (
                // <button
                //   // className="w-max rounded-md py-3 px-5 text-lg font-semibold uppercase tracking-wider text-white dark:bg-primary-sunny-blurSemiDarkBg"
                //   className="flex w-max flex-row items-center gap-2 rounded-lg border border-primary-sunny-main py-2 px-5 text-base uppercase tracking-wider duration-300 ease-in-out hover:bg-primary-sunny-dark disabled:cursor-not-allowed"
                //   onClick={() => {
                //     handleAddLiqidity();
                //   }}
                //   disabled={displayleftAprv || displayrightAprv || !address}
                // >
                //   <TailSpin
                //     height="20"
                //     width="20"
                //     color="#fff"
                //     ariaLabel="tail-spin-loading"
                //     radius="1"
                //     wrapperStyle={{}}
                //     wrapperClass=""
                //     visible={true}
                //   />
                //   Add Liquidity
                // </button>

                <Button
                  isLoading={isLoading}
                  text={'Add Liquidity'}
                  action={handleAddLiqidity}
                  disabled={displayleftAprv || displayrightAprv || !address || isLoading}
                />
              ) : (
                // <button
                //   className="w-max  rounded-lg border border-primary-sunny-main py-2 px-5 text-base uppercase tracking-wider duration-300 ease-in-out hover:bg-primary-sunny-dark"
                //   onClick={() => {
                //     handleCreatePaire();
                //   }}
                // >
                //   Create Pair
                // </button>
                <Button
                  isLoading={isLoading}
                  text={'Create Pair'}
                  action={handleCreatePaire}
                  disabled={isLoading}
                />
              ))}
          </div>
        </div>
      </section>
      <CurrencySuggestionsModal open={displayCurrencies} label={label} />
    </>
  );
}
