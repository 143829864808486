export const pairs = [
  {
    chainId: 43114,
    token0: {
      address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
      name: 'Avalanche',
      decimals: 18,
      symbol: 'AVAX',
      logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
      isNative: true,
    },
    token1: {
      address: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
      name: 'USDT',
      decimals: 6,
      symbol: 'USDT',
      logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
      isNative: false,
    },
    tradingView: 'AVAXUSDT',
  },
  {
    chainId: 43114,
    token0: {
      address: '0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
      name: 'Wrapped BTC',
      decimals: 8,
      symbol: 'WBTC',
      logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png',
      isNative: false,
    },
    token1: {
      address: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
      name: 'USDT',
      decimals: 6,
      symbol: 'USDT',
      logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
      isNative: false,
    },
    tradingView: 'WBTCUSDT',
  },
  {
    chainId: 43114,
    token0: {
      address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
      name: 'Wrapped ETH',
      decimals: 18,
      symbol: 'WETH',
      logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png',
      isNative: false,
    },
    token1: {
      address: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
      name: 'USDT',
      decimals: 6,
      symbol: 'USDT',
      logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png',
      isNative: false,
    },
    tradingView: 'WETHUSDT',
  },
  // {
  //   chainId: 43114,
  //   token0: {
  //     address: '0x442F7f22b1EE2c842bEAFf52880d4573E9201158',
  //     name: 'Wrapped BNB',
  //     decimals: 18,
  //     symbol: 'WBNB',
  //     logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png',
  //     isNative: false,
  //   },
  //   token1: {
  //     address: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
  //     name: 'USDT',
  //     decimals: 6,
  //     symbol: 'USDT',
  //     logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
  //     isNative: false,
  //   },
  //   tradingView: 'WBNBUSDT_16B9A8',
  // },
  {
    chainId: 43114,
    token0: {
      address: '0xf2f13f0B7008ab2FA4A2418F4ccC3684E49D20Eb',
      name: 'Wrapped Matic',
      decimals: 18,
      symbol: 'WMATIC',
      logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png',
      isNative: false,
    },
    token1: {
      address: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
      name: 'USDT',
      decimals: 6,
      symbol: 'USDT',
      logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
      isNative: false,
    },
    tradingView: 'WMATICUSDT',
  },
];
