/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useRefresh from '../useRefresh';
import { getTokenContract } from '../../utils/contractHelpers';
import { useAccount } from 'wagmi';
import { fetchOpenOrders, fetchOrderHistory } from '../../actions/Order';

export const useOrderHistory = () => {
  const [histories, setHistories] = useState([]);
  const { fastRefresh } = useRefresh();
  async function fetchHistories() {
    try {
      const result = await fetchOrderHistory();
      setHistories(result.data.msg ? [] : result.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    fetchHistories();
  }, [fastRefresh]);
  return histories;
};

export const useOpenOrders = () => {
  const [orders, setOrders] = useState([]);
  const { fastRefresh } = useRefresh();
  async function fetchHistories() {
    try {
      const result = await fetchOpenOrders();
      setOrders(result.data.msg ? [] : result.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    fetchHistories();
  }, [fastRefresh]);
  return orders;
};
