import React, { useEffect, useState } from 'react';
import TableContent from './layout/TableContent';
import { pool_columns } from './Helper/ColumnHelper';
import axios from 'axios';
import { useAccount, useNetwork, useProvider } from 'wagmi';
import { BigNumber, ethers } from 'ethers';
import PAIRABI from '../ABI/PangolinPair.json';
import { useDispatch, useSelector } from 'react-redux';
import CustomTokenSlice, { setOpen, setRefresh } from '../store/CustomTokenSlice';
import CustomeTokenModal from './layout/CustomeTokenModal';
import pairDummyTokens from '../utils/dummyPairToken';
import { activeNetwork, networks } from '../config/networkConfig';
import { toast } from 'react-toastify';

export default function WithdrawTable() {
  const { address } = useAccount();
  // const address = '0x0852655049f02f3eee0efd13c812f33f5751e227';
  const open = useSelector((state) => state.token.open);
  const refresh = useSelector((state) => state.token.refresh);
  const dispatch = useDispatch();
  // const address = '0x618711478689a68aa547480c2d9ba72fbfc31774';
  const provider = useProvider();
  let [tokenData, setTokenData] = useState([]);

  const { chain } = useNetwork();

  useEffect(() => {
    if (address) {
      fetchTokenData();
    }
  }, [address, open, refresh, chain]);

  const fetchTokenData = async () => {
    try {
      // const resp = await axios.post(
      //   "https://api.thegraph.com/subgraphs/name/pangolindex/exchange",
      //   {
      //       "query":`{\n  pairs(\n    where: {liquidityPositions_: {user: \"${address}\"}}\n  ) {\n    id\n    token0 {\n      symbol\n      id\n    }\n    token1 {\n      symbol\n      id\n    }\n    totalSupply\n    reserveUSD\n  }\n}`,"variables":null,"extensions":{"headers":null}
      //   }
      //   )

      // let dataArray = resp.data.data.pairs
      let data = localStorage.getItem('pairToken');
      let dataArray = JSON.parse(data);
      dataArray = await Promise.all(
        dataArray.map(async (data, i) => {
          // if (data.pairAddress === '') return;
          let userbalance, totalSupply;
          try {
            const contract = new ethers.Contract(data.pairAddress, PAIRABI, provider);
            // debugger;
            userbalance = await contract.balanceOf(address);
            totalSupply = await contract.totalSupply();
          } catch (error) {
            console.log(error);
          }

          let pool = `${data.token0.symbol} - ${data.token1.symbol}`;
          let lpBal = parseFloat(ethers.utils.formatUnits(userbalance?.toString(), 18));
          let poolShare = (lpBal / parseFloat(totalSupply)) * 100;

          // pairDummyTokens.pairTokens[i].lpBal = lpBal;
          // pairDummyTokens.pairTokens[i].poolShare = poolShare;
          return {
            ...data,
            pool,
            lpBal,
            poolShare,
          };
        })
      );
      setTokenData(dataArray);
      dispatch(setRefresh(false));
    } catch (error) {
      setTokenData([]);
      if (networks[activeNetwork].network !== chain.network) {
        toast.error(
          `Please connect to ${networks[activeNetwork].network} network for fetching your pool..`
        );
      }
      console.log('tokenData', tokenData);
      console.log(error);
    }
  };

  return (
    <>
      <header className="mb-5 flex items-center justify-between border-b-[1px] border-gray-600 p-5">
        <section className="flex-auto font-mulish tracking-wider text-white">
          Liquidity Positions
        </section>
        <section>
          <div className=" hidden flex-col gap-8 sm:flex-row md:flex-row">
            <div className="flex flex-col items-end  tracking-wider">
              <div className="text-white opacity-70">Total Withdrawable Value</div>
              <div className="mt-1 text-lg font-medium tracking-wide ">
                <span className="text-primary-sunny-main"> {7.45745154} </span>
                USDC
              </div>
            </div>
          </div>
          <div
            className="text-s my-3 cursor-pointer rounded-lg border border-primary-sunny-main py-2 px-4 text-right  tracking-wider  duration-300 ease-in-out  hover:bg-primary-sunny-dark "
            onClick={() => {
              dispatch(setOpen(true));
            }}
          >
            Import Pair
          </div>
        </section>
      </header>
      <section>
        <div className="pool_table overflow-x-auto duration-200 ease-in-out">
          {tokenData.length !== 0 ? (
            <TableContent data={tokenData} columns={pool_columns} />
          ) : (
            <div className="select-none py-4 text-center text-2xl text-gray-500 opacity-50">
              {' '}
              No TokenData found{' '}
            </div>
          )}
        </div>
      </section>
      <CustomeTokenModal open={open} />
    </>
  );
}
