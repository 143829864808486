import React, { useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { setOpen } from '../../store/CustomTokenSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Closee } from '../../assets/close.svg';
import Defalut_Logo from '../../assets/Defalut_token_logo.png';
import CurrencySuggestionsModal from './currenciesSuggestionsModal';
import { setDisplayCurrencies, setLabel } from '../../store/CurrenciesSuggestionsSlice';
import { getAddresses } from '../../utils/addressHelpers';
import FACTORYABI from '../../ABI/FujiFactoryABI.json';
import { useProvider } from 'wagmi';
import { getPairs } from '../Helper/SwappingHelper.js';
import Button from '../shared/Button';
import { toast } from 'react-toastify';

export default function CustomeTokenModal({ open }) {
  const provider = useProvider();
  const dispatch = useDispatch();
  const label = useSelector((state) => state.currenciesSuggestions.label);
  const displayCurrencies = useSelector((state) => state.currenciesSuggestions.displayCurrencies);

  let tokenA = useSelector((state) => state.currenciesSuggestions.pairTokenA);
  let tokenB = useSelector((state) => state.currenciesSuggestions.pairTokenB);

  const [upValue, setUpValue] = useState(tokenA);
  const [downValue, setDownValue] = useState(tokenB);

  useEffect(() => {
    setUpValue(tokenA);
    setDownValue(tokenB);
  }, [tokenA.symbol, tokenB.symbol]);

  // Checking for the pair

  const addresses = getAddresses();
  const [disabledButton, setDisabledButton] = useState(true);
  const [feching, setFetching] = useState(false);
  const [pairAddress, setPairAddress] = useState('');

  useEffect(() => {
    if (upValue.address && downValue.address) {
      setFetching(true);
      try {
        (async function () {
          let factoryAddress = addresses[0].factory;
          let pairAddress = await getPairs(
            factoryAddress,
            FACTORYABI,
            provider,
            upValue.address,
            downValue.address
          );
          console.log('pairAddress', pairAddress);
          if (pairAddress === '0x0000000000000000000000000000000000000000') {
            setDisabledButton(true);
          } else {
            setPairAddress(pairAddress);
            setDisabledButton(false);
          }
          setFetching(false);
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, [upValue, downValue]);

  // EOF

  // Storing The data into local Storage

  const storeIntoLocalStorage = () => {
    console.log('valuess', upValue, downValue);
    if (upValue.symbol === 'Select' || downValue.symbol === 'Select') {
      toast.error('Please select token first..', { autoClose: 3000 });
      return;
    }
    let token0 = upValue;
    let token1 = downValue;
    let tokenData = localStorage.getItem('pairToken');
    let parsedData = JSON.parse(tokenData);
    console.log('parsedData', parsedData);
    if (parsedData === undefined || parsedData === null) {
      localStorage.setItem(
        'pairToken',
        JSON.stringify([
          {
            token0,
            token1,
            pairAddress,
          },
        ])
      );
    } else {
      if (parsedData.find(({ pairAddress: pa }) => pa === pairAddress) === undefined) {
        parsedData.push({
          token0,
          token1,
          pairAddress,
        });
        localStorage.setItem('pairToken', JSON.stringify(parsedData));
      } else {
        toast.error('Pair already exist..', { autoClose: 3000 });
      }
    }

    dispatch(setOpen(false));
  };

  // EOF

  return (
    <>
      <div>
        <Dialog.Root open={open}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed top-0 left-0 right-0 h-screen w-screen bg-overlay-bg backdrop-blur-sm data-[state=open]:animate-overlayShow" />
            <Dialog.Content className="animate_div fixed top-1/2 left-1/2 w-4/5 -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white p-4 drop-shadow-[0_4px_40px_rgba(247,185,62,0.25)] focus-visible:outline-none dark:bg-[#1D202C] sm:w-3/5 lg:w-2/5">
              <div className="flex items-end justify-end px-5 text-right">
                <Dialog.Close
                  asChild
                  onClick={() => {
                    dispatch(setOpen(false));
                  }}
                  className="cursor-pointer"
                >
                  <Closee />
                </Dialog.Close>
              </div>
              <Dialog.Content
                asChild
                className="flex flex-col items-center justify-center focus-visible:outline-none"
              >
                <>
                  <div className="flex flex-col items-center justify-center p-5">
                    <div
                      className="flex w-full cursor-pointer items-center justify-between rounded-lg border-[2px] border-gray-500 py-2 px-3 duration-300 ease-linear hover:text-primary-sunny-dark"
                      onClick={() => {
                        dispatch(setLabel('Import_Token_A'));
                        dispatch(setDisplayCurrencies(true));
                      }}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <div>
                          {upValue.logoURI && (
                            <img
                              src={upValue.logoURI !== '' ? upValue.logoURI : Defalut_Logo}
                              alt="#currency"
                              className="h-8 w-12"
                            />
                          )}
                        </div>
                        <button className="currency_name flex w-full items-start font-semibold">
                          <span>{upValue ? upValue.symbol : 'select'}</span>
                        </button>
                      </div>
                      <div className="dropdown_icon">
                        <i className="bi bi-caret-down-fill"></i>
                      </div>
                    </div>
                    <div className="text-center text-4xl">+</div>
                    <div
                      className="flex w-full cursor-pointer items-center justify-between rounded-lg border-[2px] border-gray-500 py-2 px-3 duration-300 ease-linear hover:text-primary-sunny-dark"
                      onClick={() => {
                        dispatch(setLabel('Import_Token_B'));
                        dispatch(setDisplayCurrencies(true));
                      }}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <div>
                          {downValue.logoURI && (
                            <img
                              src={downValue.logoURI !== '' ? downValue.logoURI : Defalut_Logo}
                              alt="#currency"
                              className="h-8 w-12"
                            />
                          )}
                        </div>
                        <button className="currency_name flex w-full items-start font-semibold">
                          <span>{downValue ? downValue.symbol : 'select'}</span>
                        </button>
                      </div>
                      <div className="dropdown_icon">
                        <i className="bi bi-caret-down-fill"></i>
                      </div>
                    </div>
                    <div className="mt-5">
                      {/* <button
                        className={`w-max rounded-md py-3 px-5 text-lg font-semibold uppercase tracking-wider text-white disabled:bg-gray-600 dark:bg-primary-sunny-blurSemiDarkBg`}
                        disabled={disabledButton}
                        onClick={() => {
                          storeIntoLocalStorage();
                        }}
                      >
                        {feching ? 'Loading...' : disabledButton ? 'No Pair Found' : 'Add Token'}
                      </button> */}
                      <Button
                        isLoading={feching}
                        text={disabledButton ? 'No Pair Found' : 'Add Token'}
                        action={storeIntoLocalStorage}
                        disabled={disabledButton || feching}
                      />
                    </div>
                  </div>
                </>
              </Dialog.Content>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
      <CurrencySuggestionsModal open={displayCurrencies} label={label} />
    </>
  );
}
