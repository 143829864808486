import React, { useEffect, useMemo, useState } from 'react';
import Axios from 'axios'; //axios for fetching API

//importing external UI components
import * as Dialog from '@radix-ui/react-dialog';
//importing logos and icons
import BTF_logo from '../../assets/logo.png';
import AVAX_logo from '../../assets/avax_logo.svg';
import dummyTokens from '../../utils/dummyTokens';
import pairDummyTokens from '../../utils/dummyPairToken';
import MissingImageIcon from '../../assets/icons/Missing.svg';
import CoinGekoIds from '../../utils/CoinGekoIds.json';

//imporing Hooks and Components for state management
import { ReactComponent as Closee } from '../../assets/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDisplayCurrencies,
  setConvertingFrom,
  setConvertingTo,
  setSelectA,
  setSelectB,
  setPairTokenA,
  setPairTokenB,
} from '../../store/CurrenciesSuggestionsSlice';
import { activeNetwork, networks } from '../../config/networkConfig';
import { multicallv2 } from '../../utils/multicall';
import ERC20ABI from '../../ABI/ERC20ABI.json';
import { setWithDrawData } from '../../store/DropdownMenuSlice';
import PAIRABI from '../../ABI/PangolinPair.json';
import { useAccount, useProvider } from 'wagmi';
import { ethers } from 'ethers';

export default function CurrencySuggestionsModal({ open, label }) {
  const displayCurrencies = useSelector((state) => state.currenciesSuggestions.displayCurrencies);
  const fromTokenAddress = useSelector(
    (state) => state.currenciesSuggestions.converting_from.address
  );
  const toTokenAddress = useSelector((state) => state.currenciesSuggestions.converting_to.address);

  const selectAaddress = useSelector((state) => state.currenciesSuggestions.select_A.address);
  const selectBaddress = useSelector((state) => state.currenciesSuggestions.select_B.address);

  const pairTokenAaddress = useSelector((state) => state.currenciesSuggestions.pairTokenA.address);
  const pairTokenBaddress = useSelector((state) => state.currenciesSuggestions.pairTokenB.address);

  let [currencies, setCurrencies] = useState([]);
  let [currencies_default, setCurrenciesDefault] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const provider = useProvider();
  const { address: userAddress } = useAccount();

  useEffect(() => {
    setSearchInput();

    if (label === 'Token Pair') {
      setCurrencies(
        pairDummyTokens.pairTokens.filter(
          ({ chainId }) => chainId === networks[activeNetwork].chainId
        )
      );
      setCurrenciesDefault(
        pairDummyTokens.pairTokens.filter(
          ({ chainId }) => chainId === networks[activeNetwork].chainId
        )
      );

      return;
    }
    let localStorageToken = localStorage.getItem('fetchToken');
    suggestedCurrencies.push(localStorageToken);
    // console.log('localStorageToken', localStorageToken);
    let tokens = dummyTokens.tokens;

    setCurrencies(tokens.filter(({ chainId }) => chainId === networks[activeNetwork].chainId));
    setCurrenciesDefault(
      tokens.filter(({ chainId }) => chainId === networks[activeNetwork].chainId)
    );

    // Axios.get(
    //   'https://raw.githubusercontent.com/pangolindex/tokenlists/main/pangolin.tokenlist.json'
    // )
    //   .then((response) => {
    //     setCurrencies(
    //       response.data.tokens.filter(({ chainId }) => chainId === networks[activeNetwork].chainId)
    //     );
    //     setCurrenciesDefault(
    //       response.data.tokens.filter(({ chainId }) => chainId === networks[activeNetwork].chainId)
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     // console.log('Dummy Tokens', dummyTokens);
    //     setCurrencies(
    //       dummyTokens.tokens.filter(({ chainId }) => chainId === networks[activeNetwork].chainId)
    //     );
    //     setCurrenciesDefault(
    //       dummyTokens.tokens.filter(({ chainId }) => chainId === networks[activeNetwork].chainId)
    //     );
    //   });
  }, [displayCurrencies]);

  const suggestedCurrencies = [
    {
      logoURI: BTF_logo,
      symbol: 'BTF',
      address: '0x405eb25c5edbFd227a72D69e7eD9dB8860637F00',
      decimals: 18,
      name: 'BTF Token',
      chainId: 43114,
    },
    {
      logoURI: AVAX_logo,
      symbol: 'AVAX',
      address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      decimals: 18,
      name: 'Avax Token',
      chainId: 43114,
    },
  ];

  // console.log('suggestedCurrencies', suggestedCurrencies);

  function selectCurrency(index) {
    switch (label) {
      case 'from':
        if (searchFilter[index].address === toTokenAddress) {
          dispatch(
            setConvertingTo({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setConvertingFrom(searchFilter[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'to':
        if (searchFilter[index].address === fromTokenAddress) {
          dispatch(
            setConvertingFrom({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setConvertingTo(searchFilter[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Select_A':
        if (searchFilter[index].address === selectBaddress) {
          dispatch(
            setSelectB({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setSelectA(searchFilter[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Select_B':
        if (searchFilter[index].address === selectAaddress) {
          dispatch(
            setSelectA({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setSelectB(searchFilter[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Import_Token_A':
        dispatch(setPairTokenA(currencies[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Import_Token_B':
        if (searchFilter[index].address === pairTokenAaddress) {
          dispatch(
            setPairTokenA({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setPairTokenB(searchFilter[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Token Pair':
        dispatch(setWithDrawData(searchFilter[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      default:
        return;
    }
  }

  function selectSuggested(index) {
    switch (label) {
      case 'from':
        if (suggestedCurrencies[index].address === toTokenAddress) {
          dispatch(
            setConvertingTo({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setConvertingFrom(suggestedCurrencies[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'to':
        if (suggestedCurrencies[index].address === fromTokenAddress) {
          dispatch(
            setConvertingFrom({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setConvertingTo(suggestedCurrencies[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Select_A':
        if (suggestedCurrencies[index].address === selectBaddress) {
          dispatch(
            setSelectB({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setSelectA(suggestedCurrencies[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Select_B':
        if (suggestedCurrencies[index].address === selectAaddress) {
          dispatch(
            setSelectA({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setSelectB(suggestedCurrencies[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Import_Token_A':
        if (suggestedCurrencies[index].address === pairTokenBaddress) {
          dispatch(
            setPairTokenB({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setPairTokenA(suggestedCurrencies[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      case 'Import_Token_B':
        if (suggestedCurrencies[index].address === pairTokenAaddress) {
          dispatch(
            setPairTokenA({
              logoURI: '',
              symbol: 'Select',
            })
          );
        }
        dispatch(setPairTokenB(suggestedCurrencies[index]));
        dispatch(setDisplayCurrencies(false));
        break;
      default:
        return;
    }
  }

  const tokenContractSearch = async (tokenaddress) => {
    try {
      let tokenDataCalls = [];
      tokenDataCalls.push(
        ...['name', 'symbol', 'decimals'].map((method) => ({
          address: tokenaddress,
          name: method,
        }))
      );

      const tokenContractData = await multicallv2(ERC20ABI, tokenDataCalls);
      return tokenContractData;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (e) => {
    setSearchInput(e.target.value);
    if (e.target.value.length === 42) {
      if (e.target.value.startsWith('0x') && label !== 'Token Pair') {
        try {
          let tokenaddress = e.target.value;
          let tokenContractData = await tokenContractSearch(tokenaddress);
          localStorage.setItem(
            'fetchToken',
            JSON.stringify({
              logoURI: BTF_logo,
              symbol: tokenContractData[1][0],
              name: tokenContractData[0][0],
              decimals: tokenContractData[2][0],
              chainId: 43113,
              address: tokenaddress,
            })
          );
          setCurrencies([
            {
              logoURI: BTF_logo,
              symbol: tokenContractData[1][0],
              name: tokenContractData[0][0],
              decimals: tokenContractData[2][0],
              chainId: 43113,
              address: tokenaddress,
            },
          ]);
        } catch (error) {
          setCurrencies([]);
          console.log(error);
        }
      } else if (label === 'Token Pair' && e.target.value.startsWith('0x')) {
        let tokenaddress = e.target.value;
        try {
          let tokenDataCalls = [];
          tokenDataCalls.push(
            ...['totalSupply', 'decimals', 'token0', 'token1'].map((method) => ({
              address: tokenaddress,
              name: method,
            }))
          );
          tokenDataCalls.push(
            ...['balanceOf'].map((method) => ({
              address: tokenaddress,
              name: method,
              params: [userAddress],
            }))
          );
          const tokenContractData = await multicallv2(PAIRABI, tokenDataCalls);
          let _token0 = await tokenContractSearch(tokenContractData[2][0]);
          let _token1 = await tokenContractSearch(tokenContractData[3][0]);

          let _pool = `${_token0[1][0]} - ${_token1[1][0]}`;
          let _lpBal = parseFloat(
            ethers.utils.formatUnits(tokenContractData[4][0].toString(), tokenContractData[1][0])
          );
          let _poolShare = (_lpBal / parseFloat(tokenContractData[0][0])) * 100;

          let finalData = [
            {
              lpBal: _lpBal,
              pairAddress: tokenaddress,
              pool: _pool,
              poolShare: _poolShare,
              token0: {
                address: tokenContractData[2][0],
                decimals: _token0[2][0],
                name: _token0[0][0],
                symbol: _token0[1][0],
              },
              token1: {
                address: tokenContractData[3][0],
                decimals: _token1[2][0],
                name: _token1[0][0],
                symbol: _token1[1][0],
              },
              logoURI: BTF_logo,
              symbol: _pool,
              decimals: tokenContractData[1][0],
              chainId: 43113,
              address: tokenaddress,
            },
          ];
          setCurrencies(finalData);
          dispatch(setWithDrawData(finalData));
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const searchFilter = useMemo(() => {
    if (currencies.length === 0) return;
    if (searchInput === '' || searchInput === undefined) {
      return currencies_default;
    }
    if (searchInput.startsWith('0x')) {
      return currencies;
    }
    let result =
      currencies &&
      currencies.filter((curr) => curr.symbol.toLowerCase().includes(searchInput.toLowerCase()));
    // setCurrencies(result);
    return result;
  }, [searchInput, currencies]);

  return (
    <>
      <div>
        <Dialog.Root open={open}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed top-0 left-0 flex h-screen w-screen items-center justify-center bg-overlay-bg  backdrop-blur-sm data-[state=open]:animate-overlayShow" />
            <Dialog.Content className="animate_currencies_box fixed top-1/2 left-1/2  w-full -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white drop-shadow-[0_4px_40px_rgba(247,185,62,0.25)]  focus-visible:outline-none dark:bg-[#1D202C] sm:w-3/5  lg:w-2/5">
              {/*For Closing*/}
              <div className="absolute right-1 top-[44px] items-center justify-end px-5">
                <Dialog.Close
                  asChild
                  onClick={() => {
                    dispatch(setDisplayCurrencies(false));
                  }}
                  className="cursor-pointer"
                >
                  <Closee />
                </Dialog.Close>
              </div>

              {/* Currencies Dialog */}
              <Dialog.Content asChild className="m-5">
                <>
                  <div className="currencies_suggestions_header">
                    <div className="currencies_suggestions_header_label">
                      <h1>
                        {label === 'Select_B' || label === 'Select_A' ? 'Select A Token' : label}
                      </h1>
                    </div>
                    <div>
                      <input
                        className="rounded-xl border border-primary-sunny-blurSemiDarkBg bg-gray-800 py-0 px-2 text-white focus-visible:outline-none max-lg:w-full"
                        type="text"
                        placeholder="Search"
                        value={searchInput}
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>

                    {label !== 'Token Pair' && (
                      <div className="header_currencies_suggestion_cont flex items-center justify-start">
                        {suggestedCurrencies.map((currency, index) => {
                          return (
                            <div
                              className="flex cursor-pointer items-center justify-start gap-1 text-base"
                              onClick={() => {
                                selectSuggested(index);
                              }}
                            >
                              <div style={{ width: '30px', height: '30px' }}>
                                <img
                                  src={currency.logoURI}
                                  alt={'#' + currency.name}
                                  style={{ width: 'inherit', height: 'inherit' }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = MissingImageIcon;
                                  }}
                                />
                              </div>
                              <div className="">
                                <span>{currency.symbol}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="currencies_cont">
                    <ul>
                      {searchFilter &&
                        searchFilter.map((currency, index) => {
                          return (
                            <li
                              className="currency_cont relative"
                              onClick={() => selectCurrency(index)}
                              key={index}
                            >
                              <div className="currency_logo">
                                <img
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = MissingImageIcon;
                                  }}
                                  src={currency.logoURI}
                                />

                                {/* <svg
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="sc-fcdeBU fjYvEu"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                </svg> */}
                              </div>
                              <div className="currency_name">{currency.symbol}</div>
                              <div className="absolute right-3 ">
                                <span className="text-xlg font-bold" style={{ color: '#6b6868' }}>
                                  0
                                </span>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </>
              </Dialog.Content>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </>
  );
}
