export const networks = {
  bsc: {
    chainId: 56,
    rpc: { 56: 'https://bsc-dataseed4.ninicoin.io/' },
    network: 'binance',
    supportedChainIds: [56],
    blockExplorers: 'https://bscscan.com',
  },
  bscTestnet: {
    rpc: { 97: 'https://data-seed-prebsc-1-s1.binance.org:8545' },
    chainId: 97,
    supportedChainIds: [97],
    network: 'binance',
    qrcode: true,
    blockExplorers: 'https://testnet.bscscan.com',
  },
  Avalanch: {
    rpc: {
      43114: 'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
    },
    chainId: 43114,
    supportedChainIds: [43114],
    network: 'avalanche',
    qrcode: true,
    blockExplorers: 'https://snowtrace.io',
  },
  fuji: {
    rpc: { 43113: 'https://ava-testnet.public.blastapi.io/ext/bc/C/rpc' },
    chainId: 43113,
    supportedChainIds: [43113],
    network: 'avalanche-fuji',
    qrcode: true,
    blockExplorers: 'https://testnet.snowtrace.io',
  },
};

export const activeNetwork = 'Avalanch';
