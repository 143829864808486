import React, { useInsertionEffect } from 'react';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { Line } from 'react-chartjs-2';
import '../../assets/Style/SwapChart.scss';
import { Chart as ChartJS } from 'chart.js/auto';
import { ChartData } from '../../utils';
import gradient from 'chartjs-plugin-gradient';
import moment from 'moment';
import { ReactComponent as TrendUpIcon } from '../../assets/icons/trend-up.svg';
import { ReactComponent as TrendDownIcon } from '../../assets/icons/trend-down.svg';
import ColourfulLoadingGIF from '../../assets/colorfulLoading.gif';

function LineChart({ currency, symbol }) {
  let chartRef = React.useRef();
  const [gradientBg, setGradientBg] = useState();
  const [gradientStroke, setGradientStroke] = useState();
  // console.log('chart', Line);
  let [activeTime, setActiveTime] = useState('1w');

  let [isAdvanced, setIsAdvanced] = useState(false);
  let [isLoading, setIsLoading] = useState(false);

  let [chartTime, setChartTime] = useState(Date.now());
  let [chartTimeFrom, setChartTimeFrom] = useState(1392577232);
  let [finalPrice, setFinalPrice] = useState();
  const [chartData, setChartData] = React.useState();

  let trendup = false;
  let timePoints = ['1h', '12h', '1d', '1w', '1M', '3M', '1Y'];
  let unixTimePoints = [3600, 3600 * 12, 86400, 86400 * 7, 2629743, 2629743 * 3, 31556926].map(
    (timestamp) => timestamp * 1000
  );
  let noOfDays = [0.0416, 0.5, 1, 7, 31, 91, 365];

  // useEffect(() => {
  //   const chart = chartRef.current;

  //   if (chart) {
  //     console.log('ChartJS', chart);

  //     let ctx = chart.ctx;
  //     const gradient_bg = ctx.createLinearGradient(0, 0, 0, 400);
  //     gradient_bg.addColorStop(0, 'rgba(69, 3, 255,0.1)');
  //     gradient_bg.addColorStop(1, 'rgba(23 ,22 ,43,0.2)');

  //     const gradient_stroke = ctx.createLinearGradient(0, 0, 0, 400);
  //     //yellow(248, 179, 39)
  //     gradient_stroke.addColorStop(0, 'rgba(	248, 179, 39)');
  //     gradient_stroke.addColorStop(0.6, 'rgb(	248, 179, 39,0.5)');
  //     gradient_stroke.addColorStop(0.8, 'rgba(	248, 179, 39,0.5)');
  //     gradient_stroke.addColorStop(1, 'rgba(	170, 75, 186)');

  //     setGradientBg(gradient_bg);
  //     setGradientStroke(gradient_stroke);
  //   }
  // }, [chartData]);

  // console.log(unixTimePoints);

  ChartJS.register(gradient);

  useEffect(() => {
    let Currency;
    setIsLoading(true);

    if (currency.toLowerCase() === 'usdc') {
      Currency = 'usd';
    } else {
      Currency = currency;
    }
    let url = `https://api.coingecko.com/api/v3/coins/${Currency}/market_chart?vs_currency=usd&days=7`;
    Axios.get(url)
      .then((res) => {
        // console.log(res.data);
        let data = res.data;
        console.log('Chart data gotton');
        setChartData(dataForChart(data));
        setFinalPrice(data.prices[data.prices.length - 1][1].toFixed(4));
        setActiveTime('1w');
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('ChartData Err', err);
        setChartData();
        setIsLoading(false);
      });
  }, [chartTime, chartTimeFrom, currency]);

  function getDataByDays(no_of_days, timeperiod) {
    if (no_of_days === 0) {
      return;
    }
    setIsLoading(true);
    let Currency;
    if (currency.toLowerCase() === 'usdc') {
      Currency = 'usd';
    } else {
      Currency = currency;
    }
    Axios.get(
      `https://api.coingecko.com/api/v3/coins/${Currency}/market_chart?vs_currency=usd&days=${no_of_days}`
    )
      .then((res) => {
        let data = res.data;
        console.log('Chart data by day gotton', data);
        setActiveTime(timeperiod);

        setChartData(dataForChart(data, no_of_days));
        setFinalPrice(data.prices[data.prices.length - 1][1].toFixed(4));
        setTimeIntervals(data, no_of_days);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('ChartData Err', err);
        setChartData();
        setIsLoading(false);
      });
  }

  function dataForChart(data, no_of_days = 7) {
    let filteredData = setTimeIntervals(data, no_of_days);

    return {
      labels: filteredData.prices.map((data) => data[0]),
      datasets: [
        {
          gradient: {
            backgroundColor: {
              axis: 'y',
              colors: {
                1: 'rgba(69, 3, 255,0.1)',
                // 0: '#f8b327',
                0: 'rgba(23 ,22 ,43,0.2)',
                // 50: '#4503ff',
              },
            },
            borderColor: {
              axis: 'y',
              colors: {
                1: 'rgba(	248, 179, 39)',
                0.6: 'rgb(	248, 179, 39,0.5)',
                0.8: 'rgba(	248, 179, 39,0.5)',
                0: 'rgba(	170, 75, 186)',
              },
            },
          },
          label: '',
          data: filteredData.prices.map((data) => parseFloat(data[1])),

          fill: true,

          borderWidth: 2.4,
        },
      ],
    };
  }

  function setTimeIntervals(data, no_of_days) {
    if (no_of_days === noOfDays[0]) {
      return data;
    }

    const newData = {
      prices: [],
    };

    switch (no_of_days) {
      case noOfDays[1]:
        newData.prices = data.prices.filter((price, index) => index % 6 === 0 || index === 0);
        return newData;

      case noOfDays[2]:
        newData.prices = data.prices.filter((price, index) => index % 3 === 0 || index === 0);
        return newData;
      case noOfDays[3]:
        return data;

      case noOfDays[4]:
        newData.prices = data.prices.filter((price, index) => index % 24 === 0 || index === 0);
        return newData;
      case noOfDays[5]:
        newData.prices = data.prices.filter((price, index) => index % 3 === 0 || index === 0);
        return newData;

      case noOfDays[6]:
        newData.prices = data.prices.filter((price, index) => index % 7 === 0 || index === 0);
        return newData;
    }
    return data;
    // console.log(data.prices.length, activeTime);
    // let DataLength = data.prices.length;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    showXLabels: 10,
    interaction: {
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
        hoverRadius: 4,
      },
    },

    scales: {
      x: {
        beginAtZero: false,
        reverse: false,
        animate: false,
        grid: {
          display: false,
          drawOnChartArea: true,
          drawTicks: true,
          tickColor: 'grey',
        },
        ticks: {
          callback: function (value, index, ticks) {
            if (activeTime === '1h' || activeTime === '12h' || activeTime === '1d') {
              // console.log(moment(this.getLabelForValue(value)).format('DD MMM H:m'), value);
              return moment(this.getLabelForValue(value)).format('H:m');
            }
            // console.log(moment(this.getLabelForValue(value)).format('DD MMM H:m'), value);
            return moment(this.getLabelForValue(value)).format('DD MMM');
          },
          color: '#505466',
          type: 'date',
          font: {
            size: 14,
          },
          padding: 5,
          autoSkip: true,
          maxTicksLimit: 18,
        },
      },
      y: {
        reverse: false,
        ticks: {
          color: '#505466',
          font: {
            size: 14,
          },
          padding: 20,
        },
        grid: {
          backgroundColor: 'red',
          drawTicks: true,
          drawOnChartArea: true,
        },
      },
    },
    layout: {
      padding: {
        top: 20,
      },
    },

    plugins: {
      tooltip: {
        backgroundColor: '#fff',
        displayColors: false,
        titleColor: '#1b1b1b',
        bodyColor: '#1b1b1b',
        titleFont: { size: 15, weight: 'bold' },
        bodyFont: { size: 13, weight: '500' },
        callbacks: {
          title: (context) => {
            return parseFloat(context[0].raw).toFixed(4);
          },
          label: (context) => {
            return moment(parseInt(context.label)).format('ddd, DD MMM, HH:mm');
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      {chartData ? (
        <>
          <div
            className={
              isAdvanced
                ? ' fixed inset-0 z-10 ml-0 flex h-screen w-screen items-center justify-center bg-zinc-800  bg-opacity-5   py-20 backdrop-blur-sm  '
                : ' h-full w-full'
            }
          >
            <div
              className={`chart_container relative flex-col dark:bg-bg-main ${
                isAdvanced ? 'h90  mx-10   shadow-lg ' : ' mt-0 h-full w-full'
              }`}
            >
              <div className="chart_header absolute top-0 flex w-full items-center justify-between dark:bg-bg-main ">
                <div className="chart_header_left flex items-center justify-start gap-4">
                  <div className="currency_label">
                    <span>
                      {finalPrice} {symbol.toUpperCase()}
                    </span>
                  </div>
                  <div className="updown_label flex items-center justify-start gap-1">
                    {trendup ? (
                      <>
                        <div className="trend_up_icon">
                          <TrendUpIcon height={32} width={32} />
                        </div>
                        <span style={{ color: 'rgb(71, 215, 226)' }}>1.6%</span>
                      </>
                    ) : (
                      <>
                        <div className="trend_down_icon">
                          <TrendDownIcon style={{ fill: 'red' }} height={32} width={32} />
                        </div>
                        <span style={{ color: 'red' }}>1.6%</span>
                      </>
                    )}
                  </div>
                </div>

                <div className="chart_header_right flex gap-4">
                  <div className="timeline flex gap-4 ">
                    {timePoints.map((timepoint, index) => {
                      // if (index < 1) {
                      //   return (
                      //     <div
                      //       className={
                      //         timepoint === activeTime ? 'timepoint timepoint_active' : 'timepoint'
                      //       }
                      //       onClick={() => changeTimePeriod(timepoint)}
                      //     >
                      //       <span>{timepoint}</span>
                      //     </div>
                      //   );
                      // }
                      return (
                        <div
                          className={
                            timepoint === activeTime ? 'timepoint timepoint_active' : 'timepoint'
                          }
                          onClick={() => getDataByDays(noOfDays[index], timepoint)}
                        >
                          <span>{timepoint}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="simple_advanced_tab flex gap-2">
                    <button
                      className={!isAdvanced && 'btn_active'}
                      onClick={() => setIsAdvanced(false)}
                    >
                      Simple
                    </button>
                    <button
                      className={isAdvanced && 'btn_active'}
                      onClick={() => setIsAdvanced(true)}
                    >
                      Advanced
                    </button>
                  </div>
                </div>
              </div>
              {isLoading && (
                <div className="back absolute top-0 z-10 h-full w-full bg-slate-800 bg-opacity-5  pt-6 opacity-60 backdrop-blur-sm">
                  <div className="flex h-full w-full items-center justify-center  bg-transparent">
                    <img src={ColourfulLoadingGIF} />
                  </div>
                </div>
              )}
              <div className="absolute bottom-0 w-full pt-6" style={{ height: '95%' }}>
                <Line data={chartData} options={options} ref={chartRef} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <h1>No Data Found</h1>
      )}
    </>
  );
}

export default LineChart;
