import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as SwapIcon } from '../../assets/swap.svg';

export default function Button({ isLoading, text, action, disabled }) {
  return (
    <div>
      <button
        // className="w-max rounded-md py-3 px-5 text-lg font-semibold uppercase tracking-wider text-white dark:bg-primary-sunny-blurSemiDarkBg"
        // className="flex w-max flex-row items-center gap-2 rounded-lg border border-primary-sunny-main py-2 px-5 text-base uppercase tracking-wider duration-300 ease-in-out hover:bg-primary-sunny-dark disabled:cursor-not-allowed"
        className={`flex w-max cursor-pointer  gap-4 rounded-md border border-primary-sunny-main  px-8 py-3 text-lg uppercase  tracking-wider 
         text-white  duration-300 ease-in-out  enabled:hover:bg-primary-sunny-dark disabled:cursor-not-allowed disabled:opacity-70`}
        onClick={() => {
          action();
        }}
        disabled={disabled}
      >
        {text === 'Swap' && !isLoading && <SwapIcon />}

        {isLoading && (
          <TailSpin
            height="20"
            width="20"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={isLoading}
          />
        )}
        <>
          {/* <div className={text.toLowerCase() === 'approve' ? `` : ' max-sm:hidden'}>{text}</div> */}
          <div className="">{text}</div>
        </>
      </button>
    </div>
  );
}
