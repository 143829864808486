import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import PangolinRouterABI from '../../ABI/PangolinRouterABI.json';
import { getRouterAddress } from '../../utils/addressHelpers';
import Toast from '../shared/Toast';
import { toast } from 'react-toastify';

async function getPairs(factory, ABI, provider, address1, address2) {
  try {
    let contract = new ethers.Contract(factory, ABI, provider);
    let address = await contract.getPair(address1, address2);
    // console.log(address);

    // if (address === '0x0000000000000000000000000000000000000000') {
    //   // alert('Pair not found');
    //   return;
    // }
    return address;
  } catch (err) {
    // alert('Pairing Error');
    console.log(err);
  }
}

async function getAmountsOut(address, ABI, provider, path, amountA) {
  try {
    let contract = new ethers.Contract(address, ABI, provider);
    // console.log(contract);
    let value = await contract.getAmountsOut(amountA, path);

    return value[1];
  } catch (err) {
    // alert('These currencies do not have pair');
    console.log(err);
  }
}

async function getReserves(pairAddress, ABI, provider) {
  try {
    let contract = new ethers.Contract(pairAddress, ABI, provider);
    let reserves = await contract.getReserves();
    return [reserves._reserve0, reserves._reserve1];
  } catch (err) {
    console.log('Error while getting reserves', err);
  }
}
async function getToken0(pairAddress, ABI, provider) {
  try {
    let contract = new ethers.Contract(pairAddress, ABI, provider);
    let token0 = await contract.token0();
    return token0;
  } catch (err) {
    console.log('Error while getting Token0', err);
  }
}

async function SwapAmount(amount, reserves, decimalA, decimalB) {
  let reserveA = reserves[0];
  let reserveB = reserves[1];
  let output;

  if (amount.toString() === '0' || amount === '' || amount === undefined) {
    amount = 0;
    return 0;
  }
  let parsedA = ethers.utils.parseUnits(amount, decimalA);
  let nRa, nRb;
  try {
    if (decimalA >= decimalB) {
      nRa = reserveA;
      nRb = ethers.utils.parseUnits(reserveB.toString(), decimalA - decimalB);
      if (nRa.toString() === '0') {
        nRa = ethers.utils.parseUnits(BigNumber(1).toString(), decimalA - decimalB);
      }
    } else {
      nRa = ethers.utils.parseUnits(reserveB.toString(), decimalB - decimalA);
      nRb = reserveB;
      if (nRa.toString() === '0') {
        nRa = ethers.utils.parseUnits(BigNumber(1).toString(), decimalB - decimalA);
      }
    }

    output = parsedA.mul(nRb.div(nRa));
    return output;
  } catch (err) {
    console.log(err);
  }
}

async function SwapToken(amount, TokenBvalue, TokenA, TokenB, address, signer) {
  try {
    let LpTokenRouter_address = getRouterAddress();

    let TokenA_DECIMAL = TokenA.decimals;
    let TokenB_DECIMAL = TokenB.decimals;

    let contract = new ethers.Contract(LpTokenRouter_address, PangolinRouterABI, signer);
    let to = address;
    let path = [TokenA.address, TokenB.address];
    let amountOutMin;

    if (TokenA.address === '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7') {
      let slippage = 0.1;
      console.log((TokenBvalue - TokenBvalue * (slippage / 100)).toString(), TokenB_DECIMAL);
      amountOutMin = ethers.utils.parseUnits(
        (TokenBvalue - TokenBvalue * (slippage / 100)).toFixed(TokenB_DECIMAL),
        TokenB_DECIMAL
      );

      let tx = await contract.swapExactAVAXForTokens(
        amountOutMin,
        path,
        to,
        Math.ceil(Date.now() / 1000) + 10000,
        {
          value: ethers.utils.parseUnits(amount.toString(), TokenA_DECIMAL),
        }
      );
      await toast.promise(tx.wait(), {
        pending: {
          render() {
            return <Toast text={`Swapping ${TokenA.symbol} for ${TokenB.symbol}`} hash={''} />;
          },
        },
        success: {
          render() {
            return <Toast text={`Swapped ${TokenA.symbol} for ${TokenB.symbol} `} hash={tx.hash} />;
          },
        },
        error: {
          render() {
            return (
              <Toast
                text={`Error while swapping ${TokenA.symbol} for ${TokenB.symbol}`}
                hash={tx.hash}
              />
            );
          },
        },
      });

      return;
    }
    if (TokenB.address === '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7') {
      let slippage = 0.05;

      amountOutMin = ethers.utils.parseUnits(
        (TokenBvalue - TokenBvalue * (slippage / 100)).toFixed(TokenB_DECIMAL),
        TokenB_DECIMAL
      );
      let tx = await contract.swapExactTokensForAVAX(
        ethers.utils.parseUnits(amount.toString(), TokenA_DECIMAL),
        amountOutMin,
        path,
        to,
        Math.ceil(Date.now() / 1000) + 10000
      );
      await toast.promise(tx.wait(), {
        pending: {
          render() {
            return <Toast text={`Swapping ${TokenA.symbol} for ${TokenB.symbol}`} hash={''} />;
          },
        },
        success: {
          render() {
            return <Toast text={`Swapped ${TokenA.symbol} for ${TokenB.symbol} `} hash={tx.hash} />;
          },
        },
        error: {
          render() {
            return (
              <Toast
                text={`Error while swapping ${TokenA.symbol} for ${TokenB.symbol}`}
                hash={tx.hash}
              />
            );
          },
        },
      });
      return;
    }
    //else
    let slippage = 0.02;
    amountOutMin = ethers.utils.parseUnits(
      (TokenBvalue - TokenBvalue * (slippage / 100)).toFixed(TokenB_DECIMAL),
      TokenB_DECIMAL
    );
    console.log(ethers.utils.formatEther(amountOutMin));
    let tx = await contract.swapExactTokensForTokens(
      ethers.utils.parseUnits(amount.toString(), TokenA_DECIMAL),
      amountOutMin,
      path,
      to,
      Math.ceil(Date.now() / 1000) + 10000
    );
    await toast.promise(tx.wait(), {
      pending: {
        render() {
          return <Toast text={`Swapping ${TokenA.symbol} for ${TokenB.symbol}`} hash={''} />;
        },
      },
      success: {
        render() {
          return <Toast text={`Swapped ${TokenA.symbol} for ${TokenB.symbol} `} hash={tx.hash} />;
        },
      },
      error: {
        render() {
          return (
            <Toast
              text={`Error while swapping ${TokenA.symbol} for ${TokenB.symbol}`}
              hash={tx.hash}
            />
          );
        },
      },
    });
  } catch (err) {
    console.log(err);
    return false;
  }
}

export { getPairs, getAmountsOut, SwapAmount, getReserves, SwapToken, getToken0 };
