import { createSlice } from '@reduxjs/toolkit';

const DisabledBodySlice = createSlice({
  name: 'DisableBodySlice',
  initialState: false,
  reducers: {
    setDisabledBody: (data, action) => {
      data = action.payload;
    },
  },
});

export const { setDisabledBody } = DisabledBodySlice.actions;
export default DisabledBodySlice.reducer;
