import { Skeleton } from '@mui/material';

export const SkeletonComponent = () => {
  return (
    <Skeleton
      style={{
        width: '100%',
        maxWidth: '50px',
        minWidth: '50px',
        borderRadius: '2px',
        background: 'rgba(255,255,255,0.3)',
      }}
    />
  );
};
