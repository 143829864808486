import { createSlice } from '@reduxjs/toolkit';

export const SwapboxAlertSlice = createSlice({
  name: 'SwapboxAlertSlice',
  initialState: {
    alertName: '',
    message: '',
  },
  reducers: {
    setSwapboxAlert: (data, action) => {
      data.alertName = action.payload.alertName;
      data.message = action.payload.message;
    },
  },
});

export const { setSwapboxAlert } = SwapboxAlertSlice.actions;

export default SwapboxAlertSlice.reducer;
