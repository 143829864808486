import React from 'react';
import { ReactComponent as BellIcon } from '../../assets/bell.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Hamburg } from '../../assets/hamburg.svg';

import ThemeToggle from '../layout/ThemeToggle';
import CustomeConnectButton from '../layout/CustomeConnectButton';

export default function Header({setOpen}) {

  return (
    <div className="flex h-[84px] gap-3 items-center justify-between py-4 px-5 sm:px-9">
      <div className="hidden items-center gap-2 md:flex">
        <Logo />
        <div className="text-sm capitalize tracking-wide">Bee-Trade finance</div>
      </div>
      <div className="flex items-center gap-2 md:hidden" onClick={()=>{setOpen(true)}}>
        <Hamburg />
      </div>
      <div className="flex items-center gap-4">
        <ThemeToggle />
        {/* <BellIcon className="text-[#5B5B5B] dark:text-[#C2C2C2]" /> */}
        <CustomeConnectButton /> 
      </div>
    </div>
  );
}
