import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export default function ThemeToggle() {
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const toggleSwitch = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  useEffect(() => {
    if (
      localStorage.theme === 'light' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: light)').matches)
    ) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    if (theme === 'light') {
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.add('dark');
    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <div
      onClick={toggleSwitch}
      className="flex h-[36px] w-[72px] rounded-[50px] bg-[#39393C] p-[3px] shadow-inner hover:cursor-pointer hidden"
    >
      <div className="relative w-full">
        <div className={`absolute flex w-full ${theme === 'dark' && 'place-content-end'}`}>
          <motion.div
            className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-[#F8B327]"
            layout
            transition={spring}
          />
        </div>
        <div className="absolute flex h-full w-full items-center justify-between px-2">
          <i className={`bi-sun-fill text-base text-white ${ theme === "light" ? 'invisible' : 'visible' }`}/>
          <i className={`bi-moon-stars-fill text-base text-white ${ theme === "dark" ? 'invisible' : 'visible' } `} />
        </div>
      </div>
    </div>
  );
}
