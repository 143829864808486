import addresses from '../constants/contract';
import { activeNetwork, networks } from '../config/networkConfig';

export const getAddress = (address) => {
  const chainId = networks[activeNetwork]['chainId'];
  return address[chainId];
};

export const getAddresses = () => {
  return addresses.addresses;
};
export const getfarmContract = () => {
  return addresses.DrivenFarm;
};

export const getRouterAddress = () => {
  return addresses.LpTokenRouter;
};
export const getWBNBAddress = () => {
  return addresses.WBNB;
};
export const getIDOAddress = () => {
  return addresses.IDO;
};
export const getTokenAddresses = () => {
  return addresses.addresses.map((ele) => ele.DividendToken);
};

export const getLockUpAddresses = () => {
  return addresses.addresses.map((ele) => ele.lockup);
};

export const getUSDTAddress = () => {
  return addresses.USDT;
};

export const getMulticallAddress = () => {
  return addresses.multiCall;
};

export const getLimitOrderAddress = () => {
  return addresses.LimitOrder;
};
