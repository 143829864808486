import { createSlice } from "@reduxjs/toolkit"

let initialState = {
    isProvide: true,
    withdrawData: {
        lpBal: 0,
        pool: 'Select',
    }
}

export const DropdownMenu = createSlice({
    name: "DropdownMenu",
    initialState: {
        ...initialState
    },
    reducers: {
        setIsProvide: (data, action)=>{
            data.isProvide = action.payload;
        },
        setWithDrawData: (data, action) => {
            data.withdrawData = action.payload
        },
    }
})


export const { setIsProvide, setWithDrawData } = DropdownMenu.actions;
export default DropdownMenu.reducer