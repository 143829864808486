import axios from 'axios';

const instance = axios.create({ baseURL: 'https://bee-trade-backend.vercel.app/' });
// const instance = axios.create({ baseURL: 'http://localhost:4000' });

export function makeOrder(owner, tokenGive, tokenGet, rate, index, nonce, type) {
  instance.post('/api/orderController/makeOrder', {
    owner,
    tokenGive,
    tokenGet,
    rate,
    index,
    nonce,
    type,
  });
}
export async function fetchOrderHistory() {
  const result = await instance.post('/api/orderController/fetchOrderHistory');
  return result;
}
export async function fetchOpenOrders() {
  const result = await instance.post('/api/orderController/fetchOpenOrders');
  return result;
}
export async function cancelOrder(owner, nonce) {
  const result = await instance.post('/api/orderController/cancelOrder', { owner, nonce });
  return result;
}
