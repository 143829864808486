import './App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Body from './components/Body';
import '@rainbow-me/rainbowkit/styles.css';
import {
  connectorsForWallets,
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { avalanche, avalancheFuji, bsc, bscTestnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { BrowserRouter } from 'react-router-dom';
import './assets/Style/style.scss';
import { useLayoutEffect } from 'react';
import store from './store/configStore';
import { useSelector } from 'react-redux';
import {
  injectedWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
const { chains, provider } = configureChains(
  [avalanche, avalancheFuji, bscTestnet, bsc],
  [alchemyProvider({ apiKey: process.env.ALCHEMY_ID }), publicProvider()]
);
const projectId = 'beetrade';
const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
    ],
  },
]);
const wagmiClient = createClient({
  autoConnect: false,
  connectors,
  provider,
});

function App() {
  // useLockBodyScroll();

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} modalSize="compact">
        <BrowserRouter>
          <Body />
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
}

export default App;
