import { createSlice } from '@reduxjs/toolkit';
import { BigNumber, ethers } from 'ethers';

let initialState = {
  open: false,
  modalData: {
    contractType: '',
    term: '',
    lockupAddress: '',
    stakingToken: '',
    stackType: 0,
    duration: null,
    total: null,
  },
  btfBalance: BigNumber.from('0'),
  btfFormated: 0,
  totalBtf: 0,
  requireRefresh: false,
};

export const ModalSlice = createSlice({
  name: 'ModalSlice',
  initialState: {
    ...initialState,
  },

  reducers: {
    setOpen: (data, action) => {
      data.open = action.payload;
    },

    setModalData: (data, action) => {
      data.modalData = action.payload;
    },

    setBtfBalance: (data, action) => {
      data.btfBalance = action.payload;
      data.btfFormated = parseFloat(ethers.utils.formatUnits(action.payload));
    },

    setTotalBtf: (data, action) => {
      data.totalBtf = action.payload;
    },

    setRefresh: (data, action) => {
      data.requireRefresh = action.payload;
    },
  },
});

export const { setOpen, setModalData, setBtfBalance, setTotalBtf, setRefresh } = ModalSlice.actions;
export default ModalSlice.reducer;
