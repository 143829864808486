import React, { memo, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

function CountupCustom({ value, decimals }) {
  const previousValue = useRef(0);
  useEffect(() => {
    previousValue.current = value;
  }, [value]);
  return (
    <CountUp
      start={0}
      end={value}
      prefix=""
      suffix={' $BTF'}
      decimals={decimals}
      duration={1}
      separator=","
      preserveValue={true}
    />
  );
}

export default memo(CountupCustom)
