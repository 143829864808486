import React, { useState } from 'react';
import { AllSVG, AskSVG, BidSVG } from '../../../utils/svgs';
import { useOrderHistory } from '../../../hooks/trade/useOrderInfo';
import useTradeInfo from '../../../hooks/useTradeContext';
import { PriceComponent } from '../../PriceComponent';
import { SkeletonComponent } from '../../SkeletonComponent';

export default function BookPanel() {
  const historyType = [
    {
      svg: AllSVG,
      name: 'All',
      color: 'text-[#a7acb9]',
    },
    {
      svg: BidSVG,
      name: 'Bids',
      color: 'text-[#1c9f39]',
    },
    {
      svg: AskSVG,
      name: 'Asks',
      color: 'text-[#f13732]',
    },
  ];
  const [selectedHistory, setSelectedHistory] = useState(0);
  const histories = useOrderHistory();
  const { pairs, selectedPair } = useTradeInfo();
  const pair = pairs[selectedPair];
  const buyHistories = histories.filter((data) => data.type === 'buy');
  const sellHistories = histories.filter((data) => data.type === 'sell');
  return (
    <>
      <div className="mt-2 flex w-full">
        {historyType.map((data, i) => {
          return (
            <div
              key={i}
              className="flex flex-1 cursor-pointer flex-col items-center"
              onClick={() => setSelectedHistory(i)}
            >
              <div
                className={`text-xs font-semibold ${
                  i === selectedHistory ? 'text-white' : data.color
                }`}
              >
                {data.name}
              </div>
              <div>{data.svg}</div>
            </div>
          );
        })}
      </div>
      <div className="mt-2 flex justify-between bg-black py-2 text-xs font-medium">
        <div className="flex-1 text-center">PRICE(USDT)</div>
        <div className="flex-1 text-center">AMOUNT({pair.token0.symbol})</div>
        <div className="flex-1 text-center">TOTAL(USDT)</div>
      </div>
      {selectedHistory !== 2 &&
        buyHistories.map((data, i) => {
          return (
            <div key={i}>
              <div className="flex h-full flex-1 flex-col font-medium">
                <div className="my-2 flex flex-1 justify-between text-xs font-medium">
                  <div className="flex-1 text-center text-[#1c9f39]">{data.rate.toFixed(2)}</div>
                  <div className="flex-1 text-center">
                    {(data.tokenGet.amount / Math.pow(10, data.tokenGet.decimals)).toFixed(5)}
                  </div>
                  <div className="flex-1 text-center">
                    {(
                      ((data.tokenGet.amount / Math.pow(10, data.tokenGet.decimals)) *
                        data.tokenGive.amount) /
                      Math.pow(10, data.tokenGive.decimals)
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className="flex items-center justify-center bg-black py-2">
        <div className="w-[90px]">{pair.price ? pair.price.toFixed(2) : <SkeletonComponent />}</div>
        <div className="w-[60px]">
          {pair.priceChange ? (
            <PriceComponent
              value={pair.priceChange.toFixed(2)}
              change={pair.priceChange}
              suffix={'%'}
              prefix={'+'}
            />
          ) : (
            <SkeletonComponent />
          )}
        </div>
      </div>
      {selectedHistory !== 1 &&
        sellHistories.map((data, i) => {
          return (
            <div key={i}>
              <div className="flex h-full flex-1 flex-col font-medium">
                <div className="my-2 flex flex-1 justify-between text-xs font-medium">
                  <div className="flex-1 text-center text-danger">{data.rate.toFixed(2)}</div>
                  <div className="flex-1 text-center">
                    {(data.tokenGive.amount / Math.pow(10, data.tokenGive.decimals)).toFixed(5)}
                  </div>
                  <div className="flex-1 text-center">
                    {(
                      ((data.tokenGive.amount / Math.pow(10, data.tokenGive.decimals)) *
                        data.tokenGet.amount) /
                      Math.pow(10, data.tokenGet.decimals)
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
