/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useRefresh from '../useRefresh';
import { getMulticallContract, getTokenContract } from '../../utils/contractHelpers';
import { useAccount } from 'wagmi';

export const useBalance = (token) => {
  const [balance, setBalance] = useState(0);

  const { address: account } = useAccount();
  // const account = '0xA9BEDc5706DcF804516C4B5CfB9eF5601B6463Dc';
  const { fastRefresh } = useRefresh();
  async function fetchBalance() {
    try {
      if (!token.isNative) {
        const tokenContract = getTokenContract(token.address);
        const _balance = await tokenContract.balanceOf(account);
        setBalance(_balance);
      } else {
        const multicallContract = getMulticallContract();
        const _balance = await multicallContract.getEthBalance(account);
        setBalance(_balance);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (!account) return;
    fetchBalance();
  }, [fastRefresh, token, account]);
  return balance;
};

export const useAllowance = (address, to) => {
  const [allowance, setAllowance] = useState(0);

  const { address: account } = useAccount();
  const { fastRefresh } = useRefresh();

  async function fetchAllowance() {
    try {
      const tokenContract = getTokenContract(address);
      const allowance = await tokenContract.allowance(account, to);
      setAllowance(allowance);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (!account) return;
    fetchAllowance();
  }, [fastRefresh, address, to, account]);
  return allowance;
};
