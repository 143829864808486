import { createSlice } from '@reduxjs/toolkit';
import BTF_logo from '../assets/logo.png';
import AVAX_logo from '../assets/avax_logo.svg';

export const CurrencySuggestionsSlice = createSlice({
  name: 'CurrencySuggestionsSlice',
  initialState: {
    label: 'From',
    converting_from: {
      chainId: 43114,
      address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      decimals: 6,
      name: 'TetherToken',
      symbol: 'USDt',
      logoURI:
        'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7/logo_24.png',
    },
    converting_to: {
      chainId: 43114,
      address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      decimals: 18,
      name: 'Wrapped AVAX',
      symbol: 'AVAX',
      logoURI: AVAX_logo,
    },
    select_A: {
      logoURI: '',
      symbol: 'Select',
    },
    select_B: {
      logoURI: '',
      symbol: 'Select',
    },
    pairTokenA: {
      logoURI: '',
      symbol: 'Select',
    },
    pairTokenB: {
      logoURI: '',
      symbol: 'Select',
    },
    isWalletConnected: false,
    displayCurrencies: false,
  },
  reducers: {
    setDisplayCurrencies: (data, action) => {
      data.displayCurrencies = action.payload;
    },
    setLabel: (data, action) => {
      data.label = action.payload;
    },
    setConvertingFrom: (data, action) => {
      data.converting_from = action.payload;
    },
    setConvertingTo: (data, action) => {
      data.converting_to = action.payload;
    },
    setSelectA: (data, action) => {
      data.select_A = action.payload;
    },
    setSelectB: (data, action) => {
      data.select_B = action.payload;
    },
    setPairTokenA: (data, action) => {
      data.pairTokenA = action.payload;
    },
    setPairTokenB: (data, action) => {
      data.pairTokenB = action.payload;
    },
    setWalletConnected: (data, action) => {
      data.isWalletConnected = action.payload;
    },
  },
});

export const {
  setDisplayCurrencies,
  setLabel,
  setConvertingFrom,
  setConvertingTo,
  setSelectA,
  setSelectB,
  setPairTokenA,
  setPairTokenB,
  setWalletConnected,
} = CurrencySuggestionsSlice.actions;
export default CurrencySuggestionsSlice.reducer;
