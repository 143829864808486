import { toast } from 'react-toastify';

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const figureError = (error) => {
  try {
    if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
      const list = error.message.split(',');
      for (let i = 0; i < list.length; i++) {
        if (list[i].includes('message') && !list[i].includes('Internal JSON-RPC error')) {
          if (list[i].includes('insufficient funds')) {
            toast.error('Insufficient Funds');

            break;
          }
          let msg = String(list[i]).replaceAll('"', '');
          msg.replaceAll('"\\"', '');
          msg.replaceAll('message:', '');
          msg.replaceAll('}', '');
          toast.error(msg.split(':')[1].toUpperCase());

          break;
        }
      }
    } else {
      let msg = error.data?.message ?? error.message.split('(')[0];
      if (msg.includes('insufficient funds')) {
        msg = 'Insufficient Funds';
      }
      toast.error(msg);
    }
  } catch (e) {
    console.log(e);
  }
};
