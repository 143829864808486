import React, { useState } from 'react';
import styled from '@emotion/styled';
import { AllSVG, AskSVG, BidSVG } from '../../../utils/svgs';
import TradePanel from './TradePanel';
import BookPanel from './BookPanel';

export default function HistoryPanel() {
  const books = ['Order Book', 'Trade History'];
  const [selectedBook, setSelectedBook] = useState(0);

  return (
    <StyledPanel className="flex h-full 2xl:w-[320px] w-full flex-col">
      <FilterPanel
        className="flex min-h-[32px] text-xs font-semibold text-[#a7acb9]"
        active={selectedBook + 1}
      >
        {books.map((data, i) => {
          return (
            <div
              key={i}
              className="flex flex-1 cursor-pointer items-center justify-center transition hover:text-[#CA8D31]"
              onClick={() => setSelectedBook(i)}
            >
              {data}
            </div>
          );
        })}
      </FilterPanel>
      {selectedBook === 0 ? <BookPanel /> : <TradePanel />}
    </StyledPanel>
  );
}
const FilterPanel = styled.div`
  > div:nth-child(${({ active }) => active}) {
    background: #ca8d31;
    color: white;
  }
`;

const StyledPanel = styled.div`
  background: #1b1b1b;
  border-radius: 6px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
      width: 16px;
      height: 16px;
      display: block !important;
    }
    ::-webkit-scrollbar-track {
    }
    ::-webkit-scrollbar-thumb:vertical {
      border: 6px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #eebb19;
    }
`;
