import React from 'react';
import { activeNetwork, networks } from '../../config/networkConfig';

export default function Toast({ closeToast, text, hash }) {
  const baseURL = networks[activeNetwork]['blockExplorers'];
  return (
    <div className=" bg-transparent p-2">
      <div className=" mx-auto flex flex-col  ">
        <div className="pl-0">{text}</div>
        {hash !== '' && (
          <div className="pl-0 text-primary-sunny-main duration-200 ease-linear hover:underline hover:underline-offset-1">
            <a href={`${baseURL}/tx/${hash}`} target="_blank">
              {' '}
              View on Block Explorar
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
