import { Box, Modal } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import validator from 'validator';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export const IDOModal = ({ open, handleClose, setOpen }) => {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator.isEmail(email)) {
      setValidEmail(true);
      setOpen(false);
    } else {
      setValidEmail(false);
    }

    // let url = `${process.env.REACT_APP_API_URL}/add/email`;
    let url = `https://beetrade-ob-backend.vercel.app/api/IPController/add/email`;
    // let url = `http://192.168.29.189:4000/api/IPController/add/email`;
    await axios.post(url, {
      email,
    });
  };

  return (
    <Modal open={open} onClose={handleClose} hideBackdrop className="bg-[rgb(0,0,0,0.5)]">
      <Box
        sx={{ ...style, width: 400 }}
        className="rounded-xl focus-visible:outline-none dark:bg-bg-main"
      >
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full rounded-lg border border-gray-500 bg-transparent p-2.5 text-sm text-gray-900 dark:text-white"
              placeholder="name@company.com"
            />
            {!validEmail && (
              <p className="text-sm text-red-500">Please enter a valid email address.</p>
            )}
          </div>
          <button className="gradient-box w-full justify-center rounded-lg px-5 py-2.5 text-sm font-medium text-white focus:outline-none dark:bg-bg-main">
            Submit
          </button>
        </form>
      </Box>
    </Modal>
  );
};
