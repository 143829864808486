import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { ReactComponent as WalletIcon } from '../../assets/carbon_wallet.svg';
import { ReactComponent as SwapIcon } from '../../assets/swap.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setWalletConnected } from '../../store/CurrenciesSuggestionsSlice';
import { setSwapboxAlert } from '../../store/SwapboxAlertSlice';

export default function CustomeConnectButton(props) {
  let dispatch = useDispatch();
  let SwapboxAlert = useSelector((state) => state.swapboxAlert);
  return (
    <>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== 'loading';
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated');

          return (
            <div
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  dispatch(setWalletConnected(false));
                  dispatch(
                    setSwapboxAlert({
                      alertName: 'wallet_connection',
                      message: 'Please Connect Your Wallet',
                    })
                  );
                  if (props.from && props.from == 'SwapPage') {
                    return (
                      <div className="flex flex-col items-center justify-center">
                        <div className="btn_cont !w-fit">
                          <button
                            className="gradient-box  dark:bg-bg-main "
                            onClick={openConnectModal}
                            type="button"
                          >
                            <div className="flex gap-4 py-3 text-base font-semibold   text-white sm:px-5">
                              <WalletIcon />
                              <div className="hidden sm:block">Connect Wallet</div>
                            </div>
                          </button>
                        </div>
                      </div>
                    );
                  }

                  return (
                    //   <button onClick={openConnectModal} type="button">
                    //     Connect Wallet
                    //   </button>
                    <>
                      <button
                        className="gradient-box px-4 dark:bg-bg-main"
                        onClick={openConnectModal}
                        type="button"
                      >
                        <div className=" flex flex-nowrap gap-4 text-ellipsis whitespace-nowrap px-5 py-3 text-base font-semibold text-white ">
                          <WalletIcon />
                          <div className="hidden text-ellipsis whitespace-nowrap break-keep sm:block">
                            Connect Wallet
                          </div>
                        </div>
                      </button>
                    </>
                  );
                }

                if (chain.unsupported) {
                  dispatch(
                    setSwapboxAlert({
                      alertName: 'wrong_network',
                      message: 'Wrong Network Detected',
                    })
                  );

                  return (
                    <button onClick={openChainModal} type="button">
                      Wrong network
                    </button>
                  );
                }
                if (connected) {
                  dispatch(setWalletConnected(true));
                  if (SwapboxAlert.alertName === 'wallet_connection') {
                    dispatch(
                      setSwapboxAlert({
                        alertName: '',
                        message: '',
                      })
                    );
                  }
                  if (props.from && props.from == 'SwapPage' && props.text == 'Swap') {
                    return props.shouldDisplay ? (
                      <div className="" onClick={props.SwappingMethod}>
                        <button
                          className="rounded-lg border border-primary-sunny-main py-1 px-1 text-xs uppercase tracking-wider duration-300 ease-in-out hover:bg-primary-sunny-dark dark:bg-bg-main"
                          type="button"
                        >
                          <div className="flex gap-4 px-6 py-2 text-base  text-white">
                            <SwapIcon />
                            <div className="hidden sm:block">{props.text}</div>
                          </div>
                        </button>
                      </div>
                    ) : (
                      ''
                    );
                  }
                  if (props.from && props.from == 'SwapPage' && props.text == 'Approve') {
                    return (
                      <div className="btn_cont" onClick={props.ApproveMethod}>
                        <button
                          className="gradient-box duration-300 ease-in-out hover:bg-primary-sunny-dark  dark:bg-bg-main"
                          type="button"
                        >
                          <div className="flex gap-4 text-ellipsis whitespace-nowrap px-5  py-3 text-base text-white">
                            <div className="hidden sm:block">{props.text}</div>
                          </div>
                        </button>
                      </div>
                    );
                  }
                }
                return (
                  <div style={{ display: 'flex', gap: 12 }}>
                    <button
                      onClick={openChainModal}
                      style={{ display: 'flex', alignItems: 'center' }}
                      type="button"
                    >
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 25,
                            height: 25,
                            borderRadius: 999,
                            overflow: 'hidden',
                            marginRight: 4,
                          }}
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? 'Chain icon'}
                              src={chain.iconUrl}
                              style={{ width: 25, height: 25 }}
                            />
                          )}
                        </div>
                      )}
                      {chain.hasIcon}
                    </button>

                    <button onClick={openAccountModal} type="button">
                      {account.displayName}
                      {/* {account.displayBalance ? ` (${account.displayBalance})` : ''} */}
                    </button>
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </>
  );
}
