import { numberWithCommas } from '../../utils/functions';

export const PriceComponent = ({ value, change, suffix = '', prefix = '' }) => {
  return (
    <div className={`${change >= 0 ? 'text-success' : 'text-danger'}`}>
      {value >= 0 ? prefix : ''}
      {numberWithCommas(value)}
      {suffix}
    </div>
  );
};
