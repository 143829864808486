export const AllSVG = (
  <svg
    _ngcontent-iys-c103=""
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    class="w-[18px]"
  >
    <path _ngcontent-iys-c103="" d="M4 4h7v7H4V4z" fill="#F6465D"></path>
    <path _ngcontent-iys-c103="" d="M4 13h7v7H4v-7z" fill="#0ECB81"></path>
    <path
      _ngcontent-iys-c103=""
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
      fill="currentColor"
    ></path>
  </svg>
);

export const BidSVG = (
  <svg
    _ngcontent-iys-c103=""
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    class="w-[18px]"
  >
    <path _ngcontent-iys-c103="" d="M4 4h7v16H4V4z" fill="#0ECB81"></path>
    <path
      _ngcontent-iys-c103=""
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
      fill="currentColor"
    ></path>
  </svg>
);

export const AskSVG = (
  <svg
    _ngcontent-iys-c103=""
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    class="w-[18px]"
  >
    <path _ngcontent-iys-c103="" d="M4 4h7v16H4V4z" fill="#F6465D"></path>
    <path
      _ngcontent-iys-c103=""
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
      fill="currentColor"
    ></path>
  </svg>
);

export const BookSVG = (
  <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6016 6.5H20.6016M5.60156 6.5H11.6016M14.6016 10.5H20.6016M5.60156 10.5H11.6016M14.6016 14.5H20.6016M5.60156 14.5H11.6016M3 19.5H23C24.1046 19.5 25 18.6046 25 17.5V3.5C25 2.39543 24.1046 1.5 23 1.5H3C1.89543 1.5 1 2.39543 1 3.5V17.5C1 18.6046 1.89543 19.5 3 19.5Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
    ></path>
  </svg>
);

export const FundingSVG = (
  <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1.5" width="24" height="18" rx="2" stroke="currentColor" stroke-width="1.25"></rect>
    <path
      d="M24 9.40909H23.5031C22.6298 9.40909 21.8786 10.0271 21.7103 10.8841L21.5815 11.5397C21.343 12.7537 19.8177 13.1699 18.9957 12.2451V12.2451C18.1753 11.3222 16.6531 11.7347 16.4109 12.9456L16.3782 13.109C16.1826 14.0868 15.1408 14.6407 14.2209 14.256L14.1342 14.2197C13.7298 14.0506 13.4142 13.7207 13.2634 13.3092V13.3092C12.7294 11.853 10.6338 11.9647 10.2577 13.4693L10.2183 13.6266C9.83614 15.1555 7.71964 15.2991 7.13438 13.8359L6.48058 12.2014C6.18407 11.4602 5.50821 10.9385 4.71599 10.8395L2 10.5"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
    ></path>
    <path
      opacity="0.5"
      d="M24 9H2"
      stroke="currentColor"
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-dasharray="3 3"
    ></path>
  </svg>
);
export const TradingSVG = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8.125" stroke="currentColor" stroke-width="1.25"></circle>
    <path
      d="M10 6V10.375H14.375"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
    ></path>
  </svg>
);

export const HistorySVG = (
  <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3397 5.5H19.3397V7.5H16.3397V9.5H13.3397M13.3397 9.5V15.5M13.3397 9.5H10.3397V7.5H7.33972V5.5H5.33972M3 19.5H23C24.1046 19.5 25 18.6046 25 17.5V3.5C25 2.39543 24.1046 1.5 23 1.5H3C1.89543 1.5 1 2.39543 1 3.5V17.5C1 18.6046 1.89543 19.5 3 19.5Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
    ></path>
  </svg>
);
