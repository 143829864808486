import React, { useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { ReactComponent as Closee } from '../../assets/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen, setModalData, setRefresh } from '../../store/ModalSlice';
import DisplayTime from './DisplayTime';
import { getAddresses } from '../../utils/addressHelpers';
import DrivenLockupABI from '../../ABI/DrivenLockupABI.json';
import { getContract, getDrivenContract } from '../../utils/contractHelpers';
import { useAccount, useSigner } from 'wagmi';
import { BigNumber, ethers } from 'ethers';
const MAX_INT = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export default function StakingModal({ open }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal.modalData);
  const accBtfBalance = useSelector((state) => state.modal.btfFormated);
  const [btfValue, setBtfValue] = useState(null);
  const [message, setMessage] = useState('');
  const [disable, setDisable] = useState(true);
  const [enable, setEnable] = useState(true);
  const { data: signer } = useSigner();
  const { address: account } = useAccount();

  const address = getAddresses();

  const handleBtfValue = (e) => {
    let value = e.target.value;
    if (value === '') {
      setMessage('');
      setDisable(true);
    } else if (value === '0') {
      setMessage('The amount should be more then 0');
      setDisable(true);
    } else if (value > accBtfBalance) {
      setMessage('The value should be less then max amount');
      setDisable(true);
    } else {
      setDisable(false);
      setMessage('');
    }
    setBtfValue(value);
  };

  const allowance = async (amountEntered) => {
    try {
      const contract = getDrivenContract(signer, data.stakingToken);
      const allowance1 = await contract.allowance(account, data.lockupAddress);
      const flag = allowance1.gte(BigNumber.from(ethers.utils.parseEther(amountEntered)));
      return flag;
    } catch (error) {
      console.log(error);
    }
    return false;
  };
  useEffect(() => {
    if (data.stakingToken != '' && account) {
      (async function () {
        const _allowance = await allowance(btfValue ? btfValue : 0);
        setEnable(!_allowance);
      })();
    }
  }, [data, account, btfValue]);
  useEffect(() => {
    setBtfValue('');
  }, [open]);

  const stakeBtf = async () => {
    if (btfValue === '' || btfValue === 0) return;
    try {
      setDisable(true);
      if (enable) {
        const contract = getDrivenContract(signer, data.stakingToken);
        const tx = await contract.approve(data.lockupAddress, MAX_INT);
        await tx.wait();
        setEnable(false);
        setDisable(false);
        return;
      }
      const lockUpContract = getContract(DrivenLockupABI, address[0].lockup, signer);
      const tx = await lockUpContract.deposit(ethers.utils.parseEther(btfValue), data.stackType);
      await tx.wait();
      dispatch(setRefresh(true));
      dispatch(setOpen(false));
    } catch (error) {
      console.error(error);
    }
    setDisable(false);
  };

  useEffect(() => {
    dispatch(setOpen(false));
    dispatch(setModalData([]));
  }, [account]);

  return (
    <>
      <div>
        <Dialog.Root open={open}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed top-0 left-0 right-0 h-screen w-screen bg-overlay-bg backdrop-blur-sm data-[state=open]:animate-overlayShow" />
            <Dialog.Content className="animate_div fixed top-1/2 left-1/2 w-4/5 -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white p-4 drop-shadow-[0_4px_40px_rgba(247,185,62,0.25)] focus-visible:outline-none dark:bg-[#1D202C] sm:w-3/5 lg:w-2/5">
              <div className="absolute right-1 top-[44px] items-center justify-end px-5">
                <Dialog.Close
                  asChild
                  onClick={() => {
                    dispatch(setOpen(false));
                    dispatch(setModalData([]));
                  }}
                  className="cursor-pointer"
                >
                  <Closee />
                </Dialog.Close>
              </div>
              <Dialog.Content
                asChild
                className="flex flex-col items-center justify-center focus-visible:outline-none"
              >
                <>
                  <div className="flex flex-col items-center justify-center p-5">
                    <div
                      className="mb-2 text-xl font-medium tracking-wider sm:text-3xl"
                      style={{ fontSize: '23px' }}
                    >
                      Stake BTF - <span className="">{data.term}</span>
                    </div>
                    {/* <div className="mt-5 text-sm tracking-widest">
                      Increase your current stake position?
                    </div> */}
                    {/* <div className="mt-5 text-sm tracking-widest">
                      NB: your stake countdown will reset to {data.term} and your
                    </div> */}
                    {/* <div className="text-sm tracking-widest">
                      BTF will be boosted to the maximum for this position
                    </div> */}
                    {data?.user?.userstack !== '0' ? (
                      <div className="my-10 flex items-center justify-center">
                        <DisplayTime time={data?.user?.stakeendinEpoch} />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="flex flex-col justify-start">
                      <div className="flex flex-row items-start justify-start gap-5">
                        <div className="rounded-full bg-black px-3 py-2 text-xs text-white">
                          Balance :{' '}
                          <span className="pl-2 tracking-widest text-primary-sunny-main">
                            {parseFloat(accBtfBalance).toFixed(2)}
                          </span>{' '}
                          BTF
                        </div>
                        <div>
                          <button
                            className="cursor-pointer rounded-full bg-black px-3 py-1 text-white"
                            onClick={() => {
                              setBtfValue(parseFloat(accBtfBalance).toFixed(2));
                              setDisable(false);
                            }}
                          >
                            Max
                          </button>
                        </div>
                      </div>
                      <div className="mx-auto mt-2 flex flex-row items-center gap-5">
                        <form className="mt-2 flex flex-row flex-wrap items-center  justify-center gap-5">
                          <div className="max-lg:w-full">
                            <input
                              className="rounded-xl border border-primary-sunny-blurSemiDarkBg bg-gray-800 py-4 px-2 text-white focus-visible:outline-none max-lg:w-full"
                              type="text"
                              value={btfValue}
                              placeholder="0"
                              onChange={(e) => handleBtfValue(e)}
                            />
                          </div>
                          <div className="max-lg:flex max-lg:w-full max-lg:justify-center">
                            <button
                              //rounded-lg border border-primary-sunny-dark px-3 py-1 duration-300 ease-in-out hover:bg-primary-sunny-dark
                              className={`rounded-xl border border-primary-sunny-dark p-4 capitalize tracking-wider duration-200 ease-in-out focus-visible:outline-none ${
                                disable
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer hover:bg-primary-sunny-dark'
                              }`}
                              onClick={() => stakeBtf()}
                              disabled={disable}
                              type="button"
                            >
                              {enable ? 'approve' : `STAKE`}
                            </button>
                          </div>
                        </form>
                      </div>
                      {message != '' && (
                        <div className="mt-3 text-xs tracking-wider">{message}</div>
                      )}
                    </div>
                  </div>
                </>
              </Dialog.Content>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </>
  );
}
