import { Interface } from "@ethersproject/abi";
import { getMulticallContract } from "./contractHelpers";

// export interface Call {
//   address; // Address of the contract
//   name; // Function name on the contract (example: balanceOf)
//   params; // Function params
// }

// export interface MulticallOptions {
//   requireSuccess?: boolean;
// }

const multicall = async (abi, calls) => {
  const multi = getMulticallContract();
  const itf = new Interface(abi);

  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }));
  const { returnData } = await multi.aggregate(calldata);

  const res = returnData.map((call, i) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );

  return res;
};

/**
 * Multicall V2 uses the new "tryAggregate" function. It is different in 2 ways
 *
 * 1. If "requireSuccess" is false multicall will not bail out if one of the calls fails
 * 2. The return includes a boolean whether the call was successful e.g. [wasSuccessful, callResult]
 */
export const multicallv2 = async (
  abi,
  calls,
  options= { requireSuccess: true }
) => {
  const { requireSuccess } = options;
  const multi = getMulticallContract();
  const itf = new Interface(abi);

  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }));

  // console.log("multi",multi)
  const returnData = await multi.tryAggregate(requireSuccess, calldata);
  const res = returnData.map((call, i) => {
    const [result, data] = call;
    return result ? itf.decodeFunctionResult(calls[i].name, data) : null;
  });

  return res;
};

export default multicall;
