import React from 'react';
import styled from '@emotion/styled';
import useTradeInfo from '../../../hooks/useTradeContext';

export default function TradePanel() {
  const { pairs, selectedPair } = useTradeInfo();
  const pair = pairs[selectedPair];
  return (
    <StyledPanel className="flex h-full w-full flex-col 2xl:w-[320px]">
      <div className="mt-2 flex justify-between bg-black py-2 text-xs font-medium">
        <div className="flex-1 text-center">PRICE(USDT)</div>
        <div className="flex-1 text-center">AMOUNT({pair.token0.symbol})</div>
        <div className="flex-1 text-center">TIME</div>
      </div>
      <div className="my-2 flex flex-1 justify-between text-xs font-medium">
        <div className="flex-1 text-center text-[#1c9f39]">28,338.00</div>
        <div className="flex-1 text-center">7.51572</div>
        <div className="flex-1 text-center">04:37:21</div>
      </div>
    </StyledPanel>
  );
}

const StyledPanel = styled.div`
  background: #1b1b1b;
  border-radius: 6px;
  overflow-x: hidden;
`;
