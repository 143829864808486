import { combineReducers } from '@reduxjs/toolkit';
import ModalSlice from './ModalSlice';
import UnstakSlice from './UnstakSlice';
import CurrenciesSuggestionsSlice from './CurrenciesSuggestionsSlice';
import DropdownMenu from './DropdownMenuSlice';
import CustomTokenSlice from './CustomTokenSlice';
import SwapboxAlertSlice from './SwapboxAlertSlice';
import DisabledBodySlice from './DisabledBodySlice';

export default combineReducers({
  modal: ModalSlice,
  currenciesSuggestions: CurrenciesSuggestionsSlice,
  swapboxAlert: SwapboxAlertSlice,
  unstake: UnstakSlice,
  dropdown: DropdownMenu,
  token: CustomTokenSlice,
});
