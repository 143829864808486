import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/configStore';
import Test from './testing';
import Toast from './components/shared/Toast';
import { TradeProvider } from './hooks/useTradeContext';
import { RefreshContextProvider } from './context/RefreshContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RefreshContextProvider>
      <TradeProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </TradeProvider>
    </RefreshContextProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
